import createReducer from "lib/createReducer";
import * as types from "actions/types";

export const user = createReducer(
    {
        username: null,
        uid: null,
        email: null,
        circle: null,
        fullName: null,
        deviceID: null,
        fcmToken: null,
        twoFA: null,
        groupID: null,
        groupName: null,
        profile_pic: null,

        state: null,
        state_id: null,

        isLoggedIn: false,
    },
    {
        [types.SET_IS_LOGGED_IN_STATUS](state, action) {
            let newState = Object.assign({}, state);
            newState.isLoggedIn = action.isLoggedIn;
            return newState;
        },

        // manage login
        [types.SET_LOGIN](state, action) {
            let newState = Object.assign({}, state);
            if (action.info !== null) {
                newState.username = action.info.username;
                newState.uid = action.info.uid;
                newState.email = action.info.email;
                newState.circle = action.info.circle;
                newState.groupID = action.info.group_id;
                newState.groupName = action.info.group_name;
                newState.fullName = action.info.full_name;
                newState.profile_pic = action.info.profile_picture;
                newState.state = action.info.state;
                newState.state_id = action.info.state_id;

                newState.isLoggedIn = true;
            } else {
                newState.username = null;
                newState.uid = null;
                newState.email = null;
                newState.circle = null;
                newState.fullName = null;
                newState.groupID = null;
                newState.groupName = null;
                newState.profile_pic = null;
                newState.state = null;
                newState.state_id = null;

                newState.isLoggedIn = false;
            }
            return newState;
        },

        [types.UPDATE_SELF](state, action) {
            let newState = Object.assign({}, state);
            if (action.info !== null) {
                newState.fullName = action.info.full_name;
                newState.groupID = action.info.group_id;
                newState.groupName = action.info.group_name;
                newState.circle = action.info.circle;
                newState.profile_pic = action.info.avatar_url;
            }
            return newState;
        },

        [types.CHANGE_MY_DETAILS](state, action) {
            let newState = Object.assign({}, state);
            if (action.data !== null) {
                if (action.data.fullName) {
                    newState.fullName = action.data.fullName;
                }
                if (action.data.email) {
                    newState.email = action.data.email;
                }
                if (action.data.circle) {
                    newState.circle = action.data.circle;
                }
                if (action.data.groupID) {
                    newState.groupID = action.data.groupID;
                }
                if (action.data.avatar_url) {
                    newState.profile_pic = action.data.avatar_url;
                }
                if (action.data.state_id) {
                    newState.state_id = action.data.state_id;
                }
            }
            return newState;
        },

        [types.LOGOUT](state, action) {
            let newState = Object.assign({}, state);
            newState.username = null;
            newState.uid = null;
            newState.email = null;
            newState.circle = null;
            newState.fullName = null;
            newState.groupID = null;
            newState.groupName = null;
            newState.profile_pic = null;
            newState.state = null;
            newState.state_id = null;

            newState.isLoggedIn = false;
            return newState;
        },

        // set when a login attempt fails due to 2FA.
        [types.SET_TRUSTED_DEVICE_REQUESTED](state, action) {
            let newState = Object.assign({}, state);
            if (action.data !== null) {
                action.data.ts = new Date().getTime();
                newState.twoFA = action.data;
            } else {
                newState.twoFA = null;
            }
            return newState;
        },

        // Set a unique device identifier
        [types.SET_UUID](state, action) {
            let newState = Object.assign({}, state);
            newState.deviceID = action.uuid;
            return newState;
        },

        // Set Firebase Cloud Messaging Token
        [types.SET_FCMS](state, action) {
            let newState = Object.assign({}, state);
            newState.fcmToken = action.fcmToken;
            return newState;
        },

    },
);
