// import { HOST } from "Constants";
// import futch from "lib/futch";
import { executeBasicNetworkCall } from "lib/Utils";
import * as types from 'CropProductInfo/actions/types.js';

export function getToolsList(callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_tools_list",
            null,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        dispatch(setToolsList(x.data));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function setToolsList(data) {
    return {
        type: types.SET_TOOLS_LIST,
        data: data,
    };
}

export function setViewCropProductInfoListBy(data) {
    return {
        type: types.SET_VIEW_CROP_PRODUCT_INFO_LIST_BY,
        data: data,
    };
}
