import createReducer from "lib/createReducer";
import * as types from "ContentManager/actions/types";

const DEFAULT_STATE = {
    approved_or_published: [],
    my_drafts: [],
    projects_assigned_to_me: [],
    under_review: [],
};

export const myAuthorContent = createReducer(
    DEFAULT_STATE,
    {
        [types.SET_MY_AUTHOR_CONTENT_LISTS](state, action) {
            let newState = Object.assign({}, state);
            if (action.data === null || action.data === undefined) {
                newState = DEFAULT_STATE;
            } else {
                if (action.data.hasOwnProperty('approved_or_published')) {
                    newState.approved_or_published = action.data.approved_or_published;
                }
                if (action.data.hasOwnProperty('my_drafts')) {
                    newState.my_drafts = action.data.my_drafts;
                }
                if (action.data.hasOwnProperty('projects_assigned_to_me')) {
                    newState.projects_assigned_to_me = action.data.projects_assigned_to_me;
                }
                if (action.data.hasOwnProperty('under_review')) {
                    newState.under_review = action.data.under_review;
                }
            }
            
            return newState;
        },

    }
);
