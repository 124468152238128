import createReducer from "lib/createReducer";
import * as types from "AllDrafts/actions/types";

export const allDrafts = createReducer(
    {
        article_list: [],
        observation_list: [],
        exclude_article_draft_ids: [],
        exclude_observation_draft_ids: [],
    },
    {
        [types.SET_ALL_ARTICLE_DRAFTS](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.data !== null) {
                newState.article_list = action.data;
            } else {
                newState.article_list = [];
            }

            return newState;
        },

        [types.SET_EXCLUDE_ARTICLE_DRAFT_IDS](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.data !== null) {
                newState.exclude_article_draft_ids = action.data;
            } else {
                newState.exclude_article_draft_ids = [];
            }

            return newState;
        },

        [types.SET_ALL_OBSERVATION_DRAFTS](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.data !== null) {
                newState.observation_list = action.data;
            } else {
                newState.observation_list = [];
            }

            return newState;
        },

        [types.SET_EXCLUDE_OBSERVATION_DRAFT_IDS](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.data !== null) {
                newState.exclude_observation_draft_ids = action.data;
            } else {
                newState.exclude_observation_draft_ids = [];
            }

            return newState;
        },
    },
);
