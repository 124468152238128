import * as cropProductInfoReducers from './cropProductInfo';
import * as cropDetailsReducers from './cropDetails';
import * as productDetailsReducers from './productDetails';
import * as districtDetailsReducers from './districtDetails';
import * as districtCropDetailsReducers from './districtCropDetails';
import * as districtProductDetailsReducers from './districtProductDetails';

export default Object.assign({},
    cropProductInfoReducers,
    cropDetailsReducers,
    productDetailsReducers,
    districtDetailsReducers,
    districtCropDetailsReducers,
    districtProductDetailsReducers,
);
