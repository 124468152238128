import * as types from "./types";

export function enqueueJob(jobType, payload, jobTitle = "Upload") {
  return {
    type: types.ENQUEUE_JOB,
    jobType: jobType,
    title: jobTitle,
    payload
  };
}

export function dequeueJob() {
  return {
    type: types.DEQUEUE_JOB
  };
}

export function clearJobQueue() {
  return {
    type: types.CLEAR_QUEUE
  };
}
