import createReducer from "lib/createReducer";
import * as types from "actions/types";
import {compareAsc, compareDesc} from 'date-fns';

const DEFAULT_STATE = {
    draftVideos: [],
    publishedVideos: [],
    scheduledVideos: [],

};

export const videoEditor = createReducer(
    DEFAULT_STATE,
    {
        [types.SET_EDITOR_VIDEO_LIST](state, action) {
            let newState = Object.assign({}, state);

            newState.publishedVideos = action?.published?.sort((a,b) => compareDesc(new Date(a.release_date),new Date(b.release_date)));
            newState.draftVideos = action?.draft?.sort((a,b) => compareDesc(new Date(a.last_edited),new Date(b.last_edited)));
            newState.scheduledVideos = action?.scheduled?.sort((a,b) => compareAsc(new Date(a.release_date),new Date(b.release_date)));

            return newState;
        },

    }
);
