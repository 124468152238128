import * as types from "./types";

export function setIsResponsive(isResponsive = false) {
    return {
        type: types.SET_IS_RESPONSIVE,
        isResponsive,
    };
}

export function setIsPadded(isPadded = false) {
    return {
        type: types.SET_IS_PADDED,
        isPadded,
    };
}

export function setIPhoneSEMql(iPhoneSEMql = false) {
    return {
        type: types.SET_IPHONE_SE_MQL,
        iPhoneSEMql,
    };
}

export function setSidebarShowing(sidebarShowing = false) {
    return {
        type: types.SET_SIDEBAR_SHOWING,
        sidebarShowing,
    };
}
