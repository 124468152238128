import { recordAnalyticsEvent } from "@linxsystems/web-big-data";
// import { store } from "store";

export function recordAnalytics(tealium_dict, category, action, label, value, token, source = "Web") {
    // let user = store.getState().user;
    recordAnalyticsEvent(category, action, label, value, token, source);

    // if (window.utag) {
    //     if (tealium_dict) {
    //         let empty_telium_dict = {
    //             page_category: "",
    //             page_subcategory: "",
    //             event_type: "",
    //             event_name: "",
    //             event_value: "",
    //             tealium_event: "",
    //         };

    //         let new_dict = {
    //             ...tealium_dict,
    //             page_name: "Grow Smart Live",
    //             customer_email: user.email,
    //             customer_status: "logged in",
    //             customer_state: user.state,
    //             customer_id: user.uid,
    //             customer_type: user.groupName,
    //         };

    //         let dict_to_send = Object.assign(empty_telium_dict, new_dict);

    //         window.utag.link(dict_to_send);
    //     } else {
    //         // console.log('did not pass valid tealium dict')
    //     }
    // } else {
    //     // console.log('There is no window.utag')
    // }
}
