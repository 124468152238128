import * as types from "./types";
import * as MessageActions from "./messages";
import { executeBasicNetworkCall } from "lib/Utils";

function setUserDetailsInfo(data) {
    return {
        type: types.SET_USER_DETAILS_INFO,
        userDetails: data.user_details,
    };
}

export function clearCRMState() {
    return {
        type: types.RESET_CRM_STATE,
    };
}

export function updateUserDetailsInfo(requested, field_name, value, token = null, success = () => {}) {
    return (dispatch, getState) => {
        let params = {
            requested,
            field_name,
            value,
        };
        if (token !== null) {
            params.auth_token = token;
        }
        params = JSON.stringify(params);

        executeBasicNetworkCall(
            "crm_user_details_info",
            params,
            "application/json",
            "PUT",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(MessageActions.setError(x.message));
                    } else {
                        dispatch(setUserDetailsInfo(x.data));
                        success();
                    }
                }
            },
            (error) => {
                dispatch(MessageActions.setError(error));
            },
        );
    };
}

export function changeMyDetails(data) {
    return {
        type: types.CHANGE_MY_DETAILS,
        data,
    };
}

export function changeUserDetails(data) {
    return {
        type: types.CHANGE_USER_DETAILS,
        data,
    };
}

export function clearUserDetails() {
    return {
        type: types.CLEAR_USER_DETAILS,
    };
}

export function clearAssociationDetails() {
    return {
        type: types.CLEAR_ASSOCIATION_DETAILS,
    };
}

export function fetchUserActivityDetails(uid, last_month = 0, last_year = 0, doneLoading = () => {}) {
    return (dispatch, getState) => {
        let params = { uid };

        if (last_month !== 0 && last_year !== 0) {
            params.last_month = last_month;
            params.last_year = last_year;
        }

        params = JSON.stringify(params);

        executeBasicNetworkCall(
            "crm_user_activity_details",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.status === 200) {
                    if (reqq.responseText != null) {
                        const x = JSON.parse(reqq.responseText);
                        if (x.error) {
                            dispatch(MessageActions.setError(x.message));
                        } else {
                            doneLoading();
                            dispatch(setUserActivityDetails(x.data, last_year === 0));
                        }
                    }
                }
            },
            (error) => {
                dispatch(MessageActions.setError(error));
            },
        );
    };
}

function setUserActivityDetails(data, isLoadingFirst) {
    return {
        type: types.SET_USER_ACTIVITY_DETAILS,
        data,
        isLoadingFirst,
    };
}

export function fetchUserDeviceDetailsInfo(uid) {
    return (dispatch, getState) => {
        let params = { uid };

        params = JSON.stringify(params);

        executeBasicNetworkCall(
            "crm_user_device_details",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.status === 200) {
                    if (reqq.responseText != null) {
                        const x = JSON.parse(reqq.responseText);
                        if (x.error) {
                            dispatch(MessageActions.setError(x.msg));
                        } else {
                            dispatch(setUserDeviceDetailsInfo(x.data.user_device_details));
                        }
                    }
                }
            },
            (error) => {
                dispatch(MessageActions.setError(error));
            },
        );
    };
}

function setUserDeviceDetailsInfo(data) {
    return {
        type: types.SET_USER_DEVICE_DETAILS_INFO,
        data,
    };
}

export function getDropdownOptions() {
    return (dispatch, getState) => {
        let params = {};

        params = JSON.stringify(params);

        executeBasicNetworkCall(
            "crm_dropdown_options",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.status === 200) {
                    if (reqq.responseText != null) {
                        const x = JSON.parse(reqq.responseText);
                        if (x.error) {
                            dispatch(MessageActions.setError(x.msg));
                        } else {
                            dispatch(setDropdownOptions(x.data));
                        }
                    }
                }
            },
            (error) => {
                dispatch(MessageActions.setError(error));
            },
        );
    };
}

function setDropdownOptions(data) {
    return {
        type: types.SET_DROPDOWN_OPTIONS,
        data,
    };
}

export function getViewerChatSettings(successCallback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_or_toggle_viewer_chat",
            undefined,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(MessageActions.setError(x.message));
                    } else {
                        successCallback(x.data);
                    }
                }
            },
            (error) => {
                dispatch(MessageActions.setError(error));
            },
        );
    };
}

export function toggleViewerChatSettings(viewer_chat_enabled, successCallback = () => {}) {
    return (dispatch, getState) => {
        let params = { viewer_chat_enabled };
        params = JSON.stringify(params);

        executeBasicNetworkCall(
            "get_or_toggle_viewer_chat",
            params,
            "application/json",
            "PUT",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(MessageActions.setError(x.message));
                    } else {
                        dispatch(getViewerChatSettings(successCallback));
                    }
                }
            },
            (error) => {
                dispatch(MessageActions.setError(error));
            },
        );
    };
}
