import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import Flex from "flexbox-react";
import { connect } from "react-redux";

const CustomerSupportContactInfo = forwardRef(({ user }, ref) => {
    const [open, setOpen] = useState(true);

    useImperativeHandle(ref, () => ({
        forceShow: () => {
            setOpen(true);
        },
    }));

    const CUSTOMER_SUPPORT_PHONE_NUMBER = "18559135444";
    const CUSTOMER_SUPPORT_EMAIL = "customercare@growsmartsupport.com";
    const EMAIL_SUBJECT = "Grow Smart Live Web Question";

    const br = "%0D%0A";

    const body = `${br}${br}${br}Do not write below this line${br}#########################${br}${br}
App Location: ${window.location.href}${br}
Logged In? ${user?.isLoggedIn ? "Yes" : "No"}${br}
${user?.isLoggedIn ? `Username: ${user.email}${br}` : ""}
${user?.isLoggedIn ? `ID: ${user.uid}${br}` : ""}
${user?.isLoggedIn ? `Circle: ${user.circle}${br}` : ""}
User Agent: ${navigator.userAgent}${br}`;

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <Modal.Header>Customer Support</Modal.Header>
            <Modal.Content>
                <p>Our customer support agents are standing by. Have a question? Get an answer!</p>
                <br />
                <Flex flexDirection="row" justifyContent="space-around">
                    <Flex flexDirection="column" alignItems="center">
                        <h3>Call Toll Free</h3>
                        <b>+1 (855) 913-5444</b>
                        <Button
                            color="green"
                            size="massive"
                            icon
                            labelPosition="left"
                            onClick={() => (window.location.href = `tel:${CUSTOMER_SUPPORT_PHONE_NUMBER}`)}>
                            <Icon name="phone" />
                            Phone
                        </Button>
                    </Flex>
                    <Flex flexDirection="column" alignItems="center">
                        <h3>Written Support</h3>
                        <b>&nbsp;</b>
                        <Button
                            color="blue"
                            size="massive"
                            icon
                            labelPosition="left"
                            onClick={() =>
                                (window.location.href = `mailto:${CUSTOMER_SUPPORT_EMAIL}?subject=${EMAIL_SUBJECT}&body=${body}`)
                            }>
                            <Icon name="mail" />
                            Email
                        </Button>
                    </Flex>
                </Flex>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setOpen(false)}>Close</Button>
            </Modal.Actions>
        </Modal>
    );
});

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(CustomerSupportContactInfo);
