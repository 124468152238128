import createReducer from "lib/createReducer";
import * as types from "ContentManager/actions/types";

const DEFAULT_STATE = {
    ready_for_my_review: [],
    upcoming: [],
    approved: [],
};

export const myReviewContent = createReducer(
    DEFAULT_STATE,
    {
        [types.SET_MY_REVIEW_CONTENT_LISTS](state, action) {
            let newState = Object.assign({}, state);
            if (action.data === null || action.data === undefined) {
                newState = DEFAULT_STATE;
            } else {
                if (action.data.hasOwnProperty('ready_for_my_review')) {
                    newState.ready_for_my_review = action.data.ready_for_my_review;
                }
                if (action.data.hasOwnProperty('upcoming')) {
                    newState.upcoming = action.data.upcoming;
                }
                if (action.data.hasOwnProperty('approved')) {
                    newState.approved = action.data.approved;
                }
            }
            
            return newState;
        },

    }
);
