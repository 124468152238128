import { executeBasicNetworkCall } from "lib/Utils";
import * as types from "actions/types";

export function getComposeDropdownList(callback = () => {}, auth_token = null) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_compose_dropdown_list",
            JSON.stringify({ auth_token }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
        );
    };
}

export function getPostsToHashtag(
    content_type,
    hashtag_category,
    include_yellow = false,
    exclude_post_ids = [],
    callback = () => {},
) {
    return (dispatch, getState) => {
        let json = {
            content_type: content_type,
            hashtag_category: hashtag_category,
            include_yellow: include_yellow,
            exclude_post_ids: exclude_post_ids,
        };
        json = JSON.stringify(json);
        executeBasicNetworkCall(
            "get_posts_to_hashtag",
            json,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
        );
    };
}

export function updatePostHashtagsV2(
    post_id,
    state_ids = [],
    br_district_ids = [],
    br_region_ids = [],
    content_category_id,
    focus_crop_id,
    focus_product_id,
    is_customer_exclusive,
    callback = () => {},
) {
    return (dispatch, getState) => {
        let json = {
            post_id: post_id,
            state_ids: state_ids,
            br_district_ids: br_district_ids,
            br_region_ids: br_region_ids,
            content_category_id: content_category_id,
            focus_crop_id: focus_crop_id,
            focus_product_id: focus_product_id,
            is_customer_exclusive: is_customer_exclusive,
        };
        json = JSON.stringify(json);
        executeBasicNetworkCall(
            "update_post_hashtags_v2",
            json,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    console.log(x);
                    if (x.error) {
                        callback(false);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
        );
    };
}

export function updatePostHashtags(
    post_id,
    state_ids = [],
    br_district_ids = [],
    br_region_ids = [],
    crop_ids = [],
    product_ids = [],
    is_customer_exclusive,
    callback = () => {},
) {
    return (dispatch, getState) => {
        let json = {
            post_id: post_id,
            state_ids: state_ids,
            br_district_ids: br_district_ids,
            br_region_ids: br_region_ids,
            crop_ids: crop_ids,
            product_ids: product_ids,
            is_customer_exclusive: is_customer_exclusive,
        };
        json = JSON.stringify(json);
        executeBasicNetworkCall(
            "update_post_hashtags",
            json,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
        );
    };
}

export function setExcludePostIDs(data) {
    return {
        type: types.SET_EXCLUDE_POST_IDS,
        data: data,
    };
}

export function fetchAnalyticsDashboardData(
    start_date,
    end_date,
    state_list = [],
    crop_list = [],
    product_list = [],
    callback = () => {},
) {
    const params = { start_date, end_date };

    if (state_list.length !== 0) {
        params.state_list = state_list;
    }

    if (crop_list.length !== 0) {
        params.crop_list = crop_list;
    }

    if (product_list.length !== 0) {
        params.product_list = product_list;
    }

    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "hashtag_analytics_dashboard",
            JSON.stringify(params),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
        );
    };
}


export function fetchAnalyticsHeatmapData(
    start_date,
    end_date,
    state_list = [],
    crop_list = [],
    product_list = [],
    callback = () => {},
) {
    const params = { start_date, end_date };

    if (state_list.length !== 0) {
        params.state_list = state_list;
    }

    if (crop_list.length !== 0) {
        params.crop_list = crop_list;
    }

    if (product_list.length !== 0) {
        params.product_list = product_list;
    }

    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "hashtag_analytics_heatmap",
            JSON.stringify(params),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
        );
    };
}
