import * as types from "./types";
import * as Constants from "Constants";

export function deviceIsOrientedInMode(isLandscapeOrientation) {
  return {
    type: types.DETECTED_POSSIBLE_ORIENTATION_CHANGE,
    isLandscapeOrientation
  };
}

export function toggleSidebarVisible(isVisible = true) {
  return {
    type: types.TOGGLE_SIDEBAR_VISIBLE___WEB,
    isVisible
  };
}

export function setMainTourAsWatched() {
  return {
    type: types.SET_MAIN_TOUR_AS_WATCHED___WEB
  };
}

export function setSelectedComposeTab(
  selectedTab = Constants.ComposeTabHistory
) {
  return {
    type: types.SET_SELECTED_COMPOSE_TAB,
    selectedTab
  };
}

export function setEditorToolbarDisplay(display = Constants.ICON_AND_TEXT) {
  return {
    type: types.SET_EDITOR_TOOLBAR_DISPLAY,
    display
  };
}

export function toggleChangelogVisibility() {
  return {
      type: types.TOGGLE_CHANGELOG_VISIBILITY
  }
}