import * as types from "./types";
import * as MessageActions from "./messages";
import { getContentDetails, getStateStream } from "./content_stream";
import { executeBasicNetworkCall } from "lib/Utils";
import { CONTENT_TYPES } from "Constants";

export function getProfileInfo(uid, myCallback, token = null) {
    return (dispatch, getState) => {
        let json = { uid: uid, auth_token: token };
        json = JSON.stringify(json);

        executeBasicNetworkCall("profile_info", json, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    if (token === null) {
                        dispatch(MessageActions.setError(x.message));
                    } else {
                        myCallback(null);
                    }
                } else {
                    // const data = x.data;
                    myCallback(x.data);
                    // this call caused issues when viewing as a different user type, so it was removed:
                    // dispatch(
                    //     updateSelf({
                    //         full_name: data.name,
                    //         group_id: data.group_id,
                    //         group_name: data.group_name,
                    //         circle: data.circle,
                    //         avatar_url: data.profile_picture,
                    //     }),
                    // );
                }
            }
        });
    };
}

// function updateSelf(info) {
//     return {
//         type: types.UPDATE_SELF,
//         info,
//     };
// }

export function createOrUpdateBio(
    bio,
    job_title,
    website,
    linkedin,
    crop_ids,
    product_ids,
    content_category_ids,
    user_id,
    refreshPage,
    token = null,
) {
    return (dispatch, getState) => {
        let json = {
            bio: bio,
            job_title: job_title,
            website: website,
            linkedin: linkedin,
            crop_ids: crop_ids,
            product_ids: product_ids,
            content_category_ids: content_category_ids,
            user_id: user_id,
            auth_token: token,
        };
        json = JSON.stringify(json);

        executeBasicNetworkCall("create_or_update_bio", json, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    dispatch(MessageActions.setError(x.message));
                } else {
                    refreshPage();
                }
            }
        });
    };
}

export function createOrUpdateProfilePicture(mr_id, azure_media_id, success = () => { }, token) {
    return (dispatch, getState) => {
        let json = {};

        if (mr_id) {
            json = { mr_id };
        } else if (azure_media_id) {
            json = { azure_media_id };
        }

        if (token && token !== null) {
            json.auth_token = token;
        }
        json = JSON.stringify(json);

        executeBasicNetworkCall("create_or_update_profile_picture", json, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    dispatch(MessageActions.setError(x.message));
                } else {
                    dispatch(MessageActions.setSuccess(x.message));
                    success();
                }
            }
        });
    };
}

export function getNewsStateStream(last_post_id = null, num_requested = 0, callback = () => { }) {
    return (dispatch, getState) => {
        getStateStream(getState, CONTENT_TYPES.NEWS, last_post_id, num_requested, (success, data) => {
            if (success) {
                let convertedData = [];
                data.map((article) => {
                    return convertedData.push(convertV3ArticleToV1Article(article));
                });
                dispatch(setStateStream(convertedData));
                callback("stateStream");
            } else {
                callback();
            }
        });
    };
}

export function getArticles(article_list, request_old = false, last_id = 0, stream_id = null, doneLoading = () => { }) {
    return (dispatch, getState) => {
        let json;

        if (article_list === "myArticles") {
            json = { confined: true, request_old, last_id };
        } else if (article_list === "stateStream") {
            json = { confined: false, request_old, last_id };
        } else if (article_list === "allArticles") {
            json = { stream_id: "all", request_old, last_id };
        } else if (article_list === "otherArticles") {
            json = {
                confined: true,
                stream_id: stream_id,
                request_old,
                last_id,
            };
        } else if (article_list === "otherStream") {
            json = {
                confined: false,
                stream_id: stream_id,
                request_old,
                last_id,
            };
        }

        json = JSON.stringify(json);

        executeBasicNetworkCall("story_info_v2", json, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                //
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    dispatch(MessageActions.setError(x.message));
                } else {
                    if (article_list === "myArticles") {
                        dispatch(setMyArticles(x.data));
                        doneLoading(article_list);
                    } else if (article_list === "stateStream") {
                        dispatch(setStateStream(x.data));
                        doneLoading(article_list);
                    } else if (article_list === "allArticles") {
                        dispatch(setAllArticles(x.data));
                        doneLoading(article_list);
                    } else if (article_list === "otherArticles") {
                        dispatch(setOtherArticles(x.data));
                        doneLoading();
                    } else if (article_list === "otherStream") {
                        dispatch(setOtherStream(x.data));
                        doneLoading();
                    }
                }
            }
        });
    };
}

export function getNewsDetails(article_id, callback = () => { }) {
    return (dispatch, getState) => {
        getContentDetails(getState, article_id, CONTENT_TYPES.NEWS, (success, content_data) => {
            if (success) {
                const data = content_data.data;
                callback(true, data);
            } else {
                callback(false);
            }
        });
    };
}

export function getSavedArticlesList(user_id) {
    return (dispatch, getState) => {
        const params = "user=" + user_id;

        executeBasicNetworkCall("saved_articles_info", params, "application/x-www-form-urlencoded", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    dispatch(MessageActions.setError(x.message));
                } else {
                    dispatch(setSavedArticles(x.data));
                }
            }
        });
    };
}

export function saveOrUnsaveArticle(uid, aid, saved, callback = () => { }) {
    return (dispatch, getState) => {
        const params = "user=" + uid + "&article=" + aid + "&action=" + saved;

        executeBasicNetworkCall("save_article_action", params, "application/x-www-form-urlencoded", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    dispatch(MessageActions.setError(x.details));
                    callback(x.error);
                } else {
                    callback(x.error);
                    dispatch(getSavedArticlesList(uid));
                }
            }
        });
    };
}

export function likeOrUnlikeArticle(uid, aid, liked, callback = () => { }) {
    return (dispatch, getState) => {
        const params = "uid=" + uid + "&aid=" + aid + "&liked=" + liked;

        executeBasicNetworkCall("sld", params, "application/x-www-form-urlencoded", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    dispatch(MessageActions.setError(x.details));
                    callback(x.error);
                } else {
                    callback(x.error);
                    dispatch(toggleArticleLike(aid));
                }
            }
        });
    };
}

export function shareOrUnshareArticle(uid, aid, callback = () => { }) {
    return (dispatch, getState) => {
        const params = "uid=" + uid + "&aid=" + aid;

        executeBasicNetworkCall("share_article", params, "application/x-www-form-urlencoded", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    dispatch(MessageActions.setError(x.message));
                    callback(x.error);
                } else {
                    dispatch(MessageActions.setSuccess(x.message));
                    callback(x.error);
                    dispatch(toggleArticleShare(aid));
                }
            }
        });
    };
}

export function getViewerList(uid, myCallback) {
    return (dispatch, getState) => {
        let json = { uid: uid };
        json = JSON.stringify(json);

        executeBasicNetworkCall("get_viewer_list", json, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText !== null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    dispatch(MessageActions.setError(x.message));
                } else {
                    myCallback(x.data);
                }
            }
        });
    };
}

export function getArticleLikesList(bbID, callback) {
    return (dispatch, getState) => {
        let json = {
            bbid: bbID,
        };

        json = JSON.stringify(json);

        executeBasicNetworkCall(
            "get_article_likes_list",
            json,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText !== null) {
                    //
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, null);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            () => {
                callback(false, null);
            },
        );
    };
}

export function getArticleSharesList(bbID, callback) {
    return (dispatch, getState) => {
        let json = {
            bbid: bbID,
        };

        json = JSON.stringify(json);

        executeBasicNetworkCall(
            "get_article_shares_list",
            json,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, null);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            () => {
                callback(false, null);
            },
        );
    };
}

export function getSearchBASFUserList() {
    return (dispatch, getState) => {
        executeBasicNetworkCall("get_search_basf_user_list", undefined, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                //
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    dispatch(MessageActions.setError(x.message));
                } else {
                    dispatch(setBASFUsers(x.data));
                }
            }
        });
    };
}

export function reportAnalytics(articleID, callback = () => { }) {
    return (dispatch, getState) => {
        let deviceType = 3;

        let user = getState().user;

        let params = `user=${user.uid}&aid=${articleID}&dt=${deviceType}`;

        executeBasicNetworkCall("analytics_report", params, "application/x-www-form-urlencoded", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (!x.error) {
                    callback();
                }
            }
        });
    };
}

export function AMArchivePublishedBurst(bbid, callback) {
    return (dispatch, getState) => {
        let json = {
            bbid: bbid,
        };

        json = JSON.stringify(json);

        executeBasicNetworkCall(
            "am_archive_published_burst",
            json,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(MessageActions.setError(x.message));
                        callback();
                    } else {
                        dispatch(MessageActions.setSuccess(x.message));
                        callback();
                    }
                }
            },
            () => {
                callback();
            },
        );
    };
}

export function clearArticles() {
    return (dispatch) => {
        dispatch(setMyArticles(null));
        dispatch(setStateStream(null));
        dispatch(setAllArticles(null));
        dispatch(setOtherArticles(null));
        dispatch(setOtherStream(null));
    };
}

export function clearStateStream() {
    return (dispatch) => {
        dispatch(setStateStream(null));
    };
}

function setMyArticles(myArticles) {
    return {
        type: types.SET_MY_ARTICLES,
        myArticles,
    };
}

function setStateStream(stateStream) {
    return {
        type: types.SET_STATE_STREAM,
        stateStream,
    };
}

function setAllArticles(allArticles) {
    return {
        type: types.SET_ALL_ARTICLES,
        allArticles,
    };
}

function setOtherArticles(otherArticles) {
    return {
        type: types.SET_OTHER_ARTICLES,
        otherArticles,
    };
}

function setOtherStream(otherStream) {
    return {
        type: types.SET_OTHER_STREAM,
        otherStream,
    };
}

function setSavedArticles(savedArticles) {
    return {
        type: types.SET_SAVED_ARTICLES_INFO,
        savedArticles,
    };
}

function toggleArticleLike(aid) {
    return {
        type: types.TOGGLE_ARTICLE_LIKE,
        aid,
    };
}

function toggleArticleShare(aid) {
    return {
        type: types.TOGGLE_ARTICLE_SHARE,
        aid,
    };
}

function setBASFUsers(basfusers) {
    return {
        type: types.SET_BASF_USERS,
        basfusers,
    };
}

function convertV3ArticleToV1Article(article) {
    let convertedArticle = {
        id: "",
        compressed: false,
        data: {},
    };
    convertedArticle.id = article.post_id;

    convertedArticle.data = {
        aid: article.post_id,
        article_id: article.article_id,
        article_abstract_rich: article.content,
        article_created_by: article.author,
        article_created_by_profile_pic: article.author_profile_pic,
        article_date_created: article.date,
        article_image: article.image,
        article_likes: article.numLikes,
        article_title: article.title,
        article_views: article.numReads,
        liked: article.isLiked,

        // these fields are not passed from the new article,
        // so they are set to some default values here
        article_abstract: "",
        article_created_by_group_id: "",
        article_created_by_uid: "",
        article_info: [],
        article_type: 0,
        auto_shares: 0,
        can_share: false,
        external_article_views: 0,
        internal_article_views: 0,
        manual_shares: 0,
        shared: false,
    };

    return convertedArticle;
}
