import { executeBasicNetworkCall } from "lib/Utils";
import { CONTENT_TYPES, HOST } from "Constants";

export function getStateStream(getState, media_type, last_post_id = null, num_requested = 0, callback = () => {}) {
    const params = { media_type, num_requested };

    if (last_post_id) {
        params.last_post_id = last_post_id;
    }

    executeBasicNetworkCall(
        "get_state_stream",
        JSON.stringify(params),
        "application/json",
        "POST",
        getState,
        (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    console.log("resp error", x.message);
                    callback(false);
                } else {
                    const data = x.data.stream_data;
                    callback(true, data);
                }
            }
        },
        (err) => {
            console.log("error:", err);
            callback(false);
        },
        false,
        (status) => {
            console.log("bad status:", status);
            callback(false);
        },
        `${HOST}/API/v3/GetStateStream`,  // overrideURL to call from v3
    );
}

export function getContentDetails(getState, media_id, media_type, callback = () => {}) {
    const params = {};

    if (media_id && media_type) {
        if (media_type === CONTENT_TYPES.NEWS) {
            params.article_id = media_id;
        } else if (media_type === CONTENT_TYPES.OBSERVATION) {
            params.observation_id = media_id;
        } else if (media_type === CONTENT_TYPES.VIDEO) {
            params.video_id = media_id;
        } else {
            callback(false);
            return;
        }
    } else {
        callback(false);
        return;
    }

    executeBasicNetworkCall(
        "get_content_details",
        JSON.stringify(params),
        "application/json",
        "POST",
        getState,
        (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    console.log("get_content_details response", x.message);
                    callback(false);
                } else {
                    callback(true, x.data);
                }
            }
        },
        (err) => {
            console.log("get_content_details error:", err);
            callback(false);
        },
        false,
        (status) => {
            console.log("get_content_details bad status:", status);
            callback(false);
        },
        `${HOST}/API/v3/GetContentDetails`,  // overrideURL to call from v3
    );
}
