import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

export const liteUsers = createReducer(
    {
        list: [],
    },
    {
        [types.SET_UNCLAIMED_LITE_USERS](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.data !== null) {
                newState.list = action.data;
            } else {
                newState = {
                    list: [],
                };
            }

            return newState;
        },
    },
);
