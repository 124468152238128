import * as types from "./types";
import * as MessageActions from "./messages";

import { executeBasicNetworkCall } from "lib/Utils";

export function getSummaryAnalytics(time_range) {
    return (dispatch, getState) => {
        let json = { time_range: time_range };
        json = JSON.stringify(json);

        if (time_range === "7") {
            dispatch(setSummaryAnalyticsWeek(null));
        } else if (time_range === "30") {
            dispatch(setSummaryAnalyticsMonth(null));
        } else if (time_range === "365") {
            dispatch(setSummaryAnalyticsYear(null));
        } else if (time_range === "all") {
            dispatch(setSummaryAnalyticsAll(null));
        }

        executeBasicNetworkCall("summary_analytics", json, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    dispatch(MessageActions.setError(x.message));
                } else {
                    if (time_range === "7") {
                        dispatch(setSummaryAnalyticsWeek(x.data));
                    } else if (time_range === "30") {
                        dispatch(setSummaryAnalyticsMonth(x.data));
                    } else if (time_range === "365") {
                        dispatch(setSummaryAnalyticsYear(x.data));
                    } else if (time_range === "all") {
                        dispatch(setSummaryAnalyticsAll(x.data));
                    }
                }
            }
        });
    };
}

export function getLeaderboardAnalytics(time_range) {
    return (dispatch, getState) => {
        let json = { time_range: time_range };
        json = JSON.stringify(json);

        if (time_range === "7") {
            dispatch(setLeaderboardAnalyticsWeek(null));
        } else if (time_range === "30") {
            dispatch(setLeaderboardAnalyticsMonth(null));
        } else if (time_range === "365") {
            dispatch(setLeaderboardAnalyticsYear(null));
        } else if (time_range === "all") {
            dispatch(setLeaderboardAnalyticsAll(null));
        }

        executeBasicNetworkCall("leaderboard_analytics", json, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    dispatch(MessageActions.setError(x.message));
                } else {
                    if (time_range === "7") {
                        dispatch(setLeaderboardAnalyticsWeek(x.data));
                    } else if (time_range === "30") {
                        dispatch(setLeaderboardAnalyticsMonth(x.data));
                    } else if (time_range === "365") {
                        dispatch(setLeaderboardAnalyticsYear(x.data));
                    } else if (time_range === "all") {
                        dispatch(setLeaderboardAnalyticsAll(x.data));
                    }
                }
            }
        });
    };
}

export function getChirperAnalytics(time_range) {
    return (dispatch, getState) => {
        let json = { time_range: time_range };
        json = JSON.stringify(json);

        if (time_range === "7") {
            dispatch(setChirperAnalyticsWeek(null));
        } else if (time_range === "30") {
            dispatch(setChirperAnalyticsMonth(null));
        } else if (time_range === "365") {
            dispatch(setChirperAnalyticsYear(null));
        } else if (time_range === "all") {
            dispatch(setChirperAnalyticsAll(null));
        }

        executeBasicNetworkCall("chirper_analytics", json, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    dispatch(MessageActions.setError(x.message));
                } else {
                    if (time_range === "7") {
                        dispatch(setChirperAnalyticsWeek(x.data));
                    } else if (time_range === "30") {
                        dispatch(setChirperAnalyticsMonth(x.data));
                    } else if (time_range === "365") {
                        dispatch(setChirperAnalyticsYear(x.data));
                    } else if (time_range === "all") {
                        dispatch(setChirperAnalyticsAll(x.data));
                    }
                }
            }
        });
    };
}

export function getArticleActivityExpandedAnalytics(time_range, callback) {
    return (dispatch, getState) => {
        let json = { time_range: time_range };
        json = JSON.stringify(json);

        executeBasicNetworkCall("article_activity_expanded_analytics", json, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    dispatch(MessageActions.setError(x.message));
                } else {
                    callback(x.data);
                }
            }
        });
    };
}

export function getStreamFreshnessAnalytics() {
    return (dispatch, getState) => {
        let json = {};
        json = JSON.stringify(json);

        dispatch(setStreamFreshnessAnalytics(null));

        executeBasicNetworkCall("get_stream_freshness_analytics", json, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    dispatch(MessageActions.setError(x.message));
                } else {
                    dispatch(setStreamFreshnessAnalytics(x.data));
                }
            }
        });
    };
}

export function getEmployeeCirclesAndEmployeeList(callback = () => {}) {
    return (dispatch, getState) => {
        let json = {};
        json = JSON.stringify(json);

        executeBasicNetworkCall("get_employee_circles_and_employee_list", json, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    dispatch(MessageActions.setError(x.message));
                } else {
                    callback(x.data.circle_dropdown_data, x.data.user_dropdown_data);
                }
            }
        });
    };
}

export function createBatchReport(params, callback = () => {}, error = () => {}) {
    return (dispatch, getState) => {
        let json = JSON.stringify(params);

        executeBasicNetworkCall(
            "create_batch_report",
            json,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(MessageActions.setError(x.message));
                        error();
                    } else {
                        callback(x.data);
                    }
                }
            },
            () => {
                error();
                dispatch(MessageActions.setError("There was an error creating the report"));
            },
            false,
            () => {
                error();
                dispatch(MessageActions.setError("There was an error creating the report"));
            },
        );
    };
}

export function getAuthFunnelGraphAnalytics(
    start_datetime,
    end_datetime,
    callback = () => {},
    error = () => {}
) {
    return (dispatch, getState) => {
        let json = JSON.stringify({ start_datetime: start_datetime, end_datetime: end_datetime});

        dispatch(setAuthFunnelGraphData(null));

        executeBasicNetworkCall(
            "authentication_funnel_graph_analytics",
            json,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(MessageActions.setError(x.message));
                        error();
                    } else {
                        dispatch(setAuthFunnelGraphData(x.data));
                        callback(x.data);
                    }
                }
            },
            () => {
                error();
                dispatch(MessageActions.setError("There was an error creating the funnel report"));
            },
            false,
            () => {
                error();
                dispatch(MessageActions.setError("There was an error creating the funnel report"));
            },
        );
    };
}

export function getMonthlyContentCompetition(
    month,
    year,
    callback = () => {},
) {
    return (dispatch, getState) => {
        let json = JSON.stringify({ month, year });

        executeBasicNetworkCall(
            "gsl_monthly_content_competition",
            json,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(MessageActions.setError(x.message));
                        callback(false);
                    } else {
                        dispatch(setMonthlyContentCompetitionData(x.data));
                        callback(true, x.data);
                    }
                }
            },
            () => {
                callback(false);
                dispatch(MessageActions.setError("There was an error creating the monthly content report"));
            },
            false,
            () => {
                callback(false);
                dispatch(MessageActions.setError("There was an error creating the monthly content report"));
            },
        );
    };
}

export function getContentReport(
    start_datetime,
    end_datetime,
    callback = () => {},
) {
    return (dispatch, getState) => {
        let json = JSON.stringify({ start_datetime, end_datetime });

        executeBasicNetworkCall(
            "weekly_content_digest",
            json,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(MessageActions.setError(x.message));
                        callback(false);
                    } else {
                        dispatch(setContentReportData(x.data));
                        callback(true, x.data);
                    }
                }
            },
            () => {
                callback(false);
                dispatch(MessageActions.setError("There was an error creating the content report"));
            },
            false,
            () => {
                callback(false);
                dispatch(MessageActions.setError("There was an error creating the content report"));
            },
        );
    };
}

function setSummaryAnalyticsWeek(weekData) {
    return {
        type: types.SET_SUMMARY_ANALYTICS_WEEK,
        weekData,
    };
}

function setSummaryAnalyticsMonth(monthData) {
    return {
        type: types.SET_SUMMARY_ANALYTICS_MONTH,
        monthData,
    };
}

function setSummaryAnalyticsYear(yearData) {
    return {
        type: types.SET_SUMMARY_ANALYTICS_YEAR,
        yearData,
    };
}

function setSummaryAnalyticsAll(allData) {
    return {
        type: types.SET_SUMMARY_ANALYTICS_ALL,
        allData,
    };
}

function setLeaderboardAnalyticsWeek(weekData) {
    return {
        type: types.SET_LEADERBOARD_ANALYTICS_WEEK,
        weekData,
    };
}

function setLeaderboardAnalyticsMonth(monthData) {
    return {
        type: types.SET_LEADERBOARD_ANALYTICS_MONTH,
        monthData,
    };
}

function setLeaderboardAnalyticsYear(yearData) {
    return {
        type: types.SET_LEADERBOARD_ANALYTICS_YEAR,
        yearData,
    };
}

function setLeaderboardAnalyticsAll(allData) {
    return {
        type: types.SET_LEADERBOARD_ANALYTICS_ALL,
        allData,
    };
}

function setChirperAnalyticsWeek(weekData) {
    return {
        type: types.SET_CHIRPER_ANALYTICS_WEEK,
        weekData,
    };
}

function setChirperAnalyticsMonth(monthData) {
    return {
        type: types.SET_CHIRPER_ANALYTICS_MONTH,
        monthData,
    };
}

function setChirperAnalyticsYear(yearData) {
    return {
        type: types.SET_CHIRPER_ANALYTICS_YEAR,
        yearData,
    };
}

function setChirperAnalyticsAll(allData) {
    return {
        type: types.SET_CHIRPER_ANALYTICS_ALL,
        allData,
    };
}

function setStreamFreshnessAnalytics(streamFreshnessData) {
    return {
        type: types.SET_STREAM_FRESHNESS_DATA,
        streamFreshnessData,
    };
}

function setAuthFunnelGraphData(authFunnelGraphData) {
    return {
        type: types.SET_AUTH_FUNNEL_GRAPH_DATA,
        authFunnelGraphData,
    };
}

function setMonthlyContentCompetitionData(monthlyContentCompetitionData) {
    return {
        type: types.SET_MONTHLY_CONTENT_COMPETITION_DATA,
        monthlyContentCompetitionData,
    };
}

function setContentReportData(contentReportData) {
    return {
        type: types.SET_CONTENT_REPORT_DATA,
        contentReportData,
    };
}
