import { useState, useEffect } from "react";

// Hook
export function useMedia(queries, values, defaultValue) {
    // Array containing a media query list for each query
    const mediaQueryLists = queries.map((q) => window.matchMedia(q));

    // Function that gets value based on matching media query
    const getValue = () => {
        // Get index of first media query that matches
        const index = mediaQueryLists.findIndex((mql) => mql.matches);
        // Return related value or defaultValue if none
        return typeof values[index] !== "undefined"
            ? values[index]
            : defaultValue;
    };

    // State and setter for matched value
    const [value, setValue] = useState(getValue);

    useEffect(
        () => {
            // Event listener callback
            // Note: By defining getValue outside of useEffect we ensure that it has ...
            // ... current values of hook args (as this hook callback is created once on mount).
            const handler = () => setValue(getValue);
            // Set a listener for each media query with above handler as callback.
            mediaQueryLists.forEach((mql) => mql.addListener(handler));
            // Remove listeners on cleanup
            return () =>
                mediaQueryLists.forEach((mql) => mql.removeListener(handler));
        },
        // eslint-disable-next-line
        [], // Empty array ensures effect is only run on mount and unmount
    );

    return value;
}

export function useMountEffect(fun) {
    // eslint-disable-next-line
    useEffect(fun, []);
}

export const useScript = (url) => {
    useEffect(() => {
        let isDev = false;
        if (process.env.NODE_ENV === "development") {
            isDev = true;
        }
        const script = document.createElement("script");
        if (!isDev) {
            script.src = url;
            script.async = true;
        }

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [url]);
};

export function useDebounce(value, delay, callback = () => {}) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
            callback();
        }, delay);

        return () => {
            clearTimeout(handler);
        };
        // eslint-disable-next-line
    }, [value, delay]);

    return debouncedValue;
}
