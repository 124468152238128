// import { HOST } from "Constants";
// import futch from "lib/futch";
import { executeBasicNetworkCall } from "lib/Utils";
import * as types from 'QRG/actions/types.js';

// Guide-based
export function createQRG(topic_id = '', callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "create_quick_reference_guide",
            JSON.stringify({ topic_id: topic_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function getQRGDetails(topic_id = '', qrg_id = '', callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_quick_reference_guide_details",
            JSON.stringify({ topic_id: topic_id, qrg_id: qrg_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        dispatch(setQRGDetails(x.data));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function editQRG(
    topic_id = '',
    qrg_id = '',
    title = undefined,
    pdf_file_url = undefined,
    in_season_br_districts = undefined,
    out_of_season_br_districts = undefined,
    callback = () => {},
) {
    return (dispatch, getState) => {
        let params = { topic_id: topic_id, qrg_id: qrg_id };

        if (title !== undefined && title !== null) {
            params.title = title;
        }
        if (pdf_file_url !== undefined && pdf_file_url !== null) {
            params.pdf_file_url = pdf_file_url;
        }
        if (in_season_br_districts !== undefined && in_season_br_districts !== null) {
            params.in_season_br_districts = in_season_br_districts;
        }
        if (out_of_season_br_districts !== undefined && out_of_season_br_districts !== null) {
            params.out_of_season_br_districts = out_of_season_br_districts;
        }

        executeBasicNetworkCall(
            "edit_quick_reference_guide",
            JSON.stringify(params),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function archiveQRG(topic_id = '', qrg_id = '', callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "archive_quick_reference_guide",
            JSON.stringify({ topic_id: topic_id, qrg_id: qrg_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

// this endpoint updated & moved into src/actions/media.js
// export function uploadPDFToAzure(
//     auth_token,
//     pdf,
//     successCallback = () => {},
//     returnType = "url",
//     failureCallback = () => {},
//     progressCallback = (progress) => {
//         // eslint-disable-next-line no-console
//         console.log("progress", progress);
//     },
// ) {
//     return (dispatch, getState) => {
//         const endpoints = getState().storydynamics.endpoints;

//         // Do some basic validation
//         if (endpoints === null) {
//             return;
//         }
//         // BUILD THE API URL
//         const API_VERSION = endpoints.current_api_version;
//         const ENDPOINT = getURLForEndpointName("upload_pdf", endpoints.urls);
//         const URL = `${HOST}/API/${API_VERSION}${ENDPOINT}`;

//         const formData = new FormData();
//         formData.append("return_type", returnType);
//         formData.append("auth_token", auth_token);

//         let hasMedia = false;
//         if (pdf && pdf !== null) {
//             hasMedia = true;
//             formData.append("pdf", pdf);
//         }

//         futch(
//             URL,
//             {
//                 method: "POST",
//                 body: formData,
//                 headers: {
//                     // 'Content-Type': "multipart/form-data; boundary=Boundary+C95830F6ED24D5D1"
//                 },
//             },
//             (progressEvent) => {
//                 const progress = progressEvent.loaded / progressEvent.total;
//                 progressCallback(progress);
//             },
//         ).then(
//             (response) => {
//                 if (response.responseText) {
//                     const x = JSON.parse(response.responseText);
//                     if (x.error) {
//                         failureCallback(false);
//                     } else {
//                         if (x && x.data && x.data.length > 0 && hasMedia) {
//                             // we just uploaded an image. Get the Azure URL and return that to the editor
//                             successCallback(true, x.data);
//                         }
//                     }
//                 }
//             },
//             (err) => {
//                 // eslint-disable-next-line no-console
//                 console.warn(err);
//                 failureCallback();
//             },
//         );
//     };
// }

export function setQRGDetails(data) {
    return {
        type: types.SET_QRG_DETAILS,
        data: data,
    };
}

export function setViewQRGListBy(data) {
    return {
        type: types.SET_VIEW_QRG_LIST_BY,
        data: data,
    };
}
