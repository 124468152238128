// STORY DYNAMICS
export const SET_ENDPOINT_URLS = "SET_ENDPOINT_URLS";
export const SET_IS_LOGGED_IN_STATUS = "SET_IS_LOGGED_IN_STATUS";
export const SET_LOGIN = "SET_LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_TRUSTED_DEVICE_REQUESTED = "SET_TRUSTED_DEVICE_REQUESTED";
export const SET_ARTICLES = "SET_ARTICLES";
export const SAVE_ARTICLE = "SAVE_ARTICLE";
export const CLEAR_ARTICLES = "CLEAR_ARTICLES";
export const SET_CONFIGURE = "SET_CONFIGURE";
export const SET_CREATE_USER_RESPONSE = "SET_CREATE_USER_RESPONSE";
export const SET_ACTIVE_STREAM_TAB = "SET_ACTIVE_STREAM_TAB";
export const SET_ARTICLE_LIKE_STATUS = "SET_ARTICLE_LIKE_STATUS";
export const SET_SAVED_ARTICLES_INFO = "SET_SAVED_ARTICLES_INFO";
export const SET_ARTICLE_SHARED_STATUS = "SET_ARTICLE_SHARED_STATUS";
export const SET_TEAM_PHOTOS = "SET_TEAM_PHOTOS";
export const SET_BASF_USERS = "SET_BASF_USERS";

// ERROR
export const SET_ERROR = "SET_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

// SUCCESS
export const SET_SUCCESS = "SET_SUCCESS";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";

// USER
export const SET_UUID = "SET_UUID";
export const SET_FCMS = "SET_FCMS";

// Widget State
export const DETECTED_POSSIBLE_ORIENTATION_CHANGE = "DETECTED_POSSIBLE_ORIENTATION_CHANGE";
export const TOGGLE_CHANGELOG_VISIBILITY = "TOGGLE_CHANGELOG_VISIBILITY";

export const TOGGLE_SIDEBAR_VISIBLE___WEB = "TOGGLE_SIDEBAR_VISIBLE___WEB";
export const SET_MAIN_TOUR_AS_WATCHED___WEB = "SET_MAIN_TOUR_AS_WATCHED___WEB";
export const SET_SELECTED_COMPOSE_TAB = "SET_SELECTED_COMPOSE_TAB";

export const SET_EDITOR_TOOLBAR_DISPLAY = "SET_EDITOR_TOOLBAR_DISPLAY";

//ARTICLES
export const SET_MY_ARTICLES = "SET_MY_ARTICLES";
export const LOAD_MY_ARTICLES = "LOAD_MY_ARTICLES";

export const SET_STATE_STREAM = "SET_STATE_STREAM";
export const LOAD_STATE_STREAM = "LOAD_STATE_STREAM";

export const SET_ALL_ARTICLES = "SET_ALL_ARTICLES";
export const LOAD_ALL_ARTICLES = "LOAD_ALL_ARTICLES";

export const SET_OTHER_ARTICLES = "SET_OTHER_ARTICLES";
export const LOAD_OTHER_ARTICLES = "LOAD_OTHER_ARTICLES";

export const SET_OTHER_STREAM = "SET_OTHER_STREAM";
export const LOAD_OTHER_STREAM = "LOAD_OTHER_STREAM";

export const TOGGLE_ARTICLE_LIKE = "TOGGLE_ARTICLE_LIKE";
export const TOGGLE_ARTICLE_SHARE = "TOGGLE_ARTICLE_SHARE";

export const SET_NEWS_DETAILS = "SET_NEWS_DETAILS";

//REPORTS
export const SET_SUMMARY_ANALYTICS_WEEK = "SET_SUMMARY_ANALYTICS_WEEK";
export const SET_SUMMARY_ANALYTICS_MONTH = "SET_SUMMARY_ANALYTICS_MONTH";
export const SET_SUMMARY_ANALYTICS_YEAR = "SET_SUMMARY_ANALYTICS_YEAR";
export const SET_SUMMARY_ANALYTICS_ALL = "SET_SUMMARY_ANALYTICS_ALL";

export const SET_LEADERBOARD_ANALYTICS_WEEK = "SET_LEADERBOARD_ANALYTICS_WEEK";
export const SET_LEADERBOARD_ANALYTICS_MONTH = "SET_LEADERBOARD_ANALYTICS_MONTH";
export const SET_LEADERBOARD_ANALYTICS_YEAR = "SET_LEADERBOARD_ANALYTICS_YEAR";
export const SET_LEADERBOARD_ANALYTICS_ALL = "SET_LEADERBOARD_ANALYTICS_ALL";

export const SET_CHIRPER_ANALYTICS_WEEK = "SET_CHIRPER_ANALYTICS_WEEK";
export const SET_CHIRPER_ANALYTICS_MONTH = "SET_CHIRPER_ANALYTICS_MONTH";
export const SET_CHIRPER_ANALYTICS_YEAR = "SET_CHIRPER_ANALYTICS_YEAR";
export const SET_CHIRPER_ANALYTICS_ALL = "SET_CHIRPER_ANALYTICS_ALL";

export const SET_STREAM_FRESHNESS_DATA = "SET_STREAM_FRESHNESS_DATA";

export const SET_AUTH_FUNNEL_GRAPH_DATA = "SET_AUTH_FUNNEL_GRAPH_DATA";
export const SET_MONTHLY_CONTENT_COMPETITION_DATA = "SET_MONTHLY_CONTENT_COMPETITION_DATA";
export const SET_CONTENT_REPORT_DATA = "SET_CONTENT_REPORT_DATA";

//COMPOSE
export const SET_DRAFTS = "SET_DRAFTS";
export const SET_HISTORY = "SET_HISTORY";

// QUEUE
export const ENQUEUE_JOB = "ENQUEUE_JOB";
export const DEQUEUE_JOB = "DEQUEUE_JOB";
export const CLEAR_QUEUE = "CLEAR_QUEUE";

// CRM
export const SET_USER_LIST = "SET_USER_LIST";
export const SET_ASSOCIATION_LIST = "SET_ASSOCIATION_LIST";
export const SET_USER_DETAILS_INFO = "SET_USER_DETAILS_INFO";
export const SET_ASSOCIATION_DETAILS_INFO = "SET_ASSOCIATION_DETAILS_INFO";
export const CHANGE_MY_DETAILS = "CHANGE_MY_DETAILS";
export const CHANGE_USER_DETAILS = "CHANGE_USER_DETAILS";
export const CHANGE_ASSOCIATION_DETAILS = "CHANGE_ASSOCIATION_DETAILS";
export const CLEAR_USER_DETAILS = "CLEAR_USER_DETAILS";
export const CLEAR_ASSOCIATION_DETAILS = "CLEAR_ASSOCIATION_DETAILS";
export const RESET_CRM_STATE = "RESET_CRM_STATE";
export const SET_USER_ACTIVITY_DETAILS = "SET_USER_ACTIVITY_DETAILS";
export const SET_USER_DEVICE_DETAILS_INFO = "SET_USER_DEVICE_DETAILS_INFO";
export const SET_DROPDOWN_OPTIONS = "SET_DROPDOWN_OPTIONS";
export const UPDATE_SELF = "UPDATE_SELF";

// CLAIM LITE USER
export const SET_UNCLAIMED_LITE_USERS = "SET_UNCLAIMED_LITE_USERS";

//CHAT
export const SET_CHAT_USER_TOKEN = "SET_CHAT_USER_TOKEN";

// VIDEO_EDITOR
export const SET_EDITOR_VIDEO_LIST = "SET_EDITOR_VIDEO_LIST";
export const SET_EDITOR_VIEW_MODE = "SET_EDITOR_VIEW_MODE";

// HASHTAG
export const SET_EXCLUDE_POST_IDS = "SET_EXCLUDE_POST_IDS";

// PROJECT
export const SET_PROJECT_INFO = "SET_PROJECT_INFO";
