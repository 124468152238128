import * as WidgetStateActions from "./widgetstate";
import * as StoryDynamicActions from "./storydynamics";
import * as MediaActions from "./media";
import * as MessagesActions from "./messages";
import * as ArticleActions from "./articles";
import * as AllDraftActions from "AllDrafts/actions";
import * as ReportsActions from "./reports";
import * as ComposeActions from "./compose";
import * as QueueActions from "./queue";
import * as CRMActions from "./crm";
import * as ObservationActions from "./observations";

import * as ResponsiveActions from "ResponsiveManager/redux/actions";
// import * as TransferActions from "./transfer";
// import { AgCalcActionCreators } from "../AgronomicCalculator/actions";

import * as HashtagAnalyticsActions from "./hashtag_analytics";
import * as VideoEditorActions from "./video_editor";
import { QRGActionCreators } from "QRG/actions";

import { DeveloperActionCreators } from "../Developer/actions";
import { ClaimLiteActionCreators } from "../ClaimLiteUser/actions";
import { CronJobManagerActionCreators } from "../CronJobManager/actions";

import { CropProductInfoActionCreators } from "CropProductInfo/actions";
import { ContentManagerActionCreators } from "ContentManager/actions";

export const ActionCreators = Object.assign(
    {},
    WidgetStateActions,
    StoryDynamicActions,
    MediaActions,
    MessagesActions,
    ArticleActions,
    AllDraftActions,
    ReportsActions,
    ComposeActions,
    QueueActions,
    CRMActions,
    ObservationActions,

    ResponsiveActions,
    // TransferActions,
    // AgCalcActionCreators,

    VideoEditorActions,
    QRGActionCreators,

    DeveloperActionCreators,
    ClaimLiteActionCreators,
    CronJobManagerActionCreators,
    HashtagAnalyticsActions,

    CropProductInfoActionCreators,
    ContentManagerActionCreators,
);
