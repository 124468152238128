import { executeBasicNetworkCall } from "lib/Utils";
import * as types from 'QRG/actions/types.js';

export function getTopics(callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_topics",
            null,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                    } else {
                        dispatch(setTopics(x.data));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function createTopic(topic_name = '', callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "create_topic",
            JSON.stringify({ topic_name: topic_name }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        // dispatch(setTopics(x.data));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function archiveTopic(topic_id = '', callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "archive_topic",
            JSON.stringify({ topic_id: topic_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function getTopicDetails(topic_id = '', callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_topic_details",
            JSON.stringify({ topic_id: topic_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        dispatch(setTopicDetails(x.data));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}


function setTopics(data) {
    return {
        type: types.SET_TOPICS,
        data: data,
    };
}

function setTopicDetails(data) {
    return {
        type: types.SET_TOPIC_DETAILS,
        data: data,
    };
}
