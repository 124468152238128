import React from "react";
import { NavLink } from "react-router-dom";
import Flexbox from "flexbox-react";
import { recordAnalytics } from "lib/analytics";
import "styles/App.css";

import * as Constants from "Constants";

function SidebarItem(props) {
    const renderIcon = () => {
        if (props.iconName) {
            const icon2x = require("assets/" + props.iconName + "@2x.png");
            const icon3x = require("assets/" + props.iconName + "@3x.png");

            return (
                <img
                    alt=""
                    src={require("assets/" + props.iconName + ".png")}
                    srcSet={`
            ${icon2x} 2x,
            ${icon3x} 3x
          `}
                />
            );
        } else if (props.svgIconName) {
            return <img alt="" src={require(`assets/${props.svgIconName}.svg`)} width={24} />;
        }
        return <div style={{ width: 26 }} />;
    };

    const onClick = (e) => {
        let tealium_dict = {
            view_name: "sidebar",
            event_type: "click",
            event_name: "navigate_to_" + props.title.toLowerCase().replace(" ", "_"),
            tealium_event: "navigate_to_" + props.title.toLowerCase().replace(" ", "_"),
        };
        recordAnalytics(tealium_dict, "Sidebar", "Navigate", props.title);
        props.onClick(e);
    };


    let data_cy = "sidebar-" + props.title.replace(/ /g, "_");
    data_cy = data_cy.replace(/'/g, "");

    let leftPadding = 28;
    if (props.submenuLevel) {
        leftPadding += 28 * props.submenuLevel;
    }
    if (props.link === "/") {
        return (
            <NavLink
                exact
                className="sidebarNav"
                onClick={onClick}
                data-cy={data_cy}
                to={props.link}
                style={{
                    textDecoration: "none",
                    backgroundColor: Constants.TertiaryColor,
                    width: "100%",
                }}
                activeStyle={{
                    backgroundColor: Constants.DarkestTertiaryColor,
                }}>
                <Flexbox
                    data-tut={props.dataTut}
                    flexDirection="row"
                    alignItems="center"
                    style={{
                        color: "white",
                        textDecoration: "none",
                        paddingLeft: leftPadding,
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingRight: 20,
                    }}>
                    {renderIcon()}
                    <div style={{ marginLeft: 10 }}>{props.title}</div>
                </Flexbox>
            </NavLink>
        );
    }

    if (!props.link) {
        return (
            <div
                className="sidebarNav"
                onClick={onClick}
                data-cy={data_cy}
                style={{
                    textDecoration: "none",
                    backgroundColor: Constants.TertiaryColor,
                    width: "100%",
                    cursor: "pointer",
                }}>
                <Flexbox
                    data-tut={props.dataTut}
                    flexDirection="row"
                    alignItems="center"
                    style={{
                        color: "white",
                        textDecoration: "none",
                        paddingLeft: leftPadding,
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingRight: 20,
                    }}>
                    {renderIcon()}
                    <div style={{ marginLeft: 10 }}>{props.title}</div>
                </Flexbox>
            </div>
        );
    }

    return (
        <NavLink
            exact={props.exact}
            className="sidebarNav"
            onClick={onClick}
            data-cy={data_cy}
            to={props.link}
            style={{
                textDecoration: "none",
                backgroundColor: Constants.TertiaryColor,
                width: "100%",
            }}
            activeStyle={{
                backgroundColor: Constants.DarkestTertiaryColor,
            }}>
            <Flexbox
                data-tut={props.dataTut}
                flexDirection="row"
                alignItems="center"
                style={{
                    color: "white",
                    textDecoration: "none",
                    paddingLeft: leftPadding,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingRight: 20,
                }}>
                {renderIcon()}
                <div style={{ marginLeft: 10 }}>{props.title}</div>
                {props.badge && props.badge > 0 ?
                    <div style={{
                        marginLeft: 5,
                        width: 25,
                        height: 25,
                        borderRadius: "50%",
                        color: "#FFFFFF",
                        backgroundColor: "red",
                        textAlign: "center",
                    }}>
                        {props.badge}
                    </div>
                    : null}
            </Flexbox>
        </NavLink>
    );
}

export default SidebarItem;
