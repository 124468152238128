import createReducer from "lib/createReducer";
import * as types from "CropProductInfo/actions/types";
import { ViewCropProductInfoBy } from "CropProductInfo/Constants";

const DEFAULT_STATE = {
    view_crop_product_info_list_by: ViewCropProductInfoBy.Product,

    crop_list: [],
    product_list: [],
    district_list: [],
    tools_list: [],
};

export const cropProductInfo = createReducer(
    DEFAULT_STATE,
    {
        [types.SET_VIEW_CROP_PRODUCT_INFO_LIST_BY](state, action) {
            let newState = Object.assign({}, state);
            newState.view_crop_product_info_list_by = action.data;
            return newState;
        },

        [types.SET_CROP_LIST](state, action) {
            let newState = Object.assign({}, state);
            newState.crop_list = action.data;
            return newState;
        },

        [types.SET_PRODUCT_LIST](state, action) {
            let newState = Object.assign({}, state);
            newState.product_list = action.data;
            return newState;
        },

        [types.SET_DISTRICTS](state, action) {
            let newState = Object.assign({}, state);
            newState.district_list = action.data;
            return newState;
        },

        [types.SET_TOOLS_LIST](state, action) {
            let newState = Object.assign({}, state);
            newState.tools_list = action.data;
            return newState;
        },

    }
);
