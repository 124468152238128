import React, { Component } from "react";

import JobQueueView from "views/Sidebar/JobQueueView";

import { connect } from "react-redux";
import { ActionCreators } from "actions";
import { bindActionCreators } from "redux";

import { withRouter } from "react-router";

import { JOB_STATE_PENDING, JOB__PUBLISH, JOB__IMAGE_UPLOAD, JOB__VIDEO_UPLOAD } from "Constants";

class JobQueueDirector extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeJob: null,
            progress: 0,
            isUploadingArticle: false,
            currentMediaIndex: 0,
            totalMediaToUpload: 0,
        };
    }

    componentDidMount() {
        this._processQueue();
    }

    componentDidUpdate(prevProps) {
        if (this.props.uploadQueue !== prevProps.uploadQueue) {
            this._processQueue();
        }
    }

    _clearQueue = (event) => {
        this.props.clearJobQueue();
        this.setState({ activeJob: null });
        event.stopPropagation();
    };

    _processQueue = () => {
        if (this.state.activeJob && this.state.activeJob !== null) {
            return; // we already have an active job.
        }

        const job = this.props.uploadQueue.find((job) => {
            if (job.state === JOB_STATE_PENDING) {
                return job;
            }
            this.setState({ progress: 0 });
            return null;
        });

        if (job) {
            // try processing the next job on the queue
            this.setState({ activeJob: job });

            switch (job.jobType) {
                case JOB__PUBLISH:
                    // let's process this job
                    // this.setState({ isBurst: true })
                    this.startPublishProcessWithJob(job);
                    break;
                case JOB__IMAGE_UPLOAD:
                    // this.startImageUploadWithData(job.data);
                    break;
                case JOB__VIDEO_UPLOAD:
                    // this.startVideoUploadWithData(job.data);
                    break;
                default:
            }
        }
    };

    render() {
        return (
            <JobQueueView
                activeJob={this.state.activeJob}
                clearQueue={this._clearQueue}
                progress={this.state.progress}
                isUploadingArticle={this.state.isUploadingArticle}
                currIndex={this.state.currentMediaIndex}
                totalMediaToUpload={this.state.totalMediaToUpload}
                failed={this.state.failed}
            />
        );
    }

    /**
     Burst Creation Uploading Job
     */
    startPublishProcessWithJob = (job) => {
        if (!job || !job.payload) {
            return;
        }

        // do we need to upload any media?
        this.mediaToUpload = [];

        this.articleUploadData = {
            title: job.payload.title,
            abstract: job.payload.abstract,
            release_date: job.payload.release_date,
            is_draft: job.payload.is_draft,
            media: [],
            original_id: job.payload.original_id,
            burst_id: job.payload.burst_id,
            isUpdate: job.payload.isUpdate,
            isDuplicate: job.payload.isDuplicate,
            isEdit: job.payload.isEdit,
        };

        this.mediaArray = [];
        let data = job.payload;

        if (data.updateMediaCallback) {
            this.mediaCallback = data.updateMediaCallback;
        }

        for (let i = 0; i < data.media.length; i++) {
            const media = data.media[i];
            this.mediaArray[i] = media;

            if (media && media !== null) {
                let mediaObj;

                if (media.video) {
                    mediaObj = media.video;
                    this.articleUploadData.media[i] = { caption: media.file_caption };
                } else if (media.image) {
                    mediaObj = media.image;
                    this.articleUploadData.media[i] = { caption: media.file_caption };
                }

                if (mediaObj && mediaObj.isRemote && mediaObj.id) {
                    // this is a cloud media object that has already been uploaded.
                    this.articleUploadData.media[i].id = mediaObj.id;
                } else if (mediaObj) {
                    // this is a local media object that needs to be uploaded.
                    this.mediaToUpload.push({ media, index: i });
                }
            }
        }
        this.setState({ totalMediaToUpload: this.mediaToUpload.length });

        this._uploadMedia();
    };
    _uploadMedia = () => {
        if (this.mediaToUpload.length <= 0) {
            // no more left in the job queue

            this.setState({ isUploadingMedia: false, isUploadingArticle: true });

            if (this.articleUploadData.is_draft) {
                this.props.saveDraft(this.articleUploadData, this._didFinishPublish);

                if (this.mediaCallback) {
                    this.mediaCallback(this.mediaArray);
                }
            } else if (this.articleUploadData.isUpdate) {
                this.props.updateArticle(this.articleUploadData, this._didFinishPublish);
            } else {
                this.props.createNewVideoArticle(this.articleUploadData, this._didFinishPublish);
            }
            return;
        }

        const media = this.mediaToUpload[0].media;
        const userNote = "";
        const tags = [];
        if (media.video) {
            const video = media.video;

            this.props.uploadVideo(
                video,
                this.props.uid,
                userNote,
                tags,
                this._mediaUploadDidSendMoreData,
                this._didFinishUploadingMedia,
            );
            //  this.props.uploadVideoToServer(video, this.props.uid, userNote, tags, this._mediaUploadDidSendMoreData, this._didFinishUploadingMedia)
        } else {
            const image = media.image;

            this.props.uploadImage(
                image,
                this.props.uid,
                userNote,
                tags,
                this._mediaUploadDidSendMoreData,
                this._didFinishUploadingMedia,
            );
        }
    };
    _mediaUploadDidSendMoreData = (progress) => {
        const curIndex = this.state.totalMediaToUpload - this.mediaToUpload.length;

        let jobProgress = progress * 100;
        // curIndex / totalMediaToUpload + (progress / totalMediaToUpload) * 100;

        this.setState({
            currentMediaIndex: curIndex + 1,
            isUploadingMedia: true,
            progress: jobProgress,
        });
    };
    _didFinishUploadingMedia = (success, message) => {
        if (success) {
            const response = JSON.parse(message);

            const media = this.mediaToUpload[0];
            const index = media.index;

            this.articleUploadData.media[index].id = response.media_id;
            if (media.media && media.media.video) {
                this.mediaArray[index].video = { isRemote: true, id: response.media_id };
            } else {
                this.mediaArray[index].image = { isRemote: true, id: response.media_id };
            }

            this.setState({ failed: false });
            this.mediaToUpload.shift();
            this._uploadMedia();
        } else {
            this.setState({ failed: true });
        }
    };
    _didFinishPublish = (success, message) => {
        if (success) {
            // remove job from queue
            this.setState({
                isUploadingArticle: false,
                failed: false,
                activeJob: null,
            });
            this.props.dequeueJob();

            if (!this.articleUploadData.is_draft) {
                if (this.articleUploadData.isEdit) {
                    this.props.history.push("/articles");
                } else {
                    this.props.history.push("/compose");
                }
            }
            //this.playSound();

            //  this.props.showAlertWithType('success', 'Upload Successful', `${this.getSuccessMessage()} ${this.props.fullName}!`)
        } else {
            this.setState({ failed: true });
        }
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
    return {
        uploadQueue: state.queue.jobs,
        uid: state.user.uid,
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobQueueDirector));
