import { executeBasicNetworkCall } from "lib/Utils";
import * as types from "./types";

export function getObservationDetails(observation_id, callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_observation",
            JSON.stringify({ observation_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => {
                callback(false);
            },
            false,
            (status) => {
                callback(false);
            },
        );
    };
}

export function editObservationDetails(
    observation_id,
    title,
    message,
    azure_media_id,
    vimeo_video_id,
    callback = () => { }
) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "edit_observation",
            JSON.stringify({ observation_id, title, message, azure_media_id, vimeo_video_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => {
                callback(false);
            },
            false,
            (status) => {
                callback(false);
            },
        );
    };
}

export function publishObservation(
    auth_token,
    observation_id,
    is_customer_exclusive,
    states_list,
    br_districts_list,
    br_regions_list,
    content_category_ids,
    crop_ids,
    product_ids,
    callback = () => {},
) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "publish_observation",
            JSON.stringify({
                auth_token,
                observation_id,
                is_customer_exclusive,
                states_list,
                br_districts_list,
                br_regions_list,
                content_category_ids,
                crop_ids,
                product_ids,
            }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => {
                callback(false);
            },
            false,
            (status) => {
                callback(false);
            },
        );
    };
}

export function getObservationProjectDetails(observation_id, callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_content_project_details",
            JSON.stringify({ observation_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(setProjectInfo(null));
                        callback(false);
                    } else {
                        dispatch(setProjectInfo(x.data));
                        callback(true, x.data);
                    }
                }
            },
        );
    };
}

export function deleteObservationDraft(
    observation_id,
    callback = () => {},
) {
    return (dispatch, getState) => {
        let params = { observation_id };
        params = JSON.stringify(params);

        executeBasicNetworkCall(
            "delete_observation_draft",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true);
                    }
                }
            },
        );
    };
}

function setProjectInfo(data = null) {
    return {
        type: types.SET_PROJECT_INFO,
        data,
    };
}
