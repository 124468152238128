import { HOST, API_VERSION } from "Constants";
import futch from "../lib/futch";
import * as MessageActions from "./messages";
import { getURLForEndpointName } from "lib/Utils";
import { v4 as uuidv4 } from "uuid";

// export function uploadImage(image, tags, note, uid, success) {
export function uploadImage(image, uid, note = "", tags = [], progressCallback, completionCallback) {
    return (dispatch, getState) => {
        // Do some basic validation
        if (!image || image === null) {
            return;
        }

        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();
        const payload_token_identifier = uid + "_" + year + month + day + "_" + uuidv4();
        // finish payload token generation

        // build image & thumbnail names
        const imageName = "image_" + uid + "_" + uuidv4();
        const thumbnailName = "thumbnail_" + uid + "_" + uuidv4();

        // BUILD THE API URL
        const ENDPOINT = "/API/v1/ImageUpload";
        const URL = `${HOST}${ENDPOINT}`;

        let mediaOneObj = {
            liked: 0,
            stored_at: "local",
            asset_type: 1,
            file_path: null,
            mime_type: image.type,
            date_liked: null,
            location: null,
            date_captured: today.toISOString(),
            id: 0,
            uploaded_file_name: imageName,
            content_checksum: null,
            azure_date: null, //or azureData
        };

        let mediaArray = [mediaOneObj];

        let mediaRadix = {
            tags: tags,
            media: mediaArray,
            user_created_note: note, //"test note",
            release_date: "",
            burst_info: null,
            burst_found: 0,
            blog_found: 0,
            id: 0,
            composite_file_name: imageName,
            thumbnail_file_name: thumbnailName,
            date_received: null,
        };

        let burstMediaRadixObject = {
            payload_token_identifier,
            media_radix: mediaRadix,
            version: 1,
            uid,
            image: null,
            thumbnail: null,
            image_name: imageName,
            thumbnail_name: thumbnailName,
        };

        const data = new FormData();
        data.append("json_data", JSON.stringify([burstMediaRadixObject]));

        data.append(imageName, image);

        futch(
            URL,
            {
                method: "POST",
                body: data,
                headers: {},
            },
            (progressEvent) => {
                const progress = progressEvent.loaded / progressEvent.total;

                progressCallback(progress);
            },
        )
            .then((res) => {
                if (res.status !== 500) {
                    const response = JSON.parse(res.response);

                    if (response) {
                        if (JSON.parse(res.response).error) {
                            completionCallback(false, "There was an error uploading your image");
                            dispatch(MessageActions.setError("There was an error uploading your image"));
                        } else {
                            completionCallback(true, res.response);
                            dispatch(MessageActions.setSuccess("Your image was uploaded successfully"));
                        }
                    }
                } else {
                    completionCallback(false, "There was an error uploading your image");
                    dispatch(MessageActions.setError("There was an error uploading your image"));
                }
            })
            .catch((err) => {
                completionCallback(false, "There was an error uploading your image");
                dispatch(MessageActions.setError("There was an error uploading your image"));
            });
    };
}

export function uploadVideo(video, uid, userNote = "", tags = [], progressCallback, completionCallback) {
    return (dispatch, getState) => {
        // Do some basic validation
        if (!video || video === null) {
            return;
        }

        // build payload token identifier
        const user = getState().user;
        if (!user || user === null || !user.fullName) {
            return;
        }
        const usernameWithoutSpaces = user.fullName.split(" ").join("");

        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();
        const payload_token_identifier = usernameWithoutSpaces + "_" + year + month + day + "_" + uuidv4();
        // finish payload token generation

        // build image & thumbnail names
        const videoName = "video_" + usernameWithoutSpaces + "_" + uuidv4();
        const thumbnailName = "thumbnail" + usernameWithoutSpaces + "_" + uuidv4();

        // BUILD THE API URL
        const URL = HOST + "/API/" + API_VERSION + "/FileUpload";

        if (!video.type || video.type === null) {
            video.type = "video/mp4";
        }

        if (!video.name || video.name === null) {
            video.name = videoName + ".mp4";
        }

        let mediaOneObj = {
            liked: 0,
            stored_at: "local",
            asset_type: 2,
            file_path: null,
            mime_type: video.type, // CHECK THIS
            date_liked: null,
            location: null,
            date_captured: today.toISOString(),
            id: 0,
            uploaded_file_name: video.name, // CHECK THIS!
            content_checksum: null,
            azure_date: null, //or azureData
        };

        let mediaArray = [mediaOneObj];

        let mediaRadix = {
            tags: tags,
            media: mediaArray,
            user_created_note: userNote, //"test note",
            release_date: "",
            burst_info: null,
            burst_found: 0,
            blog_found: 0,
            id: 0,
            composite_file_name: video.name, // CHECK THIS
            thumbnail_file_name: thumbnailName,
            date_received: null,
        };

        let burstMediaRadixObject = {
            payload_token_identifier,
            media_radix: mediaRadix,
            version: 1,
            uid,
            image: null,
            thumbnail: null,
            image_name: videoName,
            thumbnail_name: thumbnailName,
            file_type: 1, // 1=video, 2=image
            file_size: video.size,
        };

        const data = new FormData();
        data.append("json_data", JSON.stringify([burstMediaRadixObject]));
        data.append(video.name, video);

        futch(
            URL,
            {
                method: "POST",
                body: data,
                headers: {},
            },
            (progressEvent) => {
                const progress = progressEvent.loaded / progressEvent.total;
                progressCallback(progress);
            },
        )
            .then((res) => {
                const resp = JSON.parse(res.response);

                if (resp.error) {
                    dispatch(MessageActions.setError(resp.msg));
                } else {
                    dispatch(MessageActions.setSuccess("Your video was uploaded successfully"));
                }

                completionCallback(true, res.response);
            })
            .catch((err) => {
                dispatch(MessageActions.setError("There was an error uploading your video"));
            });
    };
}

// v3 media upload
export function uploadMediaToAzure(
    auth_token,
    image,
    video,
    successCallback = () => {},
    returnType = "url",
    failureCallback = () => {},
    progressCallback = (progress) => {
        // eslint-disable-next-line no-console
        console.log("progress", progress);
    },
    tags = [],
    note = "",
) {
    return (dispatch, getState) => {
        const endpoints = getState().storydynamics.endpoints;

        // Do some basic validation
        if (endpoints === null) {
            return;
        }
        // BUILD THE API URL
        const API_VERSION = endpoints.current_api_version;
        const ENDPOINT = getURLForEndpointName("upload_media_to_azure", endpoints.urls);
        const URL = `${HOST}/API/${API_VERSION}${ENDPOINT}`;

        const formData = new FormData();
        formData.append("return_type", returnType);
        formData.append("auth_token", auth_token);
        formData.append("tags", tags);
        formData.append("note", note);

        let hasMedia = false;
        if (image && image !== null) {
            hasMedia = true;
            formData.append("images", image);
        }
        if (video && video !== null) {
            // hasMedia = true;
            // formData.append("videos", video);

            // azure video no longer supported
            failureCallback(false);
            return;
        }

        futch(
            URL,
            {
                method: "POST",
                body: formData,
                headers: {
                    // 'Content-Type': "multipart/form-data; boundary=Boundary+C95830F6ED24D5D1"
                },
            },
            (progressEvent) => {
                const progress = progressEvent.loaded / progressEvent.total;
                progressCallback(progress);
            },
        ).then(
            (response) => {
                if (response.responseText) {
                    const x = JSON.parse(response.responseText);
                    if (x.error) {
                        failureCallback(false);
                    } else {
                        if (x && x.data && x.data.length > 0 && hasMedia) {
                            // we just uploaded an image.  Get the Azure URL and return that to the editor
                            let resp = x.data[0];
                            if (video !== null && returnType === "id") {
                                resp = x.data;
                            }
                            successCallback(true, resp);
                        }
                    }
                }
            },
            (err) => {
                // eslint-disable-next-line no-console
                console.warn(err);
                failureCallback();
            },
        );
    };
}

export function uploadPDFToAzure(
    auth_token,
    pdf,
    successCallback = () => {},
    returnType = "url",
    failureCallback = () => {},
    progressCallback = (progress) => {
        // eslint-disable-next-line no-console
        console.log("progress", progress);
    },
) {
    return (dispatch, getState) => {
        const endpoints = getState().storydynamics.endpoints;

        // Do some basic validation
        if (endpoints === null) {
            return;
        }
        // BUILD THE API URL
        const API_VERSION = endpoints.current_api_version;
        const ENDPOINT = getURLForEndpointName("upload_pdf_to_azure", endpoints.urls);
        const URL = `${HOST}/API/${API_VERSION}${ENDPOINT}`;

        const formData = new FormData();
        formData.append("return_type", returnType);
        formData.append("auth_token", auth_token);

        let hasMedia = false;
        if (pdf && pdf !== null) {
            hasMedia = true;
            formData.append("pdf", pdf);
        }

        futch(
            URL,
            {
                method: "POST",
                body: formData,
                headers: {
                    // 'Content-Type': "multipart/form-data; boundary=Boundary+C95830F6ED24D5D1"
                },
            },
            (progressEvent) => {
                const progress = progressEvent.loaded / progressEvent.total;
                progressCallback(progress);
            },
        ).then(
            (response) => {
                if (response.responseText) {
                    const x = JSON.parse(response.responseText);
                    if (x.error) {
                        failureCallback(false);
                    } else {
                        if (x && x.data && x.data.length > 0 && hasMedia) {
                            // we just uploaded an image. Get the Azure URL and return that to the editor
                            successCallback(true, x.data);
                        }
                    }
                }
            },
            (err) => {
                // eslint-disable-next-line no-console
                console.warn(err);
                failureCallback();
            },
        );
    };
}

export function uploadVideoToVimeo(
    auth_token,
    image = null,
    video,
    successCallback = () => {},
    returnType = "url",
    failureCallback = () => {},
    progressCallback = (progress) => {
        // eslint-disable-next-line no-console
        console.log("progress", progress);
    },
    tags = [],
    note = "",
) {
    return (dispatch, getState) => {
        const endpoints = getState().storydynamics.endpoints;
        if (endpoints === null) {
            return;
        }
        // BUILD THE API URL
        const API_VERSION = endpoints.current_api_version;
        const ENDPOINT = getURLForEndpointName(
            "upload_vimeo_video",
            endpoints.urls,
        );
        const URL = `${HOST}/API/${API_VERSION}${ENDPOINT}`;
        // const URL = `${HOST}/API/v1/v4video/UploadVimeoVideo`;

        const formData = new FormData();
        formData.append("return_type", returnType);
        formData.append("auth_token", auth_token);
        formData.append("tags", tags);
        formData.append("note", note);

        if (video && video !== null) {
            formData.append("videos", video);
        }

        futch(
            URL,
            {
                method: "POST",
                body: formData,
                headers: {
                    // 'Content-Type': "multipart/form-data; boundary=Boundary+C95830F6ED24D5D1"
                },
            },
            (progressEvent) => {
                const progress = progressEvent.loaded / progressEvent.total;
                progressCallback(progress);
            },
        ).then(
            (response) => {
                if (response.responseText) {
                    const x = JSON.parse(response.responseText);
                    if (x.error) {
                        failureCallback(false);
                    } else {
                        successCallback(true, x.data);
                    }
                }
            },
            (err) => {
                // eslint-disable-next-line no-console
                console.warn(err);
                failureCallback(false);
            },
        )
    };
}
