import { executeBasicNetworkCall } from "lib/Utils";
import * as types from 'CropProductInfo/actions/types.js';

export function getCropList(callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_crop_list",
            null,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                    } else {
                        dispatch(setCropList(x.data));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function createCrop(crop_name = '', callback = () => {}) {
    return (dispatch, getState) => {
        // executeBasicNetworkCall(
        //     "create_crop",
        //     JSON.stringify({ crop_name: crop_name }),
        //     "application/json",
        //     "POST",
        //     getState,
        //     (reqq) => {
        //         if (reqq.responseText != null) {
        //             const x = JSON.parse(reqq.responseText);
        //             if (x.error) {
        //                 callback(false, x.message);
        //             } else {
        //                 // dispatch(setCrops(x.data));
        //                 callback(true, x.data);
        //             }
        //         }
        //     },
        //     (err) => console.log(err),
        //     false,
        //     (status) => console.log(status),
        // );
    };
}

export function archiveCrop(crop_id = '', callback = () => {}) {
    return (dispatch, getState) => {
        // executeBasicNetworkCall(
        //     "archive_crop",
        //     JSON.stringify({ crop_id: crop_id }),
        //     "application/json",
        //     "POST",
        //     getState,
        //     (reqq) => {
        //         if (reqq.responseText != null) {
        //             const x = JSON.parse(reqq.responseText);
        //             if (x.error) {
        //                 callback(false, x.message);
        //             } else {
        //                 callback(true, x.data);
        //             }
        //         }
        //     },
        //     (err) => console.log(err),
        //     false,
        //     (status) => console.log(status),
        // );
    };
}

export function getCropDetails(crop_id = '', callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_crop_details",
            JSON.stringify({ crop_id: crop_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        dispatch(setCropDetails(x.data));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function editCrop(
    crop_id = null,
    link = '',
    description = '',
    bubble_image_id = null,
    feature_image_id = null,
    cta_label = '',
    cta_link = null,
    feature_video_id = null,
    tool_ids = [],
    callback = () => {}
) {
    let json = JSON.stringify({ crop_id, link, description, bubble_image_id, feature_image_id, cta_label, cta_link, feature_video_id, tool_ids });
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "edit_crop",
            json,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.message);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        )
    }
}

function setCropList(data) {
    return {
        type: types.SET_CROP_LIST,
        data: data,
    };
}

export function setCropDetails(data) {
    return {
        type: types.SET_CROP_DETAILS,
        data: data,
    };
}
