import React, {memo} from 'react';

import {Loader} from "semantic-ui-react";

const LazyLoadingComponentPlaceholder = (props) => {
    return (
        <Loader
            active={true}
            size="large"
        >Loading</Loader>
    )
}

export default memo(LazyLoadingComponentPlaceholder);