import * as ContentManagerActions from "./contentManager";
import * as ReviewerActions from "./reviewer";
import * as MyContentActions from "./myContent";
import * as MyReviewContentActions from "./myReviewContent";

export const ContentManagerActionCreators = Object.assign(
    {},
    ContentManagerActions,
    ReviewerActions,
    MyContentActions,
    MyReviewContentActions,
);
