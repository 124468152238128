import createReducer from "lib/createReducer";
import { MOBILE_MAX_WIDTH, PADDED_MIN_WIDTH, SIDEBAR_SHOWING_MIN_WIDTH } from "../index";
import * as types from "./types";

export const responsive = createReducer(
    {
        isResponsive: window.outerWidth < MOBILE_MAX_WIDTH,
        isPadded: window.outerWidth > PADDED_MIN_WIDTH,
        iPhoneSEMql: window.outerWidth < 350,
        sidebarShowing: window.outerWidth > SIDEBAR_SHOWING_MIN_WIDTH,
    },
    {
        [types.SET_IS_RESPONSIVE](state, action) {
            let newState = Object.assign({}, state);
            newState.isResponsive = action.isResponsive;
            return newState;
        },

        [types.SET_IS_PADDED](state, action) {
            let newState = Object.assign({}, state);
            newState.isPadded = action.isPadded;
            return newState;
        },

        [types.SET_IPHONE_SE_MQL](state, action) {
            let newState = Object.assign({}, state);
            newState.iPhoneSEMql = action.iPhoneSEMql;
            return newState;
        },

        [types.SET_SIDEBAR_SHOWING](state, action) {
            let newState = Object.assign({}, state);
            newState.sidebarShowing = action.sidebarShowing;
            return newState;
        },

    },
);
