import createReducer from "lib/createReducer";
import * as types from "CropProductInfo/actions/types";

const DEFAULT_STATE = {
    crop_name: '',
    district_code: '',
    region_name: '',

    fields: [],
    district_crop_products: [],
};

export const districtCropDetails = createReducer(
    DEFAULT_STATE,
    {
        [types.SET_DISTRICT_CROP_DETAILS](state, action) {
            let newState = Object.assign({}, state);
            if (action.data === null || action.data === undefined) {
                newState = DEFAULT_STATE;
            } else {
                if (action.data.hasOwnProperty('crop_name')) {
                    newState.crop_name = action.data.crop_name;
                }
                if (action.data.hasOwnProperty('district_code')) {
                    newState.district_code = action.data.district_code;
                }
                if (action.data.hasOwnProperty('region_name')) {
                    newState.region_name = action.data.region_name;
                }
                if (action.data.hasOwnProperty('fields')) {
                    newState.fields = action.data.fields;
                }
                if (action.data.hasOwnProperty('district_crop_products')) {
                    newState.district_crop_products = action.data.district_crop_products;
                }
            }
            return newState;
        },
    }
);
