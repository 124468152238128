export const PrimaryColor = "#75AA3B"; // GSULive Green
export const LightPrimaryColor = "#82b24e"; // Light GSULive Green
export const LighterPrimaryColor = "#90bb62";
export const LightestPrimaryColor = "#9ec375";
export const DarkPrimaryColor = "#699935";
export const DarkerPrimaryColor = "#5d882f";
export const DarkestPrimaryColor = "#517629";

export const TertiaryColor = "#40434E"; // Gray color
export const LightTertiaryColor = "#53555F";
export const LighterTertiaryColor = "#666871";
export const LightestTertiaryColor = "#797b83";
export const DarkTertiaryColor = "#393c46";
export const DarkerTertiaryColor = "#33353e";
export const DarkestTertiaryColor = "#2c2e36";

export const EditableFieldColor = "#FAFAFA";

export const LightText = "#FFFFFF"; // white
export const GrayText = "#555"; //gray
export const DarkText = "#070707"; //black

export const BlueGreenGraphBackgroundColor = "rgba(75,192,192,0.4)";
export const BlueGreenGraphBorderColor = "rgba(75,192,192,1)";
export const BlueGreenGraphPointBorderColor = "rgba(75,192,192,1)";
export const BlueGreenGraphPointHoverBackgroundColor = "rgba(75,192,192,1)";
export const BlueGreenGraphPointHoverBorderColor = "rgba(220,220,220,1)";

export const RedGraphBackgroundColor = "rgba(255,192,192,0.4)";
export const RedGraphBorderColor = "rgba(255,192,192,1)";
export const RedGraphPointBorderColor = "rgba(255,192,192,1)";
export const RedGraphPointHoverBackgroundColor = "rgba(255,192,192,1)";
export const RedGraphPointHoverBorderColor = "rgba(220,220,220,1)";

export const PurpleGraphBackgroundColor = "rgba(192,192,255,0.4)";
export const PurpleGraphBorderColor = "rgba(192,192,255,1)";
export const PurpleGraphPointBorderColor = "rgba(192,192,255,1)";
export const PurpleGraphPointHoverBackgroundColor = "rgba(192,192,255,1)";
export const PurpleGraphPointHoverBorderColor = "rgba(220,220,220,1)";

export const WindowMinWidthForSidebar = 800;
export const TOOLBAR_Z_INDEX = 5;

let dataURL = window.location.origin;

if (window.location.origin.includes(".linxsystems.com")) {
    dataURL = "https://pc-sam-backend-gsl.internal.linxsystems.com";
    // dataURL = "https://mbp-avash-backend-gsl.internal.linxsystems.com";
} else if (dataURL.includes("alpha.growsmartlive.com")) {
    dataURL = "https://alpha.internal.growsmartlive.com";
} else if (dataURL.includes("beta.growsmartlive.com")) {
    dataURL = "https://beta.internal.growsmartlive.com";
} else if (dataURL.includes("www.growsmartlive.com")) {
    dataURL = "https://www.internal.growsmartlive.com";
}

if (window.location.origin.includes("localhost") && !process.env.CI) {
    /*
     You can change values in this block for local testing
     */
    // dataURL = "https://alpha.growsmartlive.com";
    dataURL = "http://localhost:8001";
} else if (window.location.origin.includes("localhost") && process.env.CI) {
    /*
    DO NOT CHANGE VALUES IN THIS BLOCK.  THIS IS FOR CIRCLECI
     */

    // we are in CircleCI
    // look for the API_SERVER bash environment variable
    if (process.env.API_SERVER) {
        dataURL = process.env.API_SERVER;
    } else {
        dataURL = "https://alpha.growsmartlive.com";
    }
}

let getStreamChatKey;
if (dataURL.includes("alpha")) {
    getStreamChatKey = "ac2hgvsq9x4b";
} else if (dataURL.includes("beta")) {
    getStreamChatKey = "jatqexga67kr";
} else if (dataURL.includes("www.growsmartlive.com") || dataURL.includes("www.internal.growsmartlive.com")) {
    getStreamChatKey = "w3bw5pzkzw6g";
} else if (dataURL.includes(".linx")) {
    getStreamChatKey = "524jkpgcwzrq";
} else {
    getStreamChatKey = "524jkpgcwzrq";
}

let pusherKey;
if (dataURL.includes(".linx")) {
    pusherKey = "abd0858d9f057048386d"; // dev pusher key
} else if (dataURL.includes("alpha")) {
    pusherKey = "2b4a888c11848eab7347";
} else if (dataURL.includes("beta")) {
    pusherKey = "a8cda2234c85c6059a85";
} else if (dataURL.includes("www.growsmartlive.com") || dataURL.includes("www.internal.growsmartlive.com")) {
    pusherKey = "166f0a08d0704d7f3aba";
} else {
    pusherKey = "abd0858d9f057048386d"; // dev pusher key
}

let perfectTenseKey;
if (dataURL.includes(".linx")) {
    perfectTenseKey = "5f0f068d45bddc4bad88bd2e"; // dev pusher key
} else if (dataURL.includes("alpha")) {
    perfectTenseKey = "5f3e7bf26161917e5e6704bb";
} else if (dataURL.includes("beta")) {
    perfectTenseKey = "5f3e7c026161917e5e6704f1";
} else if (dataURL.includes("www.growsmartlive.com") || dataURL.includes("www.internal.growsmartlive.com")) {
    perfectTenseKey = "5f3e7c116161917e5e670524";
} else {
    perfectTenseKey = "5f0f068d45bddc4bad88bd2e"; // dev pusher key
}

let tealiumEnv = "dev";
if (dataURL.includes(".linx")) {
    tealiumEnv = "dev";
} else if (dataURL.includes("alpha")) {
    tealiumEnv = "qa";
} else if (dataURL.includes("beta")) {
    tealiumEnv = "qa";
} else if (dataURL.includes("growsmartlive.com") || dataURL.includes("www.internal.growsmartlive.com")) {
    tealiumEnv = "prod";
}

export const GOOGLE_SIGN_ON_CLIENTID = "363171911754-oqu59rlgl255pfpe8lvj51744c4s3d4j.apps.googleusercontent.com";
export const FACEBOOK_SIGN_IN_APPID = "4588269567880430";

export const HOST = dataURL;
export const GET_STREAM_CHAT_KEY = getStreamChatKey;
export const PUSHER_KEY = pusherKey;
export const PERFECT_TENSE_KEY = perfectTenseKey;
export const TEALIUM_ENV = tealiumEnv;
export const MEDIA_HOST = HOST + "/media";
export const API_VERSION = "v1";

export const RECAPTCHA_SITE_KEY = "6Ldv0c4ZAAAAAG8S8FSOFDS-lkQ5Jby3nXlHzfkJ";

export const ArticlesTabMyArticles = "ArticlesTab.MyArticles";
export const ArticlesTabStateStream = "ArticlesTab.StateStream";
export const ArticlesTabAll = "ArticlesTab.All";
export const ArticlesTabTheirArticles = "ArticlesTab.TheirArticles";
export const ArticlesTabTheirStream = "ArticlesTab.TheirStream";

export const ComposeTabDrafts = "ComposeTab.Drafts";
export const ComposeTabHistory = "ComposeTab.History";

export const ArticleTypeBurst = "ArticleType.Burst";
export const ArticleTypeVideo = "ArticleType.Video";

export const ICON_AND_TEXT = "ICON_AND_TEXT";
export const ICON_ONLY = "ICON_ONLY";

export const PHOTOS = 1; // 0001
export const VIDEOS = 1 << 1; // 0010

export const AuthGroup = {
    AccountManager: 1,
    ProjectManager: 2,
    Author: 3,
    Viewer: 4,
    PracticeAuthor: 5,
    Advocate: 6,
    InternalViewer: 7,
    Lite: 8,
    Developer: 9,
    Reviewer: 10,
};

export const StateOptions = [
    { key: "alabama", text: "Alabama", value: 1 },
    { key: "alaska", text: "Alaska", value: 2 },
    { key: "arizona", text: "Arizona", value: 3 },
    { key: "arkansas", text: "Arkansas", value: 4 },
    { key: "california", text: "California", value: 5 },
    { key: "colorado", text: "Colorado", value: 6 },
    { key: "connecticut", text: "Connecticut", value: 7 },
    { key: "delaware", text: "Delaware", value: 8 },
    { key: "florida", text: "Florida", value: 9 },
    { key: "georgia", text: "Georgia", value: 10 },
    { key: "hawaii", text: "Hawaii", value: 11 },
    { key: "idaho", text: "Idaho", value: 12 },
    { key: "illinois", text: "Illinois", value: 13 },
    { key: "indiana", text: "Indiana", value: 14 },
    { key: "iowa", text: "Iowa", value: 15 },
    { key: "kansas", text: "Kansas", value: 16 },
    { key: "kentucky", text: "Kentucky", value: 17 },
    { key: "louisiana", text: "Louisiana", value: 18 },
    { key: "maine", text: "Maine", value: 19 },
    { key: "maryland", text: "Maryland", value: 20 },
    { key: "massachusetts", text: "Massachusetts", value: 21 },
    { key: "michigan", text: "Michigan", value: 22 },
    { key: "minnesota", text: "Minnesota", value: 23 },
    { key: "mississippi", text: "Mississippi", value: 24 },
    { key: "missouri", text: "Missouri", value: 25 },
    { key: "montana", text: "Montana", value: 26 },
    { key: "nebraska", text: "Nebraska", value: 27 },
    { key: "nevada", text: "Nevada", value: 28 },
    { key: "new_hampshire", text: "New Hampshire", value: 29 },
    { key: "new_jersey", text: "New Jersey", value: 30 },
    { key: "new_mexico", text: "New Mexico", value: 31 },
    { key: "new_york", text: "New York", value: 32 },
    { key: "north_carolina", text: "North Carolina", value: 33 },
    { key: "north_dakota", text: "North Dakota", value: 34 },
    { key: "ohio", text: "Ohio", value: 35 },
    { key: "oklahoma", text: "Oklahoma", value: 36 },
    { key: "oregon", text: "Oregon", value: 37 },
    { key: "pennsylvania", text: "Pennsylvania", value: 38 },
    { key: "rhode_island", text: "Rhode Island", value: 39 },
    { key: "south_carolina", text: "South Carolina", value: 40 },
    { key: "south_dakota", text: "South Dakota", value: 41 },
    { key: "tennessee", text: "Tennessee", value: 42 },
    { key: "texas", text: "Texas", value: 43 },
    { key: "utah", text: "Utah", value: 44 },
    { key: "vermont", text: "Vermont", value: 45 },
    { key: "virginia", text: "Virginia", value: 46 },
    { key: "washington", text: "Washington", value: 47 },
    { key: "west_virginia", text: "West Virginia", value: 48 },
    { key: "wisconsin", text: "Wisconsin", value: 49 },
    { key: "wyoming", text: "Wyoming", value: 50 },
];

export const ComposeCharCountsTitle = {
    SuggestedAbsoluteMinimum: 5,
    SuggestedMinimum: 10,
    SuggestedMaximum: 86,
    SuggestedAbsoluteMaximum: 100,
};
export const ComposeCharCountsSummary = {
    SuggestedAbsoluteMinimum: 25,
    SuggestedMinimum: 100,
    SuggestedMaximum: 250,
    SuggestedAbsoluteMaximum: 300,
};
export const ComposeCharCountsTextBlock = {
    SuggestedAbsoluteMinimum: 10,
    SuggestedMinimum: 50,
    SuggestedMaximum: 400,
    SuggestedAbsoluteMaximum: 750,
};

export const TitleFontSize = {
    LargeFont: "40px",
    SmallFont: "25px",
};

export const BurstTextFontSize = {
    LargeFont: "30px",
    SmallFont: "20px",
};

//store links
export const iOSAppStoreLink = "https://apps.apple.com/us/app/grow-smart-live/id1194122465";
export const androidStoreLink = "https://play.google.com/store/apps/details?id=com.linxsystems.gsuliveviewer.android";
export const APP_STORE_SMART_LINK = "http://growsmartlive.app.link/MGieRqDCwab";

export const NO_REPLY_EMAIL_ADDRESS = "noreply@growsmartlive.com";

export const PRIVACY_POLICY_LINK = "https://www.basf.com/us/en/legal/data-protection.html";

// queue job types
export const JOB__PUBLISH = "JOB__PUBLISH";
export const JOB__PUBLISH_VIDEO = "JOB__PUBLISH_VIDEO";
export const JOB__IMAGE_UPLOAD = "JOB__IMAGE_UPLOAD";
export const JOB__VIDEO_UPLOAD = "JOB__VIDEO_UPLOAD";

// queue job states
export const JOB_STATE_PENDING = "JOB_STATE_PENDING";
export const JOB_STATE_STOPPED = "JOB_STATE_STOPPED";
export const JOB_STATE_ACTIVE = "JOB_STATE_ACTIVE";
export const JOB_STATE_COMPLETE = "JOB_STATE_COMPLETE";

export const POLL_MAX_NUM_OPTIONS = 10;

export const PollInspectorTab = {
    Poll: 1,
    Options: 2,
};

export const PollType = {
    MultipleChoice: 1,
    Percentage: 2,
    ShortAnswer: 3,
};

export const VideoStatus = Object.freeze({
    Draft: 0,
    AwaitingReview: 1,
    Scheduled: 2,
    Published: 3,
    Archived: 4,
    Template: 5,
});

export const CONTENT_TYPES = {
    NEWS: "article",
    OBSERVATION: "observation",
    VIDEO: "video",
};

export const PROJECT_STATUS = {
    CM_DRAFT: "Content Manager Draft",
    AUTHOR_WIP: "Author WIP",
    UNDER_REVIEW: "Under Review",
    AWAIT_CM_APPROVAL: "Awaiting Content Manager Approval",
    APPROVED: "Approved for Publish",
    PUBLISHED: "Published",
    CM_EVALUATING_AUTHOR_DRAFT: "Content Manager Evaluating Author Draft",
};

export const PROJECT_FIELDS = {
    PROJECT: "Project",
    DRAFT: "Draft",
    TYPE: "Type",
    STATUS: "Status",
    DUE_DATE: "Due Date",
    LAST_EDITED: "Last Edited",
    EXPECTED_PUBLISH: "Expected Publish",
    PUBLISH: "Publish Date",
    CONTENT_MANAGER: "Content Manager",
    ACTION_COLUMN: "",
};

export const OBSERVATION_CHARACTER_LIMIT = 1000;
export const OBSERVATION_TITLE_LIMIT = 60;
