import { executeBasicNetworkCall } from "lib/Utils";
import * as types from 'ContentManager/actions/types.js';
import * as MessageActions from "actions/messages";

export function createArticle(auth_token, callback = () => { }) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "create_article",
            JSON.stringify({ auth_token: auth_token }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                        dispatch(MessageActions.setError(x.message));
                    } else {
                        callback(true, x.data);
                    }
                }
            },
        );
    };
}

export function deleteArticle(
    auth_token,
    article_id,
    callback = () => { },
) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "delete_article",
            JSON.stringify({ auth_token: auth_token, article_id: article_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                        dispatch(MessageActions.setError(x.message));
                    } else {
                        callback(true, x.data);
                    }
                }
            },
        );
    };
}

export function createObservation(auth_token, callback = () => { }) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "create_empty_observation",
            JSON.stringify({ auth_token: auth_token }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                        dispatch(MessageActions.setError(x.message));
                    } else {
                        callback(true, x.data);
                    }
                }
            },
        );
    };
}

export function deleteProject(
    project_id,
    callback = () => { },
) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "delete_project",
            JSON.stringify({ project_id: project_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                        dispatch(MessageActions.setError(x.message));
                    } else {
                        callback(true, x.data);
                    }
                }
            },
        );
    };
}

export function getMyAuthorContentLists(callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_my_author_content_lists",
            JSON.stringify({}),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                    } else {
                        dispatch(setMyAuthorContentLists(x.data));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

function setMyAuthorContentLists(data = null) {
    return {
        type: types.SET_MY_AUTHOR_CONTENT_LISTS,
        data: data,
    };
}

export function submitProjectForReview(
    article_id,
    observation_id,
    state_ids,
    br_district_ids,
    br_region_ids,
    content_category_ids,
    crop_ids,
    product_ids,
    is_customer_exclusive,
    is_featured,
    callback = () => { },
) {
    let params = {
        state_ids,
        br_district_ids,
        br_region_ids,
        content_category_ids,
        crop_ids,
        product_ids,
        is_customer_exclusive,
        is_featured,
    }
    
    if (article_id) {
        params.article_id = article_id;
    }
    if (observation_id) {
        params.observation_id = observation_id;
    }

    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "submit_project_for_review",
            JSON.stringify(params),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                        dispatch(MessageActions.setError(x.message));
                    } else {
                        callback(true);
                        dispatch(MessageActions.setSuccess(x.message));
                    }
                }
            },
        );
    };
}