import React, {Component} from 'react';

import {SemanticToastContainer, toast} from 'react-semantic-toasts'
import 'react-semantic-toasts/styles/react-semantic-alert.css';


class ErrorToast extends Component {
    componentDidUpdate() {
        if (this.props.message !== null && this.props.message !== '') {
            toast(
                {
                    title: 'Error',
                    description: this.props.message,
                    type: 'warning',
                    time: 5000,
                }
            );
        }
    }


    render() {
        return (<SemanticToastContainer position="bottom-center"/>)
    }
}


export default ErrorToast
