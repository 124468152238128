import * as types from "./types";
import { executeBasicNetworkCall } from "lib/Utils";
import * as MessageActions from "./messages";

export function fetchDrafts(didFinish = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "fetch_drafts",
            undefined,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    var x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        didFinish();
                    } else {
                        dispatch(setDrafts(x.data));
                        didFinish();
                    }
                }
            },
            () => {
                didFinish();
            },
        );
    };
}

export function fetchHistory(didFinish = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "fetch_history",
            undefined,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        didFinish();
                    } else {
                        //
                        dispatch(setHistory(x.data, false));
                        didFinish();
                    }
                }
            },
            () => {
                didFinish();
            },
        );
    };
}

function setDrafts(drafts, isRefresh) {
    return {
        type: types.SET_DRAFTS,
        drafts,
    };
}

function setHistory(history, isRefresh) {
    return {
        type: types.SET_HISTORY,
        history,
        isRefresh,
    };
}

//-------------------------------------------------------------------------------------

export function createNewVideoArticle(data, callback) {
    return (dispatch, getState) => {
        let params = {
            title: data.title,
            release_date: data.release_date,
            abstract: data.abstract,
            is_draft: data.is_draft,
            media: data.media,
            original_id: data.original_id,
        };

        params = JSON.stringify(params);

        executeBasicNetworkCall(
            "create_burst",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    var x = JSON.parse(reqq.responseText);

                    if (x.error) {
                        callback(false, x.message);
                        dispatch(MessageActions.setError("There was an error publishing the article."));
                    } else {
                        callback(true, x.message);
                        dispatch(MessageActions.setSuccess("The article has been published successfully"));
                        dispatch(fetchHistory());
                        dispatch(fetchDrafts());
                    }
                }
            },
            (error) => {
                callback(false, error);
            },
            false,
            (status) => {
                callback(false, `Server ${status} Error`);
            },
        );
    };
}

export function updateArticle(data, callback) {
    return (dispatch, getState) => {
        let params = {
            title: data.title,
            abstract: data.abstract,
            burst_id: data.burst_id,
            media: data.media,
        };

        params = JSON.stringify(params);

        executeBasicNetworkCall(
            "update_published_burst",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    var x = JSON.parse(reqq.responseText);

                    if (x.error) {
                        callback(false, x.message);
                        dispatch(MessageActions.setError("There was an error updating the article."));
                    } else {
                        callback(true, x.message);
                        dispatch(MessageActions.setSuccess("You have updated the article successfully."));
                        dispatch(fetchHistory());
                    }
                }
            },
            (error) => {
                callback(false, error);
            },
            false,
            (status) => {
                callback(false, `Server ${status} Error`);
            },
        );
    };
}

export function saveDraft(data, callback) {
    return (dispatch, getState) => {
        let params = {
            title: data.title,
            release_date: data.release_date,
            abstract: data.abstract,
            media: data.media,
            original_id: data.original_id,
        };

        params = JSON.stringify(params);

        executeBasicNetworkCall(
            "save_draft",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    var x = JSON.parse(reqq.responseText);

                    if (x.error) {
                        callback(false, x.message);
                        dispatch(MessageActions.setError("There was an error saving your draft."));
                    } else {
                        dispatch(fetchDrafts());

                        if (data.freshly_minted) {
                            // we just made this draft going into Basic Editor View
                            // we want to get the burst id back from the server,

                            callback(true, x.burst_id);
                        } else {
                            callback(true, x.message);

                            if (data.is_draft && !data.isDuplicate) {
                                dispatch(MessageActions.setSuccess("Your draft has been saved successfully"));
                            } else if (data.is_draft && data.isDuplicate) {
                                dispatch(MessageActions.setSuccess("Your duplicate has been saved to your Drafts folder"));
                            }
                        }
                    }
                }
            },
            (error) => {
                callback(false, error);
            },
            false,
            (status) => {
                callback(false, `Server ${status} Error`);
            },
        );
    };
}

export function archiveBurst(burst_id, callback) {
    return (dispatch, getState) => {
        let params = {
            original_id: burst_id,
        };

        params = JSON.stringify(params);

        executeBasicNetworkCall(
            "archive_burst",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    var x = JSON.parse(reqq.responseText);

                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.message);
                    }
                }
            },
            (error) => {
                callback(false, error);
            },
            false,
            (status) => {
                callback(false, `Server ${status} Error`);
            },
        );
    };
}

//-------------------------------------------------------------------------------------

export function getArticleComposeDetails(bbid, callback) {
    return (dispatch, getState) => {
        let params = {
            bbid: bbid,
        };

        params = JSON.stringify(params);

        executeBasicNetworkCall(
            "get_article_compose_details",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    var x = JSON.parse(reqq.responseText);

                    if (x.error) {
                        dispatch(MessageActions.setError(x.message));
                    } else {
                        callback(x.data);
                    }
                }
            },
            (error) => {
                callback(false, error);
            },
            false,
            (status) => {
                callback(false, `Server ${status} Error`);
            },
        );
    };
}
