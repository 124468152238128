import React, { memo } from "react";
import Flexbox from "flexbox-react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { ButtonOpacity } from "innercast-core-js/dist/module";
import TopMenu from "components/TopMenu";
import logo from "assets/basf.png";
import "styles/Components.css";

const TopBar = (props) => {
    const IS_AUTHENTICATED = props.user ? !!(props.user.uid && props.user.isLoggedIn) : false;

    const topLeft =
        IS_AUTHENTICATED && !props.menuIsVisible ? (
            <ButtonOpacity
                src={require("../assets/menu.svg").default}
                style={{ width: 30, height: 30 }}
                onClick={() => {
                    if (props.sidebarOpen) {
                        //
                    } else {
                        props.didAskForMenu(!props.sidebarOpen);
                    }
                }}
                alt="Show Menu"
                title="Show Menu"
            />
        ) : null;

    const offsetCenterItem = props.menuIsVisible ? 320 : 0;

    const topRight = props.showAccountMenu ? <TopMenu {...props} /> : <div />;

    return (
        <Flexbox flexDirection="row" justifyContent="space-between" alignItems="center" className="topbar">
            <div>{topLeft}</div>

            <Link to="/" style={{ marginLeft: offsetCenterItem }}>
                <img data-tut="reactour__topbar" src={logo} alt="BASF" height={40} />
            </Link>

            {topRight}
        </Flexbox>
    );
};

export default withRouter(memo(TopBar));
