import * as projectListReducers from './projectList';
import * as projectDetailsReducers from './projectDetails';
import * as myAuthorContentReducers from './myAuthorContent';
import * as myReviewContentReducers from './myReviewContent';
import * as contentBadgesReducers from './contentBadges';

export default Object.assign({},
    projectListReducers,
    projectDetailsReducers,
    myAuthorContentReducers,
    myReviewContentReducers,
    contentBadgesReducers,
);
