import * as types from "./types";
import { HOST } from "Constants.js";
import { executeBasicNetworkCall } from "lib/Utils";
import { recordAnalytics } from "lib/analytics";
import * as AuthActions from "../Authentication/actions/auth";
import bugsnagClient from "lib/bugsnag";

// Get all API URL endpoints
export function validate() {
    return (dispatch, getState) => {
        const url = HOST + "/API/v1/validate";

        const reqq = new XMLHttpRequest();
        reqq.onreadystatechange = (e) => {
            try {
                if (reqq.readyState !== 4) {
                    return;
                }
                //
                //
                if (reqq.status === 200) {
                    if (reqq.responseText != null) {
                        const x = JSON.parse(reqq.responseText);
                        if (x.error) {
                            // dispatch(setEndpointURLS(null));
                        } else {
                            if (x.data) {
                                const info = x.data;
                                dispatch(setEndpointURLS(info));

                                if (!process.env.CI && !window.Cypress) {
                                    // don't record traffic from CircleCI!

                                    if (document.referrer.indexOf("localhost") === -1) {
                                        // don't record local traffic

                                        let pathname = window.location.pathname;

                                        // don't record security tokens (huge security problem!!!)
                                        pathname = pathname.replace(
                                            /[0-9a-f]{8}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{12}/g,
                                            ":token",
                                        );

                                        if (document.referrer && document.referrer.length > 0) {
                                            recordAnalytics(false, "Traffic", "Referral", document.referrer, pathname);
                                        } else {
                                            recordAnalytics(false, "Traffic", "Direct", pathname);
                                        }
                                    }
                                }
                            }
                        }
                    }
                } else {
                    // dispatch(setEndpointURLS(null));
                }
            } catch (error) {
                //
                //  MyBugSnag.getInstance().client.notify(error);
                // dispatch(setEndpointURLS(null));
            }
        };
        reqq.open("POST", url);
        reqq.send();
    };
}

function setEndpointURLS(info) {
    return {
        type: types.SET_ENDPOINT_URLS,
        info,
    };
}

export function queryLogInStatus() {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "user_logged_in",
            undefined,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    var x = JSON.parse(reqq.responseText);

                    if (x.error) {
                        dispatch(setLoginStatus(false));
                        dispatch(logout());
                    } else {
                        dispatch(setLoginStatus(x.data));
                    }
                }
            },
            (error) => {
                dispatch(logout());
                dispatch(setLoginStatus(false));
            },
        );
    };
}

export function verifyUserInfo(callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "verify_user_info",
            null,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(setLoginStatus(false));
                        if (x.code === "logout") {
                            dispatch(executeLogout());
                        } else if (x.code === "error") {
                            // open data input modal
                        }
                        callback(false);
                    } else {
                        dispatch(setLoginStatus(true));
                        dispatch(setLogin(x.data));
                        callback(true);
                    }
                }
            },
            (error) => {
                console.log(error);
            },
            false,
            (status) => {
                const error = {
                    endpoint: "v3/VerifyUserInfo",
                    type: "SERVER",
                    message: "server responded with something other than 200",
                    status: status,
                };
                console.log(error);
            },
            `${HOST}/API/v3/VerifyUserInfo`,
        );
    };
}

function setLoginStatus(isLoggedIn) {
    return {
        type: types.SET_IS_LOGGED_IN_STATUS,
        isLoggedIn,
    };
}

/*
  Control login
*/
export function login(username, password, fingerprint, register, failure) {
    return (dispatch, getState) => {
        const encodedPassword = encodeURIComponent(password);
        const encodedUsername = encodeURIComponent(username);
        const encodedFingerprint = encodeURIComponent(fingerprint);

        const params = `username=${encodedUsername}&password=${encodedPassword}&fingerprint=${encodedFingerprint}`;

        executeBasicNetworkCall(
            "login",
            params,
            "application/x-www-form-urlencoded",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    var x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        if (x.code === 106) {
                            //
                            register(x.email, x.phone_number, username, password);
                        } else if (x.code === 103) {
                            failure(x.message);
                            //
                        } else {
                            failure(x.message);
                            //
                        }
                        //dispatch(setArticles(null));
                    } else {
                        dispatch(setLogin(x));

                        bugsnagClient.user = {
                            id: x.uid,
                            name: x.name,
                            email: username,
                        };
                    }
                }
            },
            (error) => {},
        );
    };
}

export function setLogin(info) {
    return {
        type: types.SET_LOGIN,
        info,
    };
}

/*
  Control logout
*/
export function logout() {
    return (dispatch, getState) => {
        bugsnagClient.user = {};

        const params = "is_web=True";

        executeBasicNetworkCall(
            "logout",
            params,
            "application/x-www-form-urlencoded",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);

                    if (x.error) {
                        console.log(x.message);
                    } else {
                        // console.log(x.message);
                    }
                }
            },
            (error) => {
                console.log(error);
            },
        );

        //TODO clear all redux states here!!!
        dispatch(executeLogout());
        dispatch(clearChatUserToken(null));
    };
}

function executeLogout() {
    return {
        type: types.LOGOUT,
    };
}

function clearChatUserToken(token) {
    return {
        type: types.SET_CHAT_USER_TOKEN,
        token,
    };
}

//-------------------------------------------------------------------------------------

export function request2FATokenForThisDevice(
    username,
    password,
    udid,
    requestType = "email",
    success = () => {},
    failure = () => {},
    email = null,
) {
    return (dispatch, getState) => {
        const json = {
            fingerprint: udid,
            type: requestType,
        };

        if (email !== null) {
            json.email = email;
        } else {
            json.username = username;
            json.password = password;
        }

        executeBasicNetworkCall(
            "create_two_factor_authenticate_and_send",
            JSON.stringify(json),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        failure(x.message, requestType);
                    } else {
                        success(x.message, requestType);
                    }
                }
            },
            (error) => {},
        );
    };
}

export function validate2FAToken(udid, token, username, password, success = () => {}, failure = () => {}, loginParams = null) {
    return (dispatch, getState) => {
        const json = {
            fingerprint: udid,
            token,
        };

        executeBasicNetworkCall(
            "create_trusted_device",
            JSON.stringify(json),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        failure(x.message);
                    } else {
                        if (loginParams === null) {
                            this.login(username, password, udid);
                            success();
                        } else {
                            dispatch(AuthActions.signIn(loginParams, success));
                        }
                    }
                }
            },
            (error) => {},
        );
    };
}

export function requestResetPassword(email, success, failure) {
    return (dispatch, getState) => {
        let json = { email: email };
        json = JSON.stringify(json);

        executeBasicNetworkCall("reset_password", json, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                var x = JSON.parse(reqq.responseText);
                if (x.error) {
                    failure(x.message);
                } else {
                    //take user to their home screen
                    success(x.message);
                }
            }
        });
    };
}
