import createReducer from "lib/createReducer";
import * as types from "CropProductInfo/actions/types";

const DEFAULT_STATE = {
    id: null,
    name: '',
    link: '',
    description: '',
    bubble_image_id: null,
    bubble_image_url: null,
    feature_image_id: null,
    feature_image_url: null,
    cta_label: '',
    cta_link: '',
    feature_video_id: null,
    feature_video_url: null,
    feature_video_vimeo_id: null,
    tools: [],
    tool_options: [],
    overwritten_cta_labels: [],
    overwritten_cta_links: [],
    overwritten_descriptions: [],
    overwritten_feature_videos: [],
};

export const cropDetails = createReducer(
    DEFAULT_STATE,
    {
        [types.SET_CROP_DETAILS](state, action) {
            let newState = Object.assign({}, state);
            if (action.data === null || action.data === undefined) {
                newState = DEFAULT_STATE;
            } else {
                if (action.data.hasOwnProperty('id')) {
                    newState.id = action.data.id;
                }
                if (action.data.hasOwnProperty('name')) {
                    newState.name = action.data.name;
                }
                if (action.data.hasOwnProperty('link')) {
                    newState.link = action.data.link;
                }
                if (action.data.hasOwnProperty('description')) {
                    newState.description = action.data.description;
                }
                if (action.data.hasOwnProperty('bubble_image_id')) {
                    newState.bubble_image_id = action.data.bubble_image_id;
                }
                if (action.data.hasOwnProperty('bubble_image_url')) {
                    newState.bubble_image_url = action.data.bubble_image_url;
                }
                if (action.data.hasOwnProperty('feature_image_id')) {
                    newState.feature_image_id = action.data.feature_image_id;
                }
                if (action.data.hasOwnProperty('feature_image_url')) {
                    newState.feature_image_url = action.data.feature_image_url;
                }
                if (action.data.hasOwnProperty('cta_label')) {
                    newState.cta_label = action.data.cta_label;
                }
                if (action.data.hasOwnProperty('cta_link')) {
                    newState.cta_link = action.data.cta_link;
                }
                if (action.data.hasOwnProperty('feature_video_id')) {
                    newState.feature_video_id = action.data.feature_video_id;
                }
                if (action.data.hasOwnProperty('feature_video_url')) {
                    newState.feature_video_url = action.data.feature_video_url;
                }
                if (action.data.hasOwnProperty('feature_video_vimeo_id')) {
                    newState.feature_video_vimeo_id = action.data.feature_video_vimeo_id;
                }
                if (action.data.hasOwnProperty('tools')) {
                    newState.tools = action.data.tools;
                }
                if (action.data.hasOwnProperty('tool_options')) {
                    newState.tool_options = action.data.tool_options;
                }
                if (action.data.hasOwnProperty('overwritten_cta_labels')) {
                    newState.overwritten_cta_labels = action.data.overwritten_cta_labels;
                }
                if (action.data.hasOwnProperty('overwritten_cta_links')) {
                    newState.overwritten_cta_links = action.data.overwritten_cta_links;
                }
                if (action.data.hasOwnProperty('overwritten_descriptions')) {
                    newState.overwritten_descriptions = action.data.overwritten_descriptions;
                }
                if (action.data.hasOwnProperty('overwritten_feature_videos')) {
                    newState.overwritten_feature_videos = action.data.overwritten_feature_videos;
                }
            }
            
            return newState;
        },

    }
);
