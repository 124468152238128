import * as CropProductInfoActions from "./cropProductInfo";
import * as CropActions from "./crop";
import * as ProductActions from "./product";
import * as DistrictActions from "./district";

export const CropProductInfoActionCreators = Object.assign(
    {},
    CropProductInfoActions,
    CropActions,
    ProductActions,
    DistrictActions
);
