import createReducer from "lib/createReducer";
import * as types from "ContentManager/actions/types";

const DEFAULT_STATE = {
    id: null,
    name: '',
    expected_publish_date: null,
    content_type: '',
    status: '',
    tags: {
        customer_exclusive: null,
        states: [],
        br_districts: [],
        br_regions: [],
        content_categories: null,
        crops: null,
        products: null,
        is_featured: false,
    },
    directions: '',


    content_manager_id: '',
    content_manager_name: '',
    author_id: '',
    author_name: '',
    author_due_date: '',
    author_note: '',
    reviewers: [],
    /* Array of Reviewer dictionaries:
        due_date
        has_approved
        note
        order
        project_reviewer_id
        reviewer_id
        reviewer_name
        role_id
        role_name
    */

    article_id: null,
    video_id: null,
    observation_id: null,
    post_id: null,

    i_am_current_contributor: false,
};

export const projectDetails = createReducer(
    DEFAULT_STATE,
    {
        [types.SET_PROJECT_DETAILS](state, action) {
            let newState = Object.assign({}, state);
            if (action.data === null || action.data === undefined) {
                newState = DEFAULT_STATE;
            } else {
                if (action.data.hasOwnProperty('id')) {
                    newState.id = action.data.id;
                }
                if (action.data.hasOwnProperty('name')) {
                    newState.name = action.data.name;
                }
                if (action.data.hasOwnProperty('expected_publish_date')) {
                    newState.expected_publish_date = action.data.expected_publish_date;
                }
                if (action.data.hasOwnProperty('content_manager_id')) {
                    newState.content_manager_id = action.data.content_manager_id;
                }
                if (action.data.hasOwnProperty('content_manager_name')) {
                    newState.content_manager_name = action.data.content_manager_name;
                }
                if (action.data.hasOwnProperty('author_id')) {
                    newState.author_id = action.data.author_id;
                }
                if (action.data.hasOwnProperty('author_name')) {
                    newState.author_name = action.data.author_name;
                }
                if (action.data.hasOwnProperty('author_due_date')) {
                    newState.author_due_date = action.data.author_due_date;
                }
                if (action.data.hasOwnProperty('author_note')) {
                    newState.author_note = action.data.author_note;
                }
                if (action.data.hasOwnProperty('content_type')) {
                    newState.content_type = action.data.content_type;
                }
                if (action.data.hasOwnProperty('status')) {
                    newState.status = action.data.status;
                }
                if (action.data.hasOwnProperty('tags')) {
                    const tags = action.data.tags;
                    if (tags.hasOwnProperty('customer_exclusive')) {
                        newState.tags.customer_exclusive = tags.customer_exclusive;
                    }
                    if (tags.hasOwnProperty('states')) {
                        newState.tags.states = tags.states;
                    }
                    if (tags.hasOwnProperty('br_districts')) {
                        newState.tags.br_districts = tags.br_districts;
                    }
                    if (tags.hasOwnProperty('br_regions')) {
                        newState.tags.br_regions = tags.br_regions;
                    }
                    if (tags.hasOwnProperty('content_categories')) {
                        newState.tags.content_categories = tags.content_categories;
                    }
                    if (tags.hasOwnProperty('crops')) {
                        newState.tags.crops = tags.crops;
                    }
                    if (tags.hasOwnProperty('products')) {
                        newState.tags.products = tags.products;
                    }
                    if (tags.hasOwnProperty('is_featured')) {
                        newState.tags.is_featured = tags.is_featured;
                    }
                }
                if (action.data.hasOwnProperty('reviewers')) {
                    newState.reviewers = action.data.reviewers;
                }
                if (action.data.hasOwnProperty('directions')) {
                    newState.directions = action.data.directions;
                }
                if (action.data.hasOwnProperty('article_id')) {
                    newState.article_id = action.data.article_id;
                }
                if (action.data.hasOwnProperty('video_id')) {
                    newState.video_id = action.data.video_id;
                }
                if (action.data.hasOwnProperty('observation_id')) {
                    newState.observation_id = action.data.observation_id;
                }
                if (action.data.hasOwnProperty('post_id')) {
                    newState.post_id = action.data.post_id;
                }
                if (action.data.hasOwnProperty('i_am_current_contributor')) {
                    newState.i_am_current_contributor = action.data.i_am_current_contributor;
                }
            }

            return newState;
        },

    }
);
