import { executeBasicNetworkCall } from "../../lib/Utils";
import * as types from "../../actions/types";

export function signIn(
    params,
    callback = () => {
        throw new Error("must supply callback");
    },
    register = () => {},
) {
    return (dispatch, getState) => {

        executeBasicNetworkCall(
            "auth_login",
            JSON.stringify({ ...params }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        if (x.code === 106) {
                            register(x.email, x.phone_number, params);
                        } else {
                            callback(false, x.data, x.message);
                        }
                    } else {
                        if (params.email && params.password) {
                        } else if (params.google_token) {
                            // if (window.utag) {
                            //     setTimeout(() => {
                            //         let user = getState().user;

                            //         let tealium_dict = {
                            //             page_name: "Grow Smart Live",
                            //             customer_email: user.email,
                            //             customer_status: "logged in",
                            //             customer_state: user.state,
                            //             customer_id: user.uid,
                            //             customer_type: user.groupName,
                            //             view_name: "login",
                            //             page_category: "Login",
                            //             event_type: "click",
                            //             event_name: `login_with_google`,
                            //             tealium_event: `login_with_google`,
                            //         };
                            //         window.utag.link(tealium_dict);
                            //     }, 1000);
                            // }
                        } else if (params.facebook_data) {
                            // setTimeout(() => {
                            //     setTimeout(() => {
                            //         let user = getState().user;

                            //         let tealium_dict = {
                            //             page_name: "Grow Smart Live",
                            //             customer_email: user.email,
                            //             customer_status: "logged in",
                            //             customer_state: user.state,
                            //             customer_id: user.uid,
                            //             customer_type: user.groupName,
                            //             view_name: "login",
                            //             page_category: "Login",
                            //             event_type: "click",
                            //             event_name: `login_with_facebook`,
                            //             tealium_event: `login_with_facebook`,
                            //         };
                            //         window.utag.link(tealium_dict);
                            //     }, 1000);
                            // }, 100);
                        } else {
                        }

                        callback(true, x.data);
                        dispatch(setLogin(x.data));
                    }
                }
            }
        );
    };
}

export function setLogin(info) {
    return {
        type: types.SET_LOGIN,
        info,
    };
}

export function requestResetPassword(email, success, failure) {
    return (dispatch, getState) => {
        const json = JSON.stringify({ email: email });

        executeBasicNetworkCall("reset_password", json, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    failure(x.message);
                } else {
                    //take user to their home screen
                    success(x.message);
                }
            }
        });
    };
}

export function createLiteAccount(first, last, state, email, password, recaptchaToken, callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "create_lite_account_v2",
            JSON.stringify({
                first_name: first,
                last_name: last,
                state: state,
                email: email,
                password: password,
                recaptchaToken: recaptchaToken,
            }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        // const utag = window.utag;

                        // if (utag) {
                        //     let tealium_dict = {
                        //         page_name: "Grow Smart Live",
                        //         page_category: "Create Account",
                        //         customer_email: email,
                        //         customer_status: "logged in",
                        //         customer_state: "",
                        //         customer_id: x.uid,
                        //         customer_type: "Lite",
                        //         event_type: "click",
                        //         event_name: "create_lite_account",
                        //         tealium_event: "create_lite_account",
                        //     };
                        //     utag.link(tealium_dict);
                        // }

                        dispatch(
                            signIn({ email: email, password: password }, (successful) => {
                                callback(successful);
                            }),
                        );
                    }
                }
            },
        );
    };
}

export function createDevAccount(first, last, state, email, password, recaptchaToken, devInviteCode, callback = () => {}) {
    return (dispatch, getState) => {
        const params = {
            first_name: first,
            last_name: last,
            state: state,
            email: email,
            password: password,
            recaptchaToken: recaptchaToken,
            dev_invite_code: devInviteCode,
        };

        const json = JSON.stringify(params);

        console.log("params", params);

        executeBasicNetworkCall("create_dev_account", json, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    callback(false, x.message);
                } else {
                    // callback(true, x.data);
                    dispatch(
                        signIn({ email: email, password: password }, (successful) => {
                            callback(successful);
                        }),
                    );
                }
            }
        });
    };
}

export function validateDeveloperInviteLink(
    inviteCode,
    callback = () => {
        throw new Error("must supply callback");
    },
) {
    return (dispatch, getState) => {
        console.log("invite_link", inviteCode);

        executeBasicNetworkCall(
            "validate_developer_invite_link",
            JSON.stringify({ invite_link: inviteCode }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    console.log(x);
                    if (x.error) {
                        callback(false);
                    } else {
                        callback(true);
                    }
                }
            },
        );
    };
}
