import React, { Component } from "react";

import { SemanticToastContainer, toast } from "react-semantic-toasts";
import "react-semantic-toasts/styles/react-semantic-alert.css";

class SuccessToast extends Component {
    componentDidUpdate() {
        if (this.props.message !== null && this.props.message !== "") {
            toast({
                title: "Success",
                description: this.props.message,
                type: "success",
                time: 5000
            });
            this.props.setSuccess(null);
        }
    }

    render() {
        return <SemanticToastContainer position="bottom-center" />;
    }
}

export default SuccessToast;
