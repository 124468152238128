import * as MessageActions from "../../actions/messages";
import {HOST} from "Constants";
import bugsnagClient from 'lib/bugsnag';

export function uploadRosterList(file, callback = () => {}) {
    return (dispatch, getState) => {
        // Do some basic validation
        if (!file || file === null) {
            return;
        }

        // BUILD THE API URL
        const ENDPOINT = "/API/v1/SalesX/UploadRosterList";
        const URL = `${HOST}${ENDPOINT}`;

        const data = new FormData();

        data.append('myfile', file);

        const form_data = new FormData();
        const xhr = new XMLHttpRequest();

        form_data.append('file', file);

        xhr.onreadystatechange = e => {
            try {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    const res = JSON.parse(xhr.responseText)
                    if(res.error) {
                        dispatch(MessageActions.setError(res.message))
                        callback(false, res.message)
                    } else {
                        dispatch(MessageActions.setSuccess(res.message))
                        callback(true)
                    }
                }
            } catch (error) {
                bugsnagClient.notify(error);
            }
        };
        xhr.open('POST', URL, true);
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        xhr.withCredentials = true;
        xhr.send(form_data);
    };
}
