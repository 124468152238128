import createReducer from "../lib/createReducer";
import * as types from "../actions/types";

const defaultData = {
    summary: {
        num_articles: 0,
        num_shares: 0,
        num_likes: 0,
        engagement: {
            graph_date_labels: [],
            customer: {
                reads_data: [],
                re_reads_data: [],
                articles_data: [],
                total_reads: 0,
                total_re_reads: 0,
            },
            internal: {
                reads_data: [],
                re_reads_data: [],
                articles_data: [],
                total_reads: 0,
                total_re_reads: 0,
            },
            all: {
                reads_data: [],
                re_reads_data: [],
                articles_data: [],
                total_reads: 0,
                total_re_reads: 0,
            },
        },
        total_customers: {
            graph_circle_labels: [],
            customer_circle_data: [],
            total_customers: 0,
        },
        lite_users: {
            num_lite_users: 0,
            num_fb_lite_users: 0,
            num_google_lite_users: 0,
            num_apple_lite_users: 0,
            num_manual_lite_users: 0,
            num_multiple_social_login_lite_users: 0,
        },
        num_authors: 0,
        num_advocates: 0,
    },
    leaderboards: {
        top_articles: [],
        top_authors: [],
    },
    chirper: {
        num_chirps: 0,
        num_reads: 0,
        num_likes: 0,
        num_image_clicks: 0,
        num_link_clicks: 0,
        impressions: {
            graph_date_labels: [],
            customer: {
                impressions_data: [],
                re_impressions_data: [],
                chirps_data: [],
                total_impressions: 0,
                total_re_impressions: 0,
            },
            internal: {
                impressions_data: [],
                re_impressions_data: [],
                chirps_data: [],
                total_impressions: 0,
                total_re_impressions: 0,
            },
            all: {
                impressions_data: [],
                re_impressions_data: [],
                chirps_data: [],
                total_impressions: 0,
                total_re_impressions: 0,
            },
        },
    },
};

const defaultSummaryData = {
    num_articles: 0,
    num_shares: 0,
    num_likes: 0,
    engagement: {
        graph_date_labels: [],
        customer: {
            reads_data: [],
            re_reads_data: [],
            articles_data: [],
            total_reads: 0,
            total_re_reads: 0,
        },
        internal: {
            reads_data: [],
            re_reads_data: [],
            articles_data: [],
            total_reads: 0,
            total_re_reads: 0,
        },
        all: {
            reads_data: [],
            re_reads_data: [],
            articles_data: [],
            total_reads: 0,
            total_re_reads: 0,
        },
    },
    total_customers: {
        graph_circle_labels: [],
        customer_circle_data: [],
        total_customers: 0,
    },
    lite_users: {
        num_lite_users: 0,
        num_fb_lite_users: 0,
        num_google_lite_users: 0,
        num_apple_lite_users: 0,
        num_manual_lite_users: 0,
        num_multiple_social_login_lite_users: 0,
    },
    num_authors: 0,
    num_advocates: 0,
};

const defaultLeaderboardData = {
    top_articles: [],
    top_authors: [],
};

const defaultChirperData = {
    num_chirps: 0,
    num_reads: 0,
    num_likes: 0,
    num_image_clicks: 0,
    num_link_clicks: 0,
    impressions: {
        graph_date_labels: [],
        customer: {
            impressions_data: [],
            re_impressions_data: [],
            chirps_data: [],
            total_impressions: 0,
            total_re_impressions: 0,
        },
        internal: {
            impressions_data: [],
            re_impressions_data: [],
            chirps_data: [],
            total_impressions: 0,
            total_re_impressions: 0,
        },
        all: {
            impressions_data: [],
            re_impressions_data: [],
            chirps_data: [],
            total_impressions: 0,
            total_re_impressions: 0,
        },
    },
};

const defaultAuthFunnelGraph = {
    able_to_browse_application : 0,
    authenticated: 0,
    basic_create_lite_account: 0,
    full_page_1: 0,
    full_page_2: 0,
    landing_screen: 0,
    login: 0,
    login_submit: 0,
    register_drawer: 0,
    straight_into_app: 0,
    submit_create_full_account: 0,
    submit_create_lite_account: 0,
    submit_zip_code_modal: 0,
    zip_code_modal_page_1: 0,
    zip_code_modal_page_2: 0,
};

export const reports = createReducer(
    {
        weekData: defaultData,
        isLoadingWeekData: false,
        isLoadingWeekLeaderboardData: false,
        isLoadingWeekChirperData: false,
        monthData: defaultData,
        isLoadingMonthData: false,
        isLoadingMonthLeaderboardData: false,
        isLoadingMonthChiperData: false,
        yearData: defaultData,
        isLoadingYearData: false,
        isLoadingYearLeaderboardData: false,
        isLoadingYearChirperData: false,
        allData: defaultData,
        isLoadingAllData: false,
        isLoadingAllLeaderboardData: false,
        isLoadingAllChirperData: false,
        streamFreshnessData: [],
        isLoadingStreamFreshnessData: false,
        authFunnelGraphData: defaultAuthFunnelGraph,
        isLoadingAuthFunnelGraphData: false,
        monthlyContentCompetitionData: null,
        contentReportData: null,
    },
    {
        [types.SET_SUMMARY_ANALYTICS_WEEK](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.weekData !== null) {
                newState.weekData.summary = action.weekData;
                newState.isLoadingWeekData = false;
            } else {
                newState.weekData.summary = defaultSummaryData;
                newState.isLoadingWeekData = true;
            }
            return newState;
        },
        [types.SET_SUMMARY_ANALYTICS_MONTH](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.monthData !== null) {
                newState.monthData.summary = action.monthData;
                newState.isLoadingMonthData = false;
            } else {
                newState.monthData.summary = defaultSummaryData;
                newState.isLoadingMonthData = true;
            }
            return newState;
        },
        [types.SET_SUMMARY_ANALYTICS_YEAR](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.yearData !== null) {
                newState.yearData.summary = action.yearData;
                newState.isLoadingYearData = false;
            } else {
                newState.yearData.summary = defaultSummaryData;
                newState.isLoadingYearData = true;
            }
            return newState;
        },
        [types.SET_SUMMARY_ANALYTICS_ALL](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.allData !== null) {
                newState.allData.summary = action.allData;
                newState.isLoadingAllData = false;
            } else {
                newState.allData.summary = defaultSummaryData;
                newState.isLoadingAllData = true;
            }
            return newState;
        },

        [types.SET_LEADERBOARD_ANALYTICS_WEEK](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.weekData !== null) {
                newState.weekData.leaderboards = action.weekData;
                newState.isLoadingWeekLeaderboardData = false;
            } else {
                newState.weekData.leaderboards = defaultLeaderboardData;
                newState.isLoadingWeekLeaderboardData = true;
            }
            return newState;
        },
        [types.SET_LEADERBOARD_ANALYTICS_MONTH](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.monthData !== null) {
                newState.monthData.leaderboards = action.monthData;
                newState.isLoadingMonthLeaderboardData = false;
            } else {
                newState.monthData.leaderboards = defaultLeaderboardData;
                newState.isLoadingMonthLeaderboardData = true;
            }
            return newState;
        },
        [types.SET_LEADERBOARD_ANALYTICS_YEAR](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.yearData !== null) {
                newState.yearData.leaderboards = action.yearData;
                newState.isLoadingYearLeaderboardData = false;
            } else {
                newState.yearData.leaderboards = defaultLeaderboardData;
                newState.isLoadingYearLeaderboardData = true;
            }
            return newState;
        },
        [types.SET_LEADERBOARD_ANALYTICS_ALL](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.allData !== null) {
                newState.allData.leaderboards = action.allData;
                newState.isLoadingAllLeaderboardData = false;
            } else {
                newState.allData.leaderboards = defaultLeaderboardData;
                newState.isLoadingAllLeaderboardData = true;
            }
            return newState;
        },

        [types.SET_CHIRPER_ANALYTICS_WEEK](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.weekData !== null) {
                newState.weekData.chirper = action.weekData;
                newState.isLoadingWeekChirperData = false;
            } else {
                newState.weekData.chirper = defaultChirperData;
                newState.isLoadingWeekChirperData = true;
            }
            return newState;
        },
        [types.SET_CHIRPER_ANALYTICS_MONTH](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.monthData !== null) {
                newState.monthData.chirper = action.monthData;
                newState.isLoadingWeekChirperData = false;
            } else {
                newState.monthData.chirper = defaultChirperData;
                newState.isLoadingWeekChirperData = true;
            }
            return newState;
        },
        [types.SET_CHIRPER_ANALYTICS_YEAR](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.yearData !== null) {
                newState.yearData.chirper = action.yearData;
                newState.isLoadingWeekChirperData = false;
            } else {
                newState.yearData.chirper = defaultChirperData;
                newState.isLoadingWeekChirperData = true;
            }
            return newState;
        },
        [types.SET_CHIRPER_ANALYTICS_ALL](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.allData !== null) {
                newState.allData.chirper = action.allData;
                newState.isLoadingWeekChirperData = false;
            } else {
                newState.allData.chirper = defaultChirperData;
                newState.isLoadingWeekChirperData = true;
            }
            return newState;
        },

        [types.SET_STREAM_FRESHNESS_DATA](state, action) {
            let newState = Object.assign({}, state);
            if (action.streamFreshnessData !== null) {
                newState.streamFreshnessData = action.streamFreshnessData;
                newState.isLoadingStreamFreshnessData = false;
            } else {
                newState.streamFreshnessData = [];
                newState.isLoadingStreamFreshnessData = true;
            }
            return newState;
        },

        [types.SET_AUTH_FUNNEL_GRAPH_DATA](state, action) {
            let newState = Object.assign({}, state);
            if (action.authFunnelGraphData !== null) {
                newState.authFunnelGraphData = action.authFunnelGraphData;
                newState.isLoadingAuthFunnelGraphData = false;
            } else {
                newState.authFunnelGraphData = defaultAuthFunnelGraph;
                newState.isLoadingAuthFunnelGraphData = true;
            }
            return newState;
        },

        [types.SET_MONTHLY_CONTENT_COMPETITION_DATA](state, action) {
            let newState = Object.assign({}, state);
            if (action.monthlyContentCompetitionData !== null) {
                newState.monthlyContentCompetitionData = action.monthlyContentCompetitionData;
            } else {
                newState.monthlyContentCompetitionData = null;
            }
            return newState;
        },

        [types.SET_CONTENT_REPORT_DATA](state, action) {
            let newState = Object.assign({}, state);
            if (action.contentReportData !== null) {
                newState.contentReportData = action.contentReportData;
            } else {
                newState.contentReportData = null;
            }
            return newState;
        }
    },
);
