import React, { Component } from "react";
import "styles/App.css";
import { NavLink } from "react-router-dom";
import Flexbox from "flexbox-react";
import * as Constants from "Constants";

import { ButtonOpacity } from "innercast-core-js/dist/module";

class SidebarGroup extends Component {
    constructor(props) {
        super(props);

        this.state = { open: true };
    }

    renderIcon = () => {
        if (this.props.iconName) {
            const icon2x = require("assets/" + this.props.iconName + "@2x.png");
            const icon3x = require("assets/" + this.props.iconName + "@3x.png");

            return (
                <img
                    alt=""
                    src={require("assets/" + this.props.iconName + ".png")}
                    style={{ marginLeft: 5 }}
                    srcSet={`
            ${icon2x} 2x,
            ${icon3x} 3x
          `}
                />
            );
        } else if (this.props.svgIconName) {
            return (
                <img alt="" src={require(`assets/${this.props.svgIconName}.svg`)} width={24} style={{ marginLeft: 5 }} />
            );
        }
        return <div style={{ width: 26 }} />;
    };

    _toggleAccordian = (e) => {
        this.setState({ open: !this.state.open });

        e.preventDefault();
    };

    render() {
        // const icon2x = require('assets/'+this.props.iconName+'@2x.png');
        // const icon3x = require('assets/'+this.props.iconName+'@3x.png');

        let children = this.props.children ? (
            <Flexbox flexDirection="column" className="Submenu">
                {this.props.children}
            </Flexbox>
        ) : null;

        let collapseIcon = require("assets/expand_arrow.png");
        let collapseIcon2x = require("assets/expand_arrow@2x.png");
        let collapseIcon3x = require("assets/expand_arrow@3x.png");
        if (this.state.open) {
            collapseIcon = require("assets/collapse_arrow.png");
            collapseIcon2x = require("assets/collapse_arrow@2x.png");
            collapseIcon3x = require("assets/collapse_arrow@3x.png");
        } else {
            children = null;
        }

        let leftPadding = 0;
        if (this.props.submenuLevel) {
            leftPadding += 28 * this.props.submenuLevel;
        }

        let data_cy = "sidebar-" + this.props.title.replace(/ /g, "_");
        data_cy = data_cy.replace(/'/g, "");

        return (
            <Flexbox flexDirection="column" className="SidebarGroup" style={{ width: "100%" }}>
                <NavLink
                    className="sidebarNav"
                    onClick={this.props.onClick}
                    data-cy={data_cy}
                    exact
                    to={this.props.link}
                    style={{ textDecoration: "none", backgroundColor: Constants.TertiaryColor }}
                    activeStyle={{ backgroundColor: Constants.DarkestTertiaryColor }}>
                    <Flexbox
                        data-tut={this.props.dataTut}
                        flexDirection="row"
                        alignItems="center"
                        style={{
                            color: "white",
                            textDecoration: "none",
                            paddingLeft: 10 + leftPadding,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingRight: 20,
                        }}>
                        <ButtonOpacity
                            onClick={this._toggleAccordian}
                            icon1x={collapseIcon}
                            icon2x={collapseIcon2x}
                            icon3x={collapseIcon3x}
                            title="Expand or collapse section"
                        />
                        {this.renderIcon()}
                        <div style={{ marginLeft: 10 }}>{this.props.title}</div>
                    </Flexbox>
                </NavLink>
                {children}
            </Flexbox>
        );
    }
}

export default SidebarGroup;
