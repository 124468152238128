import React, {PureComponent} from 'react';

import Flexbox from 'flexbox-react';

class ErrorScreen extends PureComponent {

    render() {
        return (
            <Flexbox flexDirection="column" minHeight="100%"
                     style={{backgroundImage: `url(${require('assets/fabric-of-squares.png')})`}}>
                <Flexbox flexDirection="column" justifyContent="center" alignItems="center" flexGrow={10}>
                    <div>
                        <h1>
                            Oops!
                        </h1>
                        <p>
                            An error has occurred. Our development team has been notified.
                        </p>
                        <p>
                            Return to <a href="/BASF" alt="Home" >Home Page</a>.
                        </p>
                    </div>
                </Flexbox>
            </Flexbox>
        );
    }
}

export default ErrorScreen;
