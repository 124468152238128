import createReducer from "../../lib/createReducer";
import * as types from "../actions/types";

const DEFAULT_STATE_DEVELOPER_GROUP = {
    members: null,
    groupID: null,
    inviteLink: "",
    inviteLinkExpiry: null,
    APIKey1: "",
    APIKey2: "",
    company: null,
    allowedEndpoints: []
};

export const developerGroup = createReducer(
    DEFAULT_STATE_DEVELOPER_GROUP,
    {
        [types.SET_DEV_GROUP_DETAILS](state, action) {
            let newState = Object.assign({}, state);

            if (action.data === null) {
                newState = DEFAULT_STATE_DEVELOPER_GROUP;
            } else {
                newState.members = action.data.members;
                newState.groupID = action.data.developer_group_id;
                newState.company = action.data.company;
                newState.APIKey1 = action.data.api_key_1;
                newState.APIKey2 = action.data.api_key_2;
                newState.inviteLink = action.data.invite_link;
                newState.inviteLinkExpiry = action.data.invite_link_expiration_date;
                newState.allowedEndpoints = action.data.allowed_endpoints
            }

            return newState;
        },

        [types.RESET_DEV_INVITE_LINK](state, action) {
            let newState = Object.assign({}, state);
            if (action.data !== null) {
                newState.inviteLink = action.data.new_link;
                newState.inviteLinkExpiry = action.data.new_date;
            }
            return newState;
        }
    }
);


const DEFAULT_STATE_DEVELOPER_DASHBOARD = {
    totalCalls: 0,
    successCalls: 0,
    failCalls: 0,
    month: 0,
    year: 0,
    timeLabels: [],
    totalCallsList: [],
    successCallsList: [],
    failCallsList: [],
    quota: 0,
    developerSeatsAllowed: 0,
    developerSeatsUsed: 0,
    dateFirstApiCall: null
};

export const developerDashboard = createReducer(
    DEFAULT_STATE_DEVELOPER_DASHBOARD,
    {
        [types.SET_DEV_DASHBOARD_DETAILS](state, action) {
            let newState = Object.assign({}, state);

            if (action.data === null) {
                newState = DEFAULT_STATE_DEVELOPER_DASHBOARD;
            } else {
                newState.quota = action.data.quota;
                newState.totalCalls = action.data.api_calls.total_calls;
                newState.successCalls = action.data.api_calls.success_calls;
                newState.failCalls = action.data.api_calls.fail_calls;
                newState.month = action.data.graph.month;
                newState.year = action.data.graph.year;
                newState.timeLabels = action.data.graph.time_labels;
                newState.totalCallsList = action.data.graph.total_calls_list;
                newState.successCallsList = action.data.graph.success_calls_list;
                newState.failCallsList = action.data.graph.fail_calls_list;
                newState.developerSeatsAllowed = action.data.developer_seats_allowed;
                newState.developerSeatsUsed = action.data.developer_seats_used;
                newState.dateFirstApiCall = action.data.date_first_api_call;
            }

            return newState;
        },
    }
);
