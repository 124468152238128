import React, { PureComponent } from "react";

import Flexbox from "flexbox-react";
import { Progress, Button } from "semantic-ui-react";

import { DarkestTertiaryColor, DarkerTertiaryColor } from "Constants";

export default class JobQueueView extends PureComponent {
  render() {
    if (!this.props.activeJob || this.props.activeJob === null || this.props.failed) {
      return null;
    }
    
    let uploadLabelText = '';
    if (!this.props.isUploadingArticle) {
        uploadLabelText = `Uploading local media ${this.props.currIndex}/${this.props.totalMediaToUpload}`;
    } else {
        uploadLabelText = `Uploading ${this.props.activeJob.title === '' ? 'Untitled draft' : this.props.activeJob.title}`;
    }
      
    return (
      <Flexbox
        flexDirection="column"
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 10,
          paddingBottom: 10,
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: DarkerTertiaryColor,
          borderTop: `1px solid ${DarkestTertiaryColor}`
        }}
      >
        <span
          style={{
            color: "white",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 5
          }}
        >{uploadLabelText}</span>
        <Flexbox alignItems="center">
          <Progress
            style={{
              flexGrow: 1,
              marginTop: "auto",
              marginBottom: "auto",
              marginRight: 5
            }}
            inverted
            percent={this.props.progress}
            // indicating
            color="green"
            size="small"
          />
          <Button
            onClick={this.props.clearQueue}
            circular
            size="mini"
            inverted
            icon="cancel"
            title="Clear Queue"
          />
        </Flexbox>
      </Flexbox>
    );
  }
}
