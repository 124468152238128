import { executeBasicNetworkCall } from "lib/Utils";
import * as types from 'ContentManager/actions/types.js';
import * as MessageActions from "actions/messages";

export function getMyReviewerContentLists(callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_my_reviewer_content_lists",
            JSON.stringify({}),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                    } else {
                        dispatch(setMyReviewerContentLists(x.data));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function reviewerApproveProject(
    project_id,
    callback = () => { },
) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "reviewer_approve_project",
            JSON.stringify({ project_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                        dispatch(MessageActions.setError(x.message));
                    } else {
                        callback(true, x.data);
                        dispatch(MessageActions.setSuccess(x.message));
                    }
                }
            },
        );
    };
}

export function updateProjectTags(
    project_id,
    state_ids,
    br_district_ids,
    br_region_ids,
    content_category_ids,
    crop_ids,
    product_ids,
    is_customer_exclusive,
    is_featured,
    callback = () => {}
) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "update_project_tags",
            JSON.stringify({
                project_id,
                state_ids,
                br_district_ids,
                br_region_ids,
                content_category_ids,
                crop_ids,
                product_ids,
                is_customer_exclusive,
                is_featured,
            }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                        dispatch(MessageActions.setError(x.message));
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

function setMyReviewerContentLists(data = null) {
    return {
        type: types.SET_MY_REVIEW_CONTENT_LISTS,
        data: data,
    };
}
