import createReducer from "../../lib/createReducer";
import * as types from "CronJobManager/actions/types";

export const cronJobManager = createReducer(
    {
        jobs: [],
    },
    {
        [types.SET_CRON_JOBS_LIST](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.data !== null) {
                newState.jobs = action.data;
            } else {
                newState = {
                    jobs: [],
                };
            }

            return newState;
        },
    },
);
