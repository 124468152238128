import createReducer from "lib/createReducer";
import * as types from "ContentManager/actions/types";

const DEFAULT_STATE = {
    content_manager: 0,
    for_review: 0,
    my_content: 0,

    submitted_articles: 0,
    submitted_observations: 0,
    unsubmitted_articles: 0,
};

export const contentBadges = createReducer(
    DEFAULT_STATE,
    {
        [types.SET_CONTENT_BADGES](state, action) {
            let newState = Object.assign({}, state);
            if (action.data === null || action.data === undefined) {
                newState = DEFAULT_STATE;
            } else {
                if (action.data.hasOwnProperty('content_manager')) {
                    newState.content_manager = action.data.content_manager;
                }
                if (action.data.hasOwnProperty('for_review')) {
                    newState.for_review = action.data.for_review;
                }
                if (action.data.hasOwnProperty('my_content')) {
                    newState.my_content = action.data.my_content;
                }
                if (action.data.hasOwnProperty('submitted_articles')) {
                    newState.submitted_articles = action.data.submitted_articles;
                }
                if (action.data.hasOwnProperty('submitted_observations')) {
                    newState.submitted_observations = action.data.submitted_observations;
                }
                if (action.data.hasOwnProperty('unsubmitted_articles')) {
                    newState.unsubmitted_articles = action.data.unsubmitted_articles;
                }
            }
            
            return newState;
        },

    }
);
