export const SET_VIEW_CROP_PRODUCT_INFO_LIST_BY = "SET_VIEW_CROP_PRODUCT_INFO_LIST_BY";
export const SET_CROP_LIST = "SET_CROP_LIST";
export const SET_CROP_DETAILS = "SET_CROP_DETAILS";
export const SET_PRODUCT_LIST = "SET_PRODUCT_LIST";
export const SET_PRODUCT_DETAILS = "SET_PRODUCT_DETAILS";
export const SET_DISTRICTS = "SET_DISTRICTS";
export const SET_DISTRICT_DETAILS = "SET_DISTRICT_DETAILS";
export const SET_DISTRICT_CROP_DETAILS = "SET_DISTRICT_CROP_DETAILS";
export const SET_DISTRICT_PRODUCT_DETAILS = "SET_DISTRICT_PRODUCT_DETAILS";
export const SET_TOOLS_LIST = "SET_TOOLS_LIST";
