import { executeBasicNetworkCall } from "../../lib/Utils";
import * as types from "./types";
import { setError } from "../../actions/messages";

export function getDeveloperGroupDetails() {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_developer_group_details",
            null,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(setDeveloperGroupDetails(null));
                    } else {
                        dispatch(setDeveloperGroupDetails(x.data));
                    }
                }
            }
        );
    };
}

export function setDeveloperGroupDetails(data) {
    return {
        type: types.SET_DEV_GROUP_DETAILS,
        data: data
    };
}

export function resetAPIKey(keyNum = 1) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "reset_developer_api_key",
            JSON.stringify({ api_key: keyNum }),
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(setError("Error resetting API key: " + x.msg));
                    } else {
                        dispatch(getDeveloperGroupDetails());
                    }
                }
            }
        );
    };
}

function setDeveloperDashboardDetails(data) {
    return {
        type: types.SET_DEV_DASHBOARD_DETAILS,
        data: data
    };
}

export function getDeveloperDashboardData(month = null, year = null) {
    let json;
    if (month === null && year === null) {
        json = JSON.stringify({});
    } else {
        json = JSON.stringify({ month: month, year: year });
    }

    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_developer_dashboard_data",
            json,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        console.log(x);
                    } else {
                        dispatch(setDeveloperDashboardDetails(x.data));
                    }
                }
            }
        );
    };
}

export function removeDeveloperTeamMember(
    developer_group_user_id,
    callback = () => {}
) {
    return (dispatch, getState) => {
        const params = JSON.stringify({ developer_group_user_id });

        executeBasicNetworkCall(
            "remove_developer_team_member",
            params,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(setError(x.error));
                        callback(false);
                    } else {
                        dispatch(getDeveloperGroupDetails());
                        callback(true);
                    }
                }
            }
        );
    };
}

export function resetDeveloperInviteLink() {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "reset_developer_invite_link",
            undefined,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(setError(x.error));
                    } else {
                        dispatch(setInviteLink(x.data));
                    }
                }
            }
        );
    };
}

function setInviteLink(data) {
    return {
        type: types.RESET_DEV_INVITE_LINK,
        data
    };
}
