import createReducer from "lib/createReducer";
import * as types from "actions/types";

const DEFAULT_STATE = {
    exclude_post_ids: [],
};

export const hashtagAnalytics = createReducer(
    DEFAULT_STATE,
    {
        [types.SET_EXCLUDE_POST_IDS](state, action) {
            let newState = Object.assign({}, state);
            newState.exclude_post_ids = action.data;
            return newState;
        },
    }
);
