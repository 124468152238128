import { executeBasicNetworkCall } from "lib/Utils";
// import * as types from 'ContentManager/actions/types.js';

export function getListOfReviewers(callback = () => { }) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_list_of_reviewers",
            JSON.stringify({}),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function addReviewerToProject(project_id, reviewer_user_id, callback = () => { }) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "add_reviewer_to_project",
            JSON.stringify({ project_id, reviewer_user_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function deleteReviewerFromProject(project_id, project_reviewer_id, callback = () => { }) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "delete_reviewer_from_project",
            JSON.stringify({ project_id, project_reviewer_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function editProjectReviewer(project_id, project_reviewer_id, role_id, due_date, callback = () => { }) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "edit_project_reviewer",
            JSON.stringify({ project_id, project_reviewer_id, role_id, due_date }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function reorderProjectReviewers(project_id, project_reviewer_ids = [], callback = () => { }) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "reorder_project_reviewers",
            JSON.stringify({ project_id, project_reviewer_ids }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function getReviewerRolesList(callback = () => { }) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_roles_list",
            JSON.stringify({}),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}
