import createReducer from "lib/createReducer";
import * as types from "CropProductInfo/actions/types";

const DEFAULT_STATE = {
    product_name: '',
    district_code: '',
    region_name: '',

    fields: [],
};

export const districtProductDetails = createReducer(
    DEFAULT_STATE,
    {
        [types.SET_DISTRICT_PRODUCT_DETAILS](state, action) {
            let newState = Object.assign({}, state);
            if (action.data === null || action.data === undefined) {
                newState = DEFAULT_STATE;
            } else {
                if (action.data.product_name) {
                    newState.product_name = action.data.product_name;
                }
                if (action.data.district_code) {
                    newState.district_code = action.data.district_code;
                }
                if (action.data.region_name) {
                    newState.region_name = action.data.region_name;
                }
                if (action.data.fields) {
                    newState.fields = action.data.fields;
                }
            }
            return newState;
        },
    }
);
