import { useEffect } from "react";
import * as ResponsiveActions from "./redux/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useMedia } from "../lib/hooks";

export const MOBILE_MAX_WIDTH = 1000; // in pixels
export const PADDED_MIN_WIDTH = 1225; // in pixels
export const SIDEBAR_SHOWING_MIN_WIDTH = 800; //in pixels
const Actions = Object.assign({}, ResponsiveActions);

function ResponsiveManager({ setIsResponsive, setIsPadded, setIPhoneSEMql, setSidebarShowing, }) {
    const mql = useMedia([`(min-width: ${MOBILE_MAX_WIDTH}px)`], [false], true);
    const isPadded = useMedia([`(max-width: ${PADDED_MIN_WIDTH}px)`], [false], true);
    const iPhoneSEMql = useMedia(["(min-width: 350px)"], [false], true);
    const sidebarShowingMql = useMedia([`(max-width: ${SIDEBAR_SHOWING_MIN_WIDTH}px)`], [false], true);

    useEffect(() => {
        setIsResponsive(mql);
    }, [mql, setIsResponsive]);

    useEffect(() => {
        setIsPadded(isPadded);
    }, [isPadded, setIsPadded]);

    useEffect(() => {
        setIPhoneSEMql(iPhoneSEMql);
    }, [iPhoneSEMql, setIPhoneSEMql]);

    useEffect(() => {
        setSidebarShowing(sidebarShowingMql);
    }, [sidebarShowingMql, setSidebarShowing]);

    return null;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(null, mapDispatchToProps, null)(ResponsiveManager);
