import createReducer from "../lib/createReducer";
import * as types from "../actions/types";

export const drafts = createReducer(
  {
    list: []
  },
  {
    [types.SET_DRAFTS](state, action) {
      let newState = Object.assign({}, state);
      newState.list = action.drafts;
      return newState;
    }
  }
);

export const history = createReducer(
  {
    list: []
  },
  {
    [types.SET_HISTORY](state, action) {
      let newState = Object.assign({}, state);
      newState.list = action.history;
      return newState;
    }
  }
);
