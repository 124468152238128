import { HOST } from "Constants";

import bugsnagClient from "lib/bugsnag";

export function getURLForEndpointName(name, urls) {
    for (let i = 0; i < urls.length; i++) {
        const endpoint = urls[i];
        if (endpoint.name === name) {
            return endpoint.url;
        }
    }
}

export function executeBasicNetworkCall(
    endpoint,
    params,
    contentType,
    httpMethod = "POST",
    getState,
    onReady = () => {},
    onError = () => {},
    debug = false,
    onBadStatus = () => {},
    overrideURL,
    withCredentials = true,
) {
    if (debug) {
        // eslint-disable-next-line no-console
        console.log(endpoint);
    }

    const endpoints = getState().storydynamics.endpoints;
    if (endpoints === null && overrideURL === undefined) {
        return;
    }
    if ((endpoint === undefined || endpoint === null) && overrideURL === undefined) {
        return;
    }

    let URL = overrideURL;

    if (URL === undefined || URL === null) {
        // BUILD THE API URL
        const API_VERSION = endpoints.current_api_version;
        const ENDPOINT = getURLForEndpointName(endpoint, endpoints.urls);
        URL = `${HOST}/API/${API_VERSION}${ENDPOINT}`;
    }

    if (debug) {
        // eslint-disable-next-line no-console
        console.log(URL);
    }

    const reqq = new XMLHttpRequest();
    reqq.onreadystatechange = (e) => {
        try {
            if (reqq.readyState !== 4) {
                return;
            }
            if (debug) {
                // eslint-disable-next-line no-console
                console.log(reqq);
            }
            if (reqq.status !== 200) {
                onBadStatus(reqq.status);
                bugsnagClient.notify(new Error(`endpoint returned status ${reqq.status}`));
            } else {
                onReady(reqq);
            }
        } catch (error) {
            onError(error);
            bugsnagClient.notify(error);
        }
    };
    reqq.open(httpMethod, URL);
    reqq.setRequestHeader("Content-Type", contentType);
    reqq.withCredentials = withCredentials;
    reqq.send(params);
}

// immutable array splice function
export function splice(input, start, deleteCount) {
    deleteCount = deleteCount === null ? input.length - start : deleteCount;
    const items = [].slice.call(arguments, 3);
    let output;
    return (output = input.slice(0, start)).concat.apply(output, items).concat(input.slice(start + deleteCount));
}

export function sortBy(key) {
    return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
}

export function validateEmailAddress(email) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function numberWithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function createEnum(values) {
    const enumObject = {};
    for (const val of values) {
        enumObject[val] = val;
    }
    return Object.freeze(enumObject);
}

export function getViewerFELocation() {
    let location = window.location.origin;
    if (location.includes("localhost")) {
        location = location.replace("3001", "3005");
    } else if (location.includes(".linxsystems.com")) {
        location = location.replace("gsl", "gslv3");
    } else if (location.includes("alpha.") || location.includes("beta.")) {
        location = location.replace(".internal", "");
    } else {
        location = "https://www.growsmartlive.com";
    }
    return location;
}

export function removeHttp(url) {
    return url.replace(/^https?:\/\//, "");
}
