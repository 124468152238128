import createReducer from '../lib/createReducer'
import * as types from '../actions/types'

import * as Constants from 'Constants';

const ORIENTATION_PORTRAIT = 0;
//const ORIENTATION_LANDSCAPE = 1;


export const widgetstate = createReducer({
    orientation: ORIENTATION_PORTRAIT,
    sidebarVisible: true,
    mainTourHasBeenWatched: false,
    composeTab: Constants.ComposeTabDrafts,
    editorToolbarDisplay: Constants.ICON_AND_TEXT,
    changelogVisible: false
  }, {

    // persist that the main tour has been viewed so we don't keep
    // showing it to the user and annoying them.
  [types.SET_MAIN_TOUR_AS_WATCHED___WEB](state, action) {
    let newState = Object.assign({}, state);
    newState.mainTourHasBeenWatched = true;
    return newState;
  },

  [types.TOGGLE_SIDEBAR_VISIBLE___WEB](state, action) {

    // clone the existing state
    let newState = Object.assign({}, state);
    newState.sidebarVisible = action.isVisible;
    return newState;
  },

  [types.DETECTED_POSSIBLE_ORIENTATION_CHANGE](state, action) {
    // clone the existing state
    let newState = Object.assign({}, state);
    newState.orientation = action.isLandscapeOrientation;
    return newState;
  },

  [types.SET_SELECTED_COMPOSE_TAB](state, action) {
    let newState = Object.assign({}, state);
    newState.composeTab = action.selectedTab;
    return newState;
  },

  [types.SET_EDITOR_TOOLBAR_DISPLAY](state, action) {
    let newState = Object.assign({}, state);
    newState.editorToolbarDisplay = action.display;
    return newState;
  },

  [types.TOGGLE_CHANGELOG_VISIBILITY](state) {
    let newState = Object.assign({}, state);
    newState.changelogVisible = !newState.changelogVisible;
    return newState;
  },

});
