import createReducer from "lib/createReducer";
import * as types from "QRG/actions/types";
import { ViewQRGListByOptions } from "QRG/Constants";

const DEFAULT_STATE = {
    view_qrg_list_by: ViewQRGListByOptions.Topic,
    topic_list: [],
    topic_details: [],
    qrg_details: {
        available_br_district_dropdown_options: [],
        in_season_br_districts: [],
        out_of_season_br_districts: [],
        pdf_file_url: undefined,
        taken_br_district_codes: [],
        title: '',
        topic_name: '',
    },
    district_list: [],
    district_details: [],
};

export const qrg = createReducer(
    DEFAULT_STATE,
    {
        [types.SET_VIEW_QRG_LIST_BY](state, action) {
            let newState = Object.assign({}, state);
            newState.view_qrg_list_by = action.data;
            return newState;
        },

        [types.SET_TOPICS](state, action) {
            let newState = Object.assign({}, state);
            newState.topic_list = action.data;
            return newState;
        },

        [types.SET_TOPIC_DETAILS](state, action) {
            let newState = Object.assign({}, state);
            newState.topic_details = action.data;
            return newState;
        },

        [types.SET_QRG_DETAILS](state, action) {
            let newState = Object.assign({}, state);
            if (action.data === null || action.data === undefined) {
                newState.qrg_details = {
                    available_br_district_dropdown_options: [],
                    in_season_br_districts: [],
                    out_of_season_br_districts: [],
                    pdf_file_url: undefined,
                    taken_br_district_codes: [],
                    title: '',
                    topic_name: '',
                };
            } else {
                if (action.data.available_br_district_dropdown_options) {
                    newState.qrg_details.available_br_district_dropdown_options = action.data.available_br_district_dropdown_options;
                }
                if (action.data.in_season_br_districts) {
                    newState.qrg_details.in_season_br_districts = action.data.in_season_br_districts;
                }
                if (action.data.out_of_season_br_districts) {
                    newState.qrg_details.out_of_season_br_districts = action.data.out_of_season_br_districts;
                }
                if (action.data.pdf_file_url) {
                    newState.qrg_details.pdf_file_url = action.data.pdf_file_url;
                }
                if (action.data.taken_br_district_codes) {
                    newState.qrg_details.taken_br_district_codes = action.data.taken_br_district_codes;
                }
                if (action.data.title) {
                    newState.qrg_details.title = action.data.title;
                }
                if (action.data.topic_name) {
                    newState.qrg_details.topic_name = action.data.topic_name;
                }
            }
            
            return newState;
        },

        [types.SET_DISTRICTS](state, action) {
            let newState = Object.assign({}, state);
            newState.district_list = action.data;
            return newState;
        },

        [types.SET_DISTRICT_DETAILS](state, action) {
            let newState = Object.assign({}, state);
            newState.district_details = action.data;
            return newState;
        },
    }
);
