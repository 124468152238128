export const ViewCropProductInfoBy = {
    Crop: 'crop',
    Product: 'product',
    District: 'district'
};


/** Sample Data for Testing and Building **/

export const cropListSample = [
    {
        crop_id: 1,
        crop_name: "Corn",
        date_created: new Date(),
    },
    {
        crop_id: 2,
        crop_name: "Soybeans",
        date_created: new Date(),
    },
];

export const cropDetailsSample = {
    crop_id: 1,
    crop_name: 'Corn',
    date_created: new Date(),
    value_statement: 'Corn Value Statement Here',
}

export const productListSample = [
    {
        category_id: 1,
        category_name: "Hebicide",
        products: [
            {
                product_id: 1,
                product_name: "Zidua PRO",
                is_published: false,
                missing_fields: ["Value Statement*", "Feature Video"]
            },
            {
                product_id: 2,
                product_name: "Headline AMP",
                is_published: false,
                missing_fields: [],
            },
        ]
    },
];

export const productDetailsSample = {
    id: 1,
    name: 'Zidua PRO',
    value_statement: 'Product Value Statement Here',
    feature_video_id: null,
    feature_video_url: null,
    description: 'Product Description Here',
    quick_reference_guide: null,
    label: '',
    tools: [],
}

export const districtListSample = [
    {
        district_id: 1,
        date_created: new Date(),
        br_district_code: "101",
        region_name: "Midwest"
    },
    {
        district_id: 2,
        date_created: new Date(),
        br_district_code: "102",
        region_name: "Midwest"
    },
];

export const districtDetailsSample = {
    district_id: 1,
    date_created: new Date(),
    br_district_code: '101',
    region_name: 'Midwest',
    district_crops: [
        {
            crop_id: 1,
            crop_name: 'Corn',
        },
        {
            crop_id: 2,
            crop_name: 'Soybeans',
        },
    ],
    district_products: [
        {
            product_id: 1,
            product_name: "Zidua PRO",
            fields_overridden: ["QRG", "Value Statement"],
        },
        {
            product_id: 2,
            product_name: "Headline AMP",
            fields_overridden: [],
        },
        {
            product_id: 3,
            product_name: "Zidua SC",
            fields_overridden: [],
        },
    ],
};
