import { executeBasicNetworkCall } from "lib/Utils";
import * as types from 'CropProductInfo/actions/types.js';

export function getProductList(callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_product_list",
            null,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                    } else {
                        dispatch(setProductList(x.data));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function createProduct(product_name = '', callback = () => {}) {
    return (dispatch, getState) => {
        // executeBasicNetworkCall(
        //     "create_product",
        //     JSON.stringify({ product_name: product_name }),
        //     "application/json",
        //     "POST",
        //     getState,
        //     (reqq) => {
        //         if (reqq.responseText != null) {
        //             const x = JSON.parse(reqq.responseText);
        //             if (x.error) {
        //                 callback(false, x.message);
        //             } else {
        //                 // dispatch(setProducts(x.data));
        //                 callback(true, x.data);
        //             }
        //         }
        //     },
        //     (err) => console.log(err),
        //     false,
        //     (status) => console.log(status),
        // );
    };
}

export function archiveProduct(product_id = '', callback = () => {}) {
    return (dispatch, getState) => {
        // executeBasicNetworkCall(
        //     "archive_product",
        //     JSON.stringify({ product_id: product_id }),
        //     "application/json",
        //     "POST",
        //     getState,
        //     (reqq) => {
        //         if (reqq.responseText != null) {
        //             const x = JSON.parse(reqq.responseText);
        //             if (x.error) {
        //                 callback(false, x.message);
        //             } else {
        //                 callback(true, x.data);
        //             }
        //         }
        //     },
        //     (err) => console.log(err),
        //     false,
        //     (status) => console.log(status),
        // );
    };
}

export function getProductDetails(product_id = '', callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_product_details",
            JSON.stringify({ product_id: product_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        dispatch(setProductDetails(x.data));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function editProduct(
    product_id = null,
    link = '',
    value_statement = '',
    description = '',
    disclaimer = '',
    label = '',
    qrg_url = null,
    cta_label = '',
    cta_link = null,
    feature_video_id = null,
    feature_image_id = null,
    tool_ids = [],
    callback = () => {}
) {
    let json = JSON.stringify({
        product_id,
        link,
        value_statement,
        description,
        disclaimer,
        label,
        qrg_url,
        cta_label,
        cta_link,
        feature_video_id,
        feature_image_id,
        tool_ids
    });
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "edit_product",
            json,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.message);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        )
    }
}


function setProductList(data) {
    return {
        type: types.SET_PRODUCT_LIST,
        data: data,
    };
}

export function setProductDetails(data) {
    return {
        type: types.SET_PRODUCT_DETAILS,
        data: data,
    };
}
