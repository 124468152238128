import createReducer from "lib/createReducer";
import * as types from "DraftedContent/actions/types";

export const unclaimedProjects = createReducer(
    {
        // unclaimed_project_list: [],
        unclaimed_articles: [],
        unclaimed_observations: [],
        exclude_unclaimed_project_ids: [],

        current_draft_tab: 0,
    },
    {
        [types.SET_UNCLAIMED_PROJECTS](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.data !== null) {
                // newState.unclaimed_project_list = action.data;
                newState.unclaimed_articles = action.data.unclaimed_articles || [];
                newState.unclaimed_observations = action.data.unclaimed_observations || [];
            } else {
                // newState.unclaimed_project_list = [];
                newState.unclaimed_articles = [];
                newState.unclaimed_observations = [];
            }

            return newState;
        },

        [types.SET_EXCLUDE_UNCLAIMED_PROJECT_IDS](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.data !== null) {
                newState.exclude_unclaimed_project_ids = action.data;
            } else {
                newState.exclude_unclaimed_project_ids = [];
            }

            return newState;
        },

        [types.SET_CURRENT_DRAFT_TAB](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.data !== null) {
                newState.current_draft_tab = action.data;
            } else {
                newState.current_draft_tab = 0;
            }

            return newState;
        },
    },
);
