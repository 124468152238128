import { executeBasicNetworkCall } from "lib/Utils";
import * as types from 'QRG/actions/types.js';

export function getDistricts(callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "qrg_district_list",
            null,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                    } else {
                        dispatch(setDistricts(x.data));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function getDistrictDetails(district_id = '', callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_district_quick_reference_guides",
            JSON.stringify({ br_district_code: district_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        dispatch(setDistrictDetails(x.data));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function getDistrictsDropdownList(callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_br_districts_dropdown_list",
            null,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

function setDistricts(data) {
    return {
        type: types.SET_DISTRICTS,
        data: data,
    };
}

function setDistrictDetails(data) {
    return {
        type: types.SET_DISTRICT_DETAILS,
        data: data,
    };
}
