import React from "react";
import Flexbox from "flexbox-react";
import { InnercastAvatar } from "@linxsystems/web-core";
import { Dropdown } from "semantic-ui-react";
import * as Constants from "Constants";
import { AuthGroup } from "Constants";
import "styles/Components.css";

export default function TopMenu(props) {
    const isAuthorAMPMOrAdvocate = props?.user?.groupID
        ? props.user.groupID === AuthGroup.AccountManager ||
          props.user.groupID === AuthGroup.ProjectManager ||
          props.user.groupID === AuthGroup.Reviewer ||
          props.user.groupID === AuthGroup.Author ||
          props.user.groupID === AuthGroup.Advocate
        : false;

    const trigger = (
        <span>
            <InnercastAvatar
                name={props.user.fullName}
                circle={props.user.circle}
                size={45}
                group_id={props.user.groupID}
                src={props.user.profile_pic}
            />
        </span>
    );

    const header = (
        <div style={{ backgroundColor: Constants.TertiaryColor, padding: 5 }}>
            <Flexbox flexDirection="row">
                <InnercastAvatar
                    name={props.user.fullName}
                    circle={props.user.circle}
                    group_id={props.user.groupID}
                    size={45}
                    src={props.user.profile_pic}
                    style={{ margin: 5 }}
                />

                <Flexbox flexDirection="column" justifyContent="center">
                    <div>{props.user.fullName}</div>
                    <div style={{ fontSize: 10 }}>{props.user.email}</div>
                </Flexbox>
            </Flexbox>
        </div>
    );

    return (
        <Dropdown lazyLoad trigger={trigger} pointing="top right" icon={null}>
            <Dropdown.Menu>
                <Dropdown.Header className="dropdownHeader">{header}</Dropdown.Header>
                {isAuthorAMPMOrAdvocate && (
                    <React.Fragment>
                        <Dropdown.Item text="My Profile" icon="user" onClick={() => props.history.push("/articles")} />
                        <Dropdown.Divider />
                    </React.Fragment>
                )}

                <Dropdown.Item
                    text="Terms and Conditions"
                    onClick={() => props.history.push("/terms_and_conditions")}
                    icon="law"
                />
                <Dropdown.Item
                    text="Data Privacy"
                    onClick={() => window.location.replace("https://www.basf.com/us/en/legal/data-protection.html")}
                    icon="privacy"
                />
                {isAuthorAMPMOrAdvocate && (
                    <Dropdown.Item
                        text="BASF Social Media Guidelines"
                        onClick={() => props.history.push("/social_media_guidelines")}
                        icon="file text"
                    />
                )}
                {isAuthorAMPMOrAdvocate && (
                    <Dropdown.Item
                        text="BASF Posting Guidelines"
                        onClick={() => props.history.push("/posting_guidelines")}
                        icon="file text outline"
                    />
                )}

                <Dropdown.Item
                    text="Settings"
                    icon="settings"
                    onClick={() => {
                        if (isAuthorAMPMOrAdvocate) {
                            props.history.push("/crm/users");
                        } else {
                            props.history.push(`/my_account/${props.user.uid}/${props.user.uid}/edit`);
                        }
                    }}
                />
                <Dropdown.Item
                    text="Logout"
                    icon="sign out"
                    onClick={() => {
                        props.logout();
                        props.history.push("/");
                    }}
                />
            </Dropdown.Menu>
        </Dropdown>
    );
}
