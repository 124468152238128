// This file instantiates one Bugsnag client for the entire application

// Components and modules that want to send handled errors can import the
// exported client to send handled errors

// Components can get access to the React <ErrorBoundary/> by calling
//
//    const ErrorBoundary = bugsnagClient.getPlugin('react')
//

import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";
import React from "react";
import packageJson from "../../package.json";

const bugsnagClient = bugsnag({
    apiKey: "c70d1c67d224323fbeac973705fd911a",
    appVersion: packageJson.version,
    releaseStage: process.env.NODE_ENV,
}).use(bugsnagReact, React);

export default bugsnagClient;
