import * as types from "./types";

export function setError(message) {
  return (dispatch) => {
    dispatch(setErrorMessage(message));
    setTimeout(() => {
      dispatch(clearError())
    }, 10)
  };
}

export function clearError() {
  return (dispatch) => {
    dispatch(setErrorMessage(null));
  };
}

export function setSuccess(message) {
  return (dispatch) => {
    dispatch(setSuccessMessage(message));
  };
}

export function clearSuccess() {
  return (dispatch) => {
    dispatch(setSuccessMessage(null));
  };
}

function setErrorMessage(message) {
  return {
    type: types.SET_ERROR,
    message
  };
}

function setSuccessMessage(message) {
  return {
    type: types.SET_SUCCESS,
    message
  };
}
