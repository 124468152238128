import createReducer from '../lib/createReducer'
import * as types from '../actions/types'


export const crm = createReducer(
    {
        userList: [],
        associationList: [],
        has_more_users: false,
        has_more_associations: false,
        last_loaded_uid: 0,
        last_loaded_aid: 0,
        userDetails: [],
        associationDetails: [],
        userActivity: [],
        has_more_activity: false,
        deviceDetails: [],
        dropdownOptions: {}
    }, {

        [types.SET_USER_LIST](state, action) {
            let newState = Object.assign({}, state);
            if (action.data !== null) {
                newState.userList = action.isLoadingMore ? state.userList.concat(action.data) : action.data;
                newState.has_more_users = action.data.length === 20;
                newState.last_loaded_uid = action.data.length > 0 ? action.data[action.data.length - 1].uid : 0;
            } else {
                newState.userList = [];
                newState.has_more_users = false;
                newState.last_loaded_uid = 0;
            }
            return newState;
        },

        [types.SET_ASSOCIATION_LIST](state, action) {
            let newState = Object.assign({}, state);
            if (action.data !== null) {
                newState.associationList = action.isLoadingMore ? state.associationList.concat(action.data) : action.data;
                newState.has_more_associations = action.data.length === 20;
                newState.last_loaded_aid = action.data.length > 0 ? action.data[action.data.length - 1].id : 0;
            } else {
                newState.associationList = [];
                newState.has_more_associations = false;
                newState.last_loaded_aid = 0;
            }
            return newState;
        },

        [types.SET_USER_DETAILS_INFO](state, action) {
            let newState = Object.assign({}, state);
            if (action.data !== null) {
                newState.userDetails = action.userDetails;
            } else {
                newState.userDetails = [];
            }
            return newState;
        },

        [types.SET_ASSOCIATION_DETAILS_INFO](state, action) {
            let newState = Object.assign({}, state);
            if (action.data !== null) {
                newState.associationDetails = action.associationDetails;
            } else {
                newState.associationDetails = [];
            }
            return newState;
        },

        [types.CHANGE_USER_DETAILS](state, action) {
            let newState = Object.assign({}, state);
            if (action.data !== null) {
                let user = newState.userList.filter(user => user.uid === action.data.uid)[0];
                if (user) {
                    if (action.data.fullName) {
                        user.full_name = action.data.fullName
                    }
                    if (action.data.circle) {
                        user.circle = action.data.circle
                    }
                    if (action.data.groupID) {
                        user.group_id = action.data.groupID
                    }
                }
            }
            return newState;
        },

        [types.CLEAR_USER_DETAILS](state, action) {
            let newState = Object.assign({}, state);
            newState.userDetails = [];
            newState.userActivity = [];
            newState.has_more_activity = false;
            newState.deviceDetails = [];
            return newState;
        },

        [types.CLEAR_ASSOCIATION_DETAILS](state, action) {
            let newState = Object.assign({}, state);
            newState.associationDetails = [];
            return newState;
        },

        [types.SET_USER_ACTIVITY_DETAILS](state, action) {
            let newState = Object.assign({}, state);
            if (action.data !== null) {
                if (action.isLoadingFirst) {
                    newState.userActivity = [action.data];
                } else {
                    newState.userActivity = [...state.userActivity, action.data];
                }
                newState.has_more_activity = true;
            } else {
                newState.has_more_activity = false;
            }
            return newState;
        },

        [types.SET_USER_DEVICE_DETAILS_INFO](state, action) {
            let newState = Object.assign({}, state);
            if (action.data !== null) {
                newState.deviceDetails = action.data;
            } else {
                newState.deviceDetails = [];
            }
            return newState;
        },

        [types.SET_DROPDOWN_OPTIONS](state, action) {
            let newState = Object.assign({}, state);
            if (action.data !== null) {
                newState.dropdownOptions = action.data;
            } else {
                newState.dropdownOptions = {};
            }
            return newState;
        },

    });
