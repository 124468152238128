import { executeBasicNetworkCall } from "lib/Utils";
import * as types from "AllDrafts/actions/types";

export function fetchAllArticleDrafts(callback = () => { }) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "see_all_article_drafts",
            JSON.stringify({  }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message)
                    } else {
                        dispatch(setAllArticleDrafts(x.data));
                        callback(true, x.data);
                    }
                }
            },
        );
    };
}

export function fetchAllObservationDrafts(callback = () => { }) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "see_all_observation_drafts",
            JSON.stringify({  }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message)
                    } else {
                        dispatch(setAllObservationDrafts(x.data));
                        callback(true, x.data);
                    }
                }
            },
        );
    };
}

function setAllArticleDrafts(data = null) {
    return {
        type: types.SET_ALL_ARTICLE_DRAFTS,
        data: data,
    };
}

function setAllObservationDrafts(data = null) {
    return {
        type: types.SET_ALL_OBSERVATION_DRAFTS,
        data: data,
    };
}

export function setExcludeArticleDraftIDs(data = null) {
    return {
        type: types.SET_EXCLUDE_ARTICLE_DRAFT_IDS,
        data: data,
    }
}

export function setExcludeObservationDraftIDs(data = null) {
    return {
        type: types.SET_EXCLUDE_OBSERVATION_DRAFT_IDS,
        data: data,
    }
}

export function clearAllArticleDrafts() {
    return (dispatch) => {
        dispatch(setAllArticleDrafts(null));
    };
}

export function clearAllObservationDrafts() {
    return (dispatch) => {
        dispatch(setAllObservationDrafts(null));
    };
}
