import createReducer from '../lib/createReducer'
import * as types from '../actions/types'


export const storydynamics = createReducer(
  {
    endpoints: null,
    articles: []
  }, {

    [types.SET_ENDPOINT_URLS](state, action) {
    let newState = Object.assign({}, state);
    if(action.info !== null) {
      newState.endpoints = action.info;
    } else {
      newState.endpoints = null;
    }
    return newState;
  },

  [types.SET_ARTICLES](state, action) {
      let newState = Object.assign({}, state);
      if (action.articles !== null) {
          newState.articles = action.articles;
      } else {
          newState.articles = [];
      }
      return newState;
  },

});
