import React, { useRef, useState } from "react";
import "styles/App.css";
import Flexbox from "flexbox-react";
import SidebarItem from "views/Sidebar/SidebarItem";
import SidebarGroup from "views/Sidebar/SidebarGroup";
import packageJSON from "../../../package.json";
import CustomerSupportContactInfo from "../../components/CustomerSupportContactInfo";
import { AuthGroup, TertiaryColor } from "Constants";
import { getViewerFELocation } from "lib/Utils";

import JobQueueDirector from "containers/JobQueueDirector";

import { connect } from "react-redux";

const version = () => {
    return (
        <Flexbox flexDirection="column" alignSelf="center" justifyContent="flex-end" flexGrow={100}>
            <span style={{ fontSize: 10 }} data-cy="version">
                {packageJSON.version}
            </span>
        </Flexbox>
    );
};

const renderItems = (props, sidebarItems = [], level = 0) => {
    return (
        <>
            {sidebarItems.map((item, index) => {
                if (item.hr) {
                    return <div style={{ height: 1, width: "90%", margin: 10, backgroundColor: "darkgray" }} key={index} />;
                } else if (item.subItems) {
                    return (
                        <SidebarGroup
                            key={index}
                            submenuLevel={level}
                            {...item}
                            onClick={item.onClick ? item.onClick : props.onNavClick}>
                            {renderItems(props, item.subItems, level + 1)}
                        </SidebarGroup>
                    );
                } else {
                    return (
                        <SidebarItem
                            key={index}
                            submenuLevel={level}
                            {...item}
                            onClick={item.onClick ? item.onClick : props.onNavClick}
                        />
                    );
                }
            })}
        </>
    );
};

function SidebarContent(props) {
    let sidebarItems = [];
    let showJobQueueDirector = false;

    const supportModal = useRef();
    const [showContactInfo, setShowContactInfo] = useState(false);

    const SIDEBAR_ITEMS = {
        Articles: {
            dataTut: "reactour__home",
            link: "/",
            title: "Articles",
            iconName: "sidebar_home",
        },
        Compose: {
            dataTut: "reactour__editor",
            link: "/editor",
            title: "Compose",
            svgIconName: "pencil",
        },
        Compose_Article: {
            dataTut: "reactour__editor",
            link: "/editor",
            title: "Compose Article",
            exact: true,
            svgIconName: "news",
        },
        Compose_Video: {
            dataTut: "reactour__editor",
            link: "/editor/video",
            title: "Compose Video",
            svgIconName: "videos",
        },
        Content: {
            dataTut: "reactour__content",
            link: "/content",
            title: "Content",
            svgIconName: "pencil",
        },
        My_Content: {
            dataTut: "reactour__content",
            link: "/content",
            title: "My Content",
            exact: true,
            svgIconName: "pencil",
            badge: props.contentBadges?.my_content,
        },
        // All_Drafts: {
        //     dataTut: "reactour__alldrafts",
        //     link: "/all_drafts",
        //     title: "All Drafts",
        //     svgIconName: "content-manager",
        // },
        Unclaimed_Projects: {
            dataTut: "reactour__unclaimedprojects",
            link: "/drafted_content",
            title: "Drafted Content",
            svgIconName: "content-manager",
            badge: (props.contentBadges?.submitted_articles || 0) + (props.contentBadges?.submitted_observations || 0),
        },
        Media: {
            dataTut: "reactour__media",
            link: "/media",
            title: "Media Library",
            svgIconName: "camera",
        },
        CRM: {
            dataTut: "reactour__CRM",
            link: "/crm",
            title: "Users",
            svgIconName: "users",
        },
        CRM_Manage: {
            dataTut: "reactour__CRM_users",
            link: "/crm/users",
            // title: "Manage",
            title: "Users",
            // svgIconName: "business_card",
            svgIconName: "users",
        },
        CRM_Claim_Lite: {
            dataTut: "reactour__claim_lite_user",
            link: "/crm/claim_lite_user",
            title: "Claim Lite User",
            svgIconName: "claim_lite_user",
        },
        Reports: {
            dataTut: "reactour__reports",
            link: "/analytics",
            title: "Analytics",
            svgIconName: "reports",
        },
        Reports_Articles: {
            dataTut: "reactour__reports_articles",
            link: "/reports/articles",
            title: "Article Reports",
            svgIconName: "reports",
        },
        Reports_Chirps: {
            dataTut: "reactour__reports_chirps",
            link: "/reports/chirps",
            title: "Chirper Reports",
            svgIconName: "reports",
        },
        Reports_Batch: {
            dataTut: "reactour__reports_batch",
            link: "/reports/batch",
            title: "Batch Reports",
            svgIconName: "reports",
        },
        Observations: {
            dataTut: "reactour__observer",
            link: "/observations",
            title: "Observations",
            svgIconName: "observations",
        },
        // Chat: {
        //     dataTut: "reactour__Chat",
        //     link: "/chat",
        //     title: "Chat",
        //     svgIconName: "chat",
        // },
        // Events: {
        //     dataTut: "reactour__events",
        //     link: "/events",
        //     title: "Topics",
        //     svgIconName: "events",
        // },
        // About: {
        //     dataTut: "reactour__about",
        //     link: "/about",
        //     title: "About",
        //     iconName: "sidebar_home",
        // },
        // Apps: {
        //     dataTut: "reactour__apps",
        //     link: "/apps",
        //     title: "Apps",
        //     svgIconName: "mobile_devices",
        // },
        TermsAndConditions: {
            dataTut: "reactour__tos",
            link: "/terms_and_conditions",
            title: "Terms And Conditions",
            svgIconName: "terms",
        },
        Logout: {
            dataTut: "reactour__logout",
            link: "/",
            title: "Logout",
            svgIconName: "logout",
        },
        EmailTemplate: {
            dataTut: "reactour__email_template",
            link: "/EmailTemplates",
            title: "Email Template",
            svgIconName: "email",
        },
        UntaggedContent: {
            dataTut: "reactour__untagged_content",
            link: "/untagged_content",
            title: "Untagged Content",
            iconName: "tag",
        },
        CropProductInfo: {
            dataTut: "reactour__crop_product_info",
            link: "/crop_product_info",
            title: "Crop/Product Info",
            svgIconName: "pencil",
        },
        ContentManager: {
            dataTut: "reactour__content_manager",
            link: "/content_manager",
            title: "Content Manager",
            svgIconName: "content-manager",
            badge: props.contentBadges?.content_manager,
        },
        ForReview: {
            dataTut: "reactour__for_review",
            link: "/for_review",
            title: "For My Review",
            svgIconName: "content-manager",
            badge: props.contentBadges?.for_review,
        },
        CustomerSupport: {
            dataTut: "reactour__customer_support",
            title: "Customer Support",
            svgIconName: "sidebar_help",
        },
        DeveloperDashboard: {
            dataTut: "reactour__dev_dashboard",
            link: "/developer",
            title: "Dashboard",
            svgIconName: "reports",
            exact: true,
        },
        DeveloperAccountSettings: {
            dataTut: "reactour__dev_account_settings",
            link: "/developer/team",
            title: "Account Settings",
            iconName: "sidebar_home",
            exact: true,
        },
        DeveloperDocumentation: {
            dataTut: "reactour__dev_documentation",
            link: "/developer/documentation",
            title: "Documentation",
            svgIconName: "business_card",
        },
        Alite27: {
            dataTut: "reactour__alite_27",
            link: "/alite27",
            title: "Alite™ 27 Herbicide",
            svgIconName: "alite27",
        },
        // StoryCompose: {
        //     dataTut: "reactout__story_compose",
        //     link: "/StoryCompose",
        //     title: "Story Compose",
        //     svgIconName: "story_compose",
        // },
        ViewerApp: {
            dataTut: "reactout__viewer_app",
            title: "Return To Viewer Mode",
            svgIconName: "plant_in_hand",
        },
        // QRG: {
        //     dataTut: "reactour__qrg",
        //     link: "/qrg",
        //     title: "Quick Reference",
        //     svgIconName: "qrg",
        // },
        Separator: {
            hr: true,
        },
    };

    const showSupport = () => {
        if (supportModal.current) {
            supportModal.current.forceShow();
        }

        setShowContactInfo(true);
    };

    // const CRM_Item = SIDEBAR_ITEMS.CRM;
    // CRM_Item.subItems = [SIDEBAR_ITEMS.CRM_Manage, SIDEBAR_ITEMS.CRM_Claim_Lite];
    const CRM_Item = SIDEBAR_ITEMS.CRM_Manage;

    const Compose_Item = SIDEBAR_ITEMS.Compose;
    Compose_Item.subItems = [SIDEBAR_ITEMS.Compose_Article, SIDEBAR_ITEMS.Compose_Video];

    const ContentSection = SIDEBAR_ITEMS.Content;
    if (props.user.groupID === AuthGroup.AccountManager) {
        ContentSection.subItems = [SIDEBAR_ITEMS.My_Content, SIDEBAR_ITEMS.ForReview, SIDEBAR_ITEMS.ContentManager, SIDEBAR_ITEMS.Unclaimed_Projects];
    } else if (props.user.groupID === AuthGroup.Reviewer || props.user.groupID === AuthGroup.ProjectManager) {
        ContentSection.subItems = [SIDEBAR_ITEMS.My_Content, SIDEBAR_ITEMS.ForReview,];
    }

    const LogoutItem = SIDEBAR_ITEMS.Logout;
    LogoutItem.onClick = props.logout;
    LogoutItem.link = "/logout";

    const CustomerSupport = SIDEBAR_ITEMS.CustomerSupport;
    CustomerSupport.onClick = showSupport;

    const LinkToViewerApp = SIDEBAR_ITEMS.ViewerApp;
    LinkToViewerApp.onClick = (e) => {
        const location = getViewerFELocation();

        if (e.ctrlKey || e.metaKey) {
            window.open(location, "_blank");
        } else {
            window.location.assign(location);
        }
    };

    if (props.user.groupID === AuthGroup.AccountManager) {
        showJobQueueDirector = true;
        sidebarItems = [
            LinkToViewerApp,
            SIDEBAR_ITEMS.Separator,
            SIDEBAR_ITEMS.Articles,
            // SIDEBAR_ITEMS.Compose,
            SIDEBAR_ITEMS.Content,
            SIDEBAR_ITEMS.Observations,
            SIDEBAR_ITEMS.Media,
            CRM_Item,
            SIDEBAR_ITEMS.Reports,
            SIDEBAR_ITEMS.EmailTemplate,
            // SIDEBAR_ITEMS.UntaggedContent,
            SIDEBAR_ITEMS.CropProductInfo,
            // SIDEBAR_ITEMS.Alite27,
            // SIDEBAR_ITEMS.QRG,
            SIDEBAR_ITEMS.Separator,
            SIDEBAR_ITEMS.CustomerSupport,
            LogoutItem,
        ];
    } else if (props.user.groupID === AuthGroup.ProjectManager) {
        showJobQueueDirector = true;
        sidebarItems = [
            LinkToViewerApp,
            SIDEBAR_ITEMS.Separator,
            SIDEBAR_ITEMS.Articles,
            // SIDEBAR_ITEMS.Compose,
            SIDEBAR_ITEMS.Content,
            SIDEBAR_ITEMS.Observations,
            SIDEBAR_ITEMS.Media,
            CRM_Item,
            // SIDEBAR_ITEMS.Alite27,
            // SIDEBAR_ITEMS.QRG,
            SIDEBAR_ITEMS.Separator,
            SIDEBAR_ITEMS.CustomerSupport,
            LogoutItem,
        ];
    } else if (props.user.groupID === AuthGroup.Reviewer) {
        showJobQueueDirector = true;
        sidebarItems = [
            LinkToViewerApp,
            SIDEBAR_ITEMS.Separator,
            SIDEBAR_ITEMS.Articles,
            // SIDEBAR_ITEMS.Compose,
            SIDEBAR_ITEMS.Content,
            SIDEBAR_ITEMS.Observations,
            SIDEBAR_ITEMS.Media,
            CRM_Item,
            // SIDEBAR_ITEMS.Alite27,
            // SIDEBAR_ITEMS.QRG,
            SIDEBAR_ITEMS.Separator,
            SIDEBAR_ITEMS.CustomerSupport,
            LogoutItem,
        ];
    } else if (props.user.groupID === AuthGroup.Author) {
        showJobQueueDirector = true;
        sidebarItems = [
            LinkToViewerApp,
            SIDEBAR_ITEMS.Separator,
            SIDEBAR_ITEMS.Articles,
            // SIDEBAR_ITEMS.Compose,
            SIDEBAR_ITEMS.My_Content,
            SIDEBAR_ITEMS.Observations,
            SIDEBAR_ITEMS.Media,
            CRM_Item,
            // SIDEBAR_ITEMS.Alite27,
            SIDEBAR_ITEMS.Separator,
            SIDEBAR_ITEMS.CustomerSupport,
            LogoutItem,
        ];
    } else if (props.user.groupID === AuthGroup.PracticeAuthor) {
        showJobQueueDirector = true;
        sidebarItems = [
            LinkToViewerApp,
            SIDEBAR_ITEMS.Separator,
            SIDEBAR_ITEMS.Articles,
            // SIDEBAR_ITEMS.Compose,
            SIDEBAR_ITEMS.My_Content,
            SIDEBAR_ITEMS.Observations,
            SIDEBAR_ITEMS.Media,
            CRM_Item,
            // SIDEBAR_ITEMS.Alite27,
            SIDEBAR_ITEMS.Separator,
            SIDEBAR_ITEMS.CustomerSupport,
            LogoutItem,
        ];
    } else if (props.user.groupID === AuthGroup.Advocate) {
        showJobQueueDirector = true;
        sidebarItems = [
            LinkToViewerApp,
            SIDEBAR_ITEMS.Separator,
            SIDEBAR_ITEMS.Articles,
            SIDEBAR_ITEMS.Observations,
            CRM_Item,
            // SIDEBAR_ITEMS.Alite27,
            SIDEBAR_ITEMS.Separator,
            SIDEBAR_ITEMS.CustomerSupport,
            LogoutItem,
        ];
    } else if (props.user.groupID === AuthGroup.Developer) {
        sidebarItems = [
            SIDEBAR_ITEMS.DeveloperDashboard,
            SIDEBAR_ITEMS.DeveloperAccountSettings,
            SIDEBAR_ITEMS.DeveloperDocumentation,
            SIDEBAR_ITEMS.TermsAndConditions,
            SIDEBAR_ITEMS.CustomerSupport,
            LogoutItem,
        ];
    } else {
        // else this shouldn't happen
        return <div />;
    }

    return (
        <Flexbox flexDirection="column" flex="1" justifyContent="space-between" style={{ minHeight: "100%" }}>
            <nav
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    alignItems: "flex-start",
                    minWidth: 170,
                    background: TertiaryColor,
                    color: "white",
                    minHeight: "100%",
                }}>
                {renderItems(props, sidebarItems)}
            </nav>
            {showJobQueueDirector && <JobQueueDirector />}
            {version()}

            {showContactInfo ? (
                <React.Fragment>
                    <React.Suspense fallback={null}>
                        <CustomerSupportContactInfo ref={supportModal} />
                    </React.Suspense>
                </React.Fragment>
            ) : null}
        </Flexbox>
    );
}

function mapStateToProps(state) {
    return {
        contentBadges: state.contentBadges,
    };
}

export default connect(mapStateToProps, null)(SidebarContent);
