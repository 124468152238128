import { executeBasicNetworkCall } from "../../lib/Utils";
import * as MessageActions from "../../actions/messages";
import * as types from "../../actions/types";

export function getUnclaimedLiteUsers(
    active_page = 1,
    num_rows = 20,
    sort_column = "date_joined",
    sort_order = "descending",
    callback = () => {},
) {
    return (dispatch, getState) => {
        const json = {
            active_page,
            num_rows,
        };

        if (sort_column !== null) {
            json.sort_column = sort_column;
        }

        if (sort_order !== null) {
            json.sort_order = sort_order;
        }

        executeBasicNetworkCall(
            "get_unclaimed_lite_users",
            JSON.stringify(json),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(MessageActions.setError(x.message));
                        callback(false);
                    } else {
                        dispatch(setUnclaimedLiteUsers(x.data));
                        callback(true, x.total_pages);
                    }
                }
            },
        );
    };
}

function setUnclaimedLiteUsers(data) {
    return {
        type: types.SET_UNCLAIMED_LITE_USERS,
        data: data,
    };
}

export function clearUnclaimedLiteUsers() {
    return {
        type: types.SET_UNCLAIMED_LITE_USERS,
        data: null,
    };
}

export function claimLiteUser(
    user_id,
    callback = () => {},
    activePage = 1,
    numRows = 20,
    sortColumn = null,
    sortOrder = null,
) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "claim_lite_user",
            JSON.stringify({ user_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(MessageActions.setError(x.message));
                        callback(false);
                    } else {
                        dispatch(MessageActions.setSuccess(x.message));
                        dispatch(
                            getUnclaimedLiteUsers(
                                activePage,
                                numRows,
                                sortColumn,
                                sortOrder,
                            ),
                        );
                        callback(true);
                    }
                }
            },
        );
    };
}
