import { combineReducers } from "redux";

import * as widgetstateReducers from "./widgetstate";
import * as storydynamicReducers from "./storydynamics";
import * as userReducers from "./user";
import * as messagesReducers from "./messages";
import * as articlesReducers from "./articles";
import * as reportsReducers from "./reports";
import * as composeReducers from "./compose";
import * as queueReducers from "./queue";
import * as crmReducers from "./crm";

import * as ResponsiveReducers from "ResponsiveManager/redux/reducer";
// import * as transferReducers from './transfer';
// import agCalcReducers from "../AgronomicCalculator/reducers/";
import AllDraftReducers from "AllDrafts/reducers";
import UnclaimedProjectReducers from "DraftedContent/reducers";
import * as videoEditorReducers from "./video_editor";
import * as hashtagAnalyticsReducers from "./hashtag_analytics";
// import agCalcReducers from "../AgronomicCalculator/reducers/";
import DeveloperReducers from "../Developer/reducers";
import StoryComposeReducers from "../StoryCompose/reducers";
import ClaimLiteUserReducers from "../ClaimLiteUser/reducers";
import CronJobManagerReducers from "../CronJobManager/reducers";
import QRGReducers from "QRG/reducers";

import CropProductInfoReducers from "../CropProductInfo/reducers";
import ContentManagerReducers from "../ContentManager/reducers";

export default combineReducers(
    Object.assign(
        widgetstateReducers,
        storydynamicReducers,
        userReducers,
        messagesReducers,
        articlesReducers,
        reportsReducers,
        composeReducers,
        queueReducers,
        crmReducers,

        // transferReducers,
        AllDraftReducers,
        UnclaimedProjectReducers,
        // agCalcReducers,

        videoEditorReducers,
        hashtagAnalyticsReducers,
        // agCalcReducers,
        DeveloperReducers,
        StoryComposeReducers,
        ClaimLiteUserReducers,
        CronJobManagerReducers,
        QRGReducers,

        CropProductInfoReducers,
        ContentManagerReducers,

        ResponsiveReducers,
    )

);
