import { executeBasicNetworkCall } from "lib/Utils";
import * as types from 'CropProductInfo/actions/types.js';

export function getDistricts(callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_crop_product_district_list",
            null,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                    } else {
                        dispatch(setDistricts(x.data));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function getCropProductDistrictDetails(br_district_id = '', callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_crop_product_district_details",
            JSON.stringify({ br_district_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        dispatch(setDistrictDetails(x.data));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

/** Crops **/

export function getDistrictCrops(br_district_id = '', callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_district_crops",
            JSON.stringify({ br_district_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        dispatch(setDistrictDetails({ district_crops: x.data }));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function addDistrictCrop(br_district_id, crop_id, callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "add_district_crop",
            JSON.stringify({ br_district_id, crop_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function removeDistrictCrop(br_district_id, crop_id, callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "remove_district_crop",
            JSON.stringify({ br_district_id, crop_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function reorderDistrictCrops(br_district_id, crop_ids, callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "reorder_district_crops",
            JSON.stringify({ br_district_id, crop_ids }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function getDistrictCropDetails(br_district_id, crop_id, callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_district_crop_details",
            JSON.stringify({ br_district_id, crop_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        dispatch(setDistrictCropDetails(x.data));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function overwriteDistrictCropField(br_district_id, crop_id, params, callback = () => {}) {
    let json = Object.assign({}, { br_district_id, crop_id }, params);
    json = JSON.stringify(json);

    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "overwrite_district_crop_field",
            json,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function getDistrictCropProductList(br_district_id, crop_id, callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_district_crop_product_list",
            JSON.stringify({ br_district_id, crop_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        dispatch(setDistrictCropDetails({ district_crop_products: x.data }));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function addProductToDistrictCrop(br_district_id, crop_id, product_id, callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "add_product_to_district_crop",
            JSON.stringify({ br_district_id, crop_id, product_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function removeProductFromDistrictCrop(br_district_id, crop_id, product_id, callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "remove_product_from_district_crop",
            JSON.stringify({ br_district_id, crop_id, product_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function reorderProductsForDistrictCrop(br_district_id, crop_id, product_ids, callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "reorder_products_for_district_crop",
            JSON.stringify({ br_district_id, crop_id, product_ids }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}


/** Products **/

export function getDistrictProducts(br_district_id = '', callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_district_products",
            JSON.stringify({ br_district_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        dispatch(setDistrictDetails({ district_products: x.data }));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function addDistrictProduct(br_district_id, product_id, callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "add_district_product",
            JSON.stringify({ br_district_id, product_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function removeDistrictProduct(br_district_id, product_id, callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "remove_district_product",
            JSON.stringify({ br_district_id, product_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function reorderDistrictProducts(br_district_id, product_ids, callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "reorder_district_products",
            JSON.stringify({ br_district_id, product_ids }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function getDistrictProductDetails(br_district_id, product_id, callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_district_product_details",
            JSON.stringify({ br_district_id, product_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        dispatch(setDistrictProductDetails(x.data));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function overwriteDistrictProductField(br_district_id, product_id, params, callback = () => {}) {
    let json = Object.assign({}, { br_district_id, product_id }, params);
    json = JSON.stringify(json);

    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "overwrite_district_product_field",
            json,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}


/** Tools **/

export function getDistrictTools(br_district_id = '', callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_district_tools",
            JSON.stringify({ br_district_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        dispatch(setDistrictDetails({ district_tools: x.data }));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function addDistrictTool(br_district_id, tool_id, callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "add_district_tool",
            JSON.stringify({ br_district_id, tool_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function removeDistrictTool(br_district_id, tool_id, callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "remove_district_tool",
            JSON.stringify({ br_district_id, tool_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function reorderDistrictTools(br_district_id, tool_ids, callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "reorder_district_tools",
            JSON.stringify({ br_district_id, tool_ids }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}


function setDistricts(data) {
    return {
        type: types.SET_DISTRICTS,
        data: data,
    };
}

export function setDistrictDetails(data) {
    return {
        type: types.SET_DISTRICT_DETAILS,
        data: data,
    };
}

function setDistrictCropDetails(data) {
    return {
        type: types.SET_DISTRICT_CROP_DETAILS,
        data: data,
    };
}

function setDistrictProductDetails(data) {
    return {
        type: types.SET_DISTRICT_PRODUCT_DETAILS,
        data: data,
    }
}