import createReducer from "../lib/createReducer";
import * as types from "../actions/types";

export const articles = createReducer(
    {
        myArticles: {
            data: [],
            is_more: false,
            last_index: 0,
        },
        stateStream: {
            data: [],
            is_more: false,
            last_index: 0,
        },
        allArticles: {
            data: [],
            is_more: false,
            last_index: 0,
        },
        otherArticles: {
            data: [],
            is_more: false,
            last_index: 0,
        },
        otherStream: {
            data: [],
            is_more: false,
            last_index: 0,
        },
    },
    {
        [types.SET_MY_ARTICLES](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.myArticles !== null) {
                newState.myArticles.data = newState.myArticles.data.concat(action.myArticles);
                newState.myArticles.is_more = action.myArticles.length === 20;
                newState.myArticles.last_index =
                    action.myArticles.length > 0 ? action.myArticles[action.myArticles.length - 1].id : 0;
            } else {
                newState.myArticles = {
                    data: [],
                    is_more: false,
                    last_index: 0,
                };
            }
            return newState;
        },
        [types.SET_STATE_STREAM](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.stateStream !== null) {
                newState.stateStream.data = newState.stateStream.data.concat(action.stateStream);
                newState.stateStream.is_more = action.stateStream.length === 20;
                newState.stateStream.last_index = action.stateStream.length > 0 ? action.stateStream[action.stateStream.length - 1].id : 0;
            } else {
                newState.stateStream = {
                    data: [],
                    is_more: false,
                    last_index: 0,
                };
            }
            return newState;
        },
        [types.SET_ALL_ARTICLES](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.allArticles !== null) {
                newState.allArticles.data = newState.allArticles.data.concat(action.allArticles);
                newState.allArticles.is_more = action.allArticles.length === 20;
                newState.allArticles.last_index =
                    action.allArticles.length > 0 ? action.allArticles[action.allArticles.length - 1].id : 0;
            } else {
                newState.allArticles = {
                    data: [],
                    is_more: false,
                    last_index: 0,
                };
            }
            return newState;
        },
        [types.SET_OTHER_ARTICLES](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.otherArticles !== null) {
                newState.otherArticles.data = newState.otherArticles.data.concat(action.otherArticles);
                newState.otherArticles.is_more = action.otherArticles.length === 20;
                newState.otherArticles.last_index =
                    action.otherArticles.length > 0 ? action.otherArticles[action.otherArticles.length - 1].id : 0;
            } else {
                newState.otherArticles = {
                    data: [],
                    is_more: false,
                    last_index: 0,
                };
            }
            return newState;
        },
        [types.SET_OTHER_STREAM](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.otherStream !== null) {
                newState.otherStream.data = newState.otherStream.data.concat(action.otherStream);
                newState.otherStream.is_more = action.otherStream.length === 20;
                newState.otherStream.last_index =
                    action.otherStream.length > 0 ? action.otherStream[action.otherStream.length - 1].id : 0;
            } else {
                newState.otherStream = {
                    data: [],
                    is_more: false,
                    last_index: 0,
                };
            }
            return newState;
        },
        [types.TOGGLE_ARTICLE_LIKE](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.aid !== null) {
                newState.stateStream.data = newState.stateStream.data.map((item, index) => {
                    if (action.aid === item.id) {
                        let newItem = { ...item };
                        newItem.data.liked = !item.data.liked;
                        return newItem;
                    } else {
                        return item;
                    }
                });
                newState.myArticles.data = newState.myArticles.data.map((item, index) => {
                    if (action.aid === item.id) {
                        let newItem = { ...item };
                        newItem.data.liked = !item.data.liked;
                        return newItem;
                    } else {
                        return item;
                    }
                });
                newState.allArticles.data = newState.allArticles.data.map((item, index) => {
                    if (action.aid === item.id) {
                        let newItem = { ...item };
                        newItem.data.liked = !item.data.liked;
                        return newItem;
                    } else {
                        return item;
                    }
                });
                newState.otherArticles.data = newState.otherArticles.data.map((item, index) => {
                    if (action.aid === item.id) {
                        let newItem = { ...item };
                        newItem.data.liked = !item.data.liked;
                        return newItem;
                    } else {
                        return item;
                    }
                });
                newState.otherStream.data = newState.otherStream.data.map((item, index) => {
                    if (action.aid === item.id) {
                        let newItem = { ...item };
                        newItem.data.liked = !item.data.liked;
                        return newItem;
                    } else {
                        return item;
                    }
                });
            }

            return newState;
        },
        [types.TOGGLE_ARTICLE_SHARE](state, action) {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.aid !== null) {
                newState.stateStream.data = newState.stateStream.data.map((item, index) => {
                    if (action.aid === item.id) {
                        let newItem = { ...item };
                        newItem.data.shared = !item.data.shared;
                        return newItem;
                    } else {
                        return item;
                    }
                });
                newState.myArticles.data = newState.myArticles.data.map((item, index) => {
                    if (action.aid === item.id) {
                        let newItem = { ...item };
                        newItem.data.shared = !item.data.shared;
                        return newItem;
                    } else {
                        return item;
                    }
                });
                newState.allArticles.data = newState.allArticles.data.map((item, index) => {
                    if (action.aid === item.id) {
                        let newItem = { ...item };
                        newItem.data.shared = !item.data.shared;
                        return newItem;
                    } else {
                        return item;
                    }
                });
                newState.otherArticles.data = newState.otherArticles.data.map((item, index) => {
                    if (action.aid === item.id) {
                        let newItem = { ...item };
                        newItem.data.shared = !item.data.shared;
                        return newItem;
                    } else {
                        return item;
                    }
                });
                newState.otherStream.data = newState.otherStream.data.map((item, index) => {
                    if (action.aid === item.id) {
                        let newItem = { ...item };
                        newItem.data.shared = !item.data.shared;
                        return newItem;
                    } else {
                        return item;
                    }
                });
            }

            return newState;
        },
    },
);

export const savedarticles = createReducer(
    {
        list: [],
    },
    {
        [types.SET_SAVED_ARTICLES_INFO](state, action) {
            //The line beneath here probably needs to change.
            let newState = Object.assign({}, state);

            if (action.savedArticles !== null) {
                newState.list = action.savedArticles;
            } else {
                newState = {
                    list: [],
                };
            }
            return newState;
        },
    },
);

export const basfusers = createReducer(
    {
        list: [],
    },
    {
        [types.SET_BASF_USERS](state, action) {
            //The line beneath here probably needs to change.
            let newState = Object.assign({}, state);

            if (action.basfusers !== null) {
                newState.list = action.basfusers;
            } else {
                newState = {
                    list: [],
                };
            }
            return newState;
        },
    },
);
