import createReducer from "../../lib/createReducer";
import * as types from "../actions/types";

const DEFAULT_STATE_STORY_LIST = [];

const DEFAULT_STATE_STORY_DETAILS = {
    id: null,
    name: null,
    slides: [],
    is_published: false
};

const DEFAULT_STATE_SLIDE_DETAILS = {
    id: null,
    index: null,
    slide_image: null,
    slide_text: null
};

export const storyCompose = createReducer(
    {
        stories: DEFAULT_STATE_STORY_LIST,
        storyDetails: DEFAULT_STATE_STORY_DETAILS,
        slideDetails: DEFAULT_STATE_SLIDE_DETAILS
    },
    {
        [types.SET_STORY_LIST](state, action) {
            let newState = Object.assign({}, state);

            if (action.data === null) {
                newState.stories = DEFAULT_STATE_STORY_LIST;
            } else {
                newState.stories = action.data.story_data;
            }

            return newState;
        },

        [types.SET_STORY_DETAILS](state, action) {
            let newState = Object.assign({}, state);

            if (action.data === null) {
                newState.storyDetails = DEFAULT_STATE_STORY_DETAILS;
            } else {
                newState.storyDetails = action.data;
            }

            return newState;
        },

        [types.SET_STORY_SLIDE_DETAILS](state, action) {
            let newState = Object.assign({}, state);

            if (action.data === null) {
                newState.slideDetails = DEFAULT_STATE_SLIDE_DETAILS;
            } else {
                newState.slideDetails = action.data;
            }

            return newState;
        }
    }
);
