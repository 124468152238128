import createReducer from "lib/createReducer";
import * as types from "CropProductInfo/actions/types";

const DEFAULT_STATE = {
    district_id: null,
    district_code: '',
    region_name: '',
    district_crops: [],
    district_products: [],
    district_tools: [],
};

export const districtDetails = createReducer(
    DEFAULT_STATE,
    {
        [types.SET_DISTRICT_DETAILS](state, action) {
            let newState = Object.assign({}, state);
            if (action.data === null || action.data === undefined) {
                newState = DEFAULT_STATE;
            } else {
                if (action.data.district_id) {
                    newState.district_id = action.data.district_id;
                }
                if (action.data.district_code) {
                    newState.district_code = action.data.district_code;
                }
                if (action.data.region_name) {
                    newState.region_name = action.data.region_name;
                }
                if (action.data.district_crops) {
                    newState.district_crops = action.data.district_crops;
                }
                if (action.data.district_products) {
                    newState.district_products = action.data.district_products;
                }
                if (action.data.district_tools) {
                    newState.district_tools = action.data.district_tools;
                }
            }
            return newState;
        },
    }
);
