import { executeBasicNetworkCall } from "../../lib/Utils";
import * as MessageActions from "../../actions/messages";
import * as types from "./types";

export function getCronJobs() {
    return (dispatch, getState) => {
        executeBasicNetworkCall("get_cron_jobs", null, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    dispatch(MessageActions.setError(x.message));
                } else {
                    dispatch(setCronJobs(x.data));
                }
            }
        });
    };
}

function setCronJobs(data) {
    return {
        type: types.SET_CRON_JOBS_LIST,
        data: data,
    };
}

export function callJobWithAction(endpoint, action, callback = () => {}) {
    let json = {
        action,
    };
    json = JSON.stringify(json);

    return (dispatch, getState) => {
        executeBasicNetworkCall(endpoint, json, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    dispatch(MessageActions.setError(x.message));
                    callback();
                } else {
                    dispatch(MessageActions.setSuccess(x.message));
                    callback();
                }
            }
        });
    };
}
