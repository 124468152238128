import {
    executeBasicNetworkCall,
} from "lib/Utils";
import * as MessageActions from "actions/messages";
import * as types from "actions/types";

export function createVideo(
    auth_token,
    callback = () => {},
) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "create_video",
            JSON.stringify({ auth_token: auth_token }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(
                            MessageActions.setError(
                                "There was an unexpected error. Video creation failed",
                            ),
                        );
                    } else {
                        callback(x.data);
                    }
                }
            },
        );
    };
}

export function getVideoList(auth_token) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_video_list",
            JSON.stringify({ auth_token: auth_token }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(
                            MessageActions.setError(
                                "There was an unexpected error. Video List fetch failed",
                            ),
                        );
                    } else {
                        const published = x.data.published_videos;
                        const draft = x.data.draft_videos;
                        const scheduled = x.data.scheduled_to_publish_videos;

                        dispatch(
                            setVideoList(
                                published,
                                draft,
                                scheduled,
                            ),
                        );
                    }
                }
            },
        );
    };
}

function setVideoList(
    published = [],
    draft = [],
    scheduled = [],
) {
    return {
        type: types.SET_EDITOR_VIDEO_LIST,
        draft,
        published,
        scheduled,
    };
}

export function getVideo(
    auth_token,
    video_id,
    callback = () => {},
    failure = () => {},
) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_video",
            JSON.stringify({ auth_token: auth_token, video_id: video_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(
                            MessageActions.setError(
                                "There was an unexpected error. Video loading failed",
                            ),
                        );
                        failure();
                    } else {
                        // do something with video data
                        callback(x.data);
                    }
                }
            },
        );
    };
}

export function saveVideo(
    auth_token,
    data = {
        video_id: -1, // always have to send this
        title: "",
        release_date: new Date().toISOString(),
        video_azure_media_id: "",
    },
    callback = () => {},
) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "save_video",
            JSON.stringify(Object.assign({ auth_token: auth_token }, data)),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(
                            MessageActions.setError(
                                "There was an unexpected error. Video saving failed",
                            ),
                        );
                        callback(false);
                    } else {
                        // do something with video data
                        // callback(x.data);
                        callback(true);
                    }
                }
            },
            (err) => callback(false),
        );
    };
}

export function publishVideo(
    auth_token,
    video_id,
    states_list = [],
    br_districts_list = [],
    br_regions = [],
    crops_list = [],
    products_list = [],
    is_customer_exclusive,
    callback = () => {},
) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "publish_video",
            JSON.stringify({
                auth_token: auth_token,
                video_id: video_id,
                states: states_list,
                br_districts: br_districts_list,
                br_regions: br_regions,
                crops: crops_list,
                products: products_list,
                is_customer_exclusive: is_customer_exclusive,
            }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(
                            MessageActions.setError(
                                "There was an unexpected error. Video publishing failed",
                            ),
                        );
                        callback(false, x.message);
                    } else {
                        dispatch(getVideoList(auth_token));
                        callback(true);
                    }
                }
            },
        );
    };
}

export function publishVideoV2(
    auth_token,
    video_id,
    states_list = [],
    br_districts_list = [],
    br_regions = [],
    content_category_id,
    focus_crop_id,
    focus_product_id,
    is_customer_exclusive,
    callback = () => {},
) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "publish_video_v2",
            JSON.stringify({
                auth_token: auth_token,
                video_id: video_id,
                states: states_list,
                br_districts: br_districts_list,
                br_regions: br_regions,
                content_category_id: content_category_id,
                focus_crop_id: focus_crop_id,
                focus_product_id: focus_product_id,
                is_customer_exclusive: is_customer_exclusive,
            }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(
                            MessageActions.setError(
                                "There was an unexpected error. Video publishing failed",
                            ),
                        );
                        callback(false, x.message);
                    } else {
                        dispatch(getVideoList(auth_token));
                        callback(true);
                    }
                }
            },
        );
    };
}

export function publishForMicrosite(
    auth_token,
    video_id,
    callback = () => {},
) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "publish_video_archived",
            JSON.stringify({ auth_token: auth_token, video_id: video_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(
                            MessageActions.setError(
                                "There was an unexpected error. Video publishing failed",
                            ),
                        );
                        callback(false, x.message);
                    } else {
                        dispatch(getVideoList(auth_token));
                        callback(true);
                    }
                }
            },
        );
    };
}

export function deleteVideo(
    auth_token,
    video_id,
    callback = () => {},
) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "delete_video",
            JSON.stringify({ auth_token: auth_token, video_id: video_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(
                            MessageActions.setError(
                                "There was an unexpected error. Video deletion failed",
                            ),
                        );
                    } else {
                        // do something with video data
                        // callback(x.data);
                        dispatch(getVideoList(auth_token));
                        callback();
                    }
                }
            },
        );
    };
}

export function getVideoURLs(
    locator_id,
    video_id,
    callback = () => { },
    failure = () => { },
) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "fetch_video_urls",
            JSON.stringify({ locator_id, video_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        dispatch(
                            MessageActions.setError(
                                "There was an unexpected error. Failed to fetch video url",
                            ),
                        );
                        failure();
                    } else {
                        // do something with video data
                        callback(x.data);
                    }
                }
            },
        );
    };
}
